<template>
    <div class="am-header-actions">
        <div class="am-header-actions__left">
            <slot />
            <ow-toggle v-model="showOnlyUnread"
                       data-track="Asset Monitoring: Toggle to show only unread"
                       class="am-header-actions__unreadOnly"
                       :label="t('assetMonitoring.readStatus.showUnreadOnly')" />
            <a v-if="hasItems"
               class="link-text am-header-actions__link am-header-actions__markAllRead"
               data-test="mark-all-read"
               data-track="Asset Monitoring: Mark all as read"
               href="#"
               @click.prevent="markAllReadHandler">
                {{ t('action.markAllRead') }}
            </a>
        </div>
        <div>
            <a class="accents-small am-header-actions__link"
               data-test="refresh-notifications"
               data-track="Asset Monitoring: Refresh notifications"
               href="#"
               @click.prevent="refreshNotificationsHandler">
                {{ t('action.refresh') }}
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">

    import {ref,
            watch} from "vue"
    import {useI18n} from "vue-i18n"

    import OwToggle from "@/components/core/ow-toggle.vue"
    import {useAssetMonitoringStore} from "@/stores/asset-monitoring"

    const { t } = useI18n()
    const assetMonitoringStore = useAssetMonitoringStore()

    const props = defineProps({
        hasItems: Boolean,
        matterId: Number,
    })

    const showOnlyUnread = ref<boolean>(false)
    watch(() => showOnlyUnread.value, (showOnlyUnread: boolean): void => {
        assetMonitoringStore.updateShowUnreadOnly(props.matterId, showOnlyUnread)
    })

    const refreshNotificationsHandler = async () => {
        await assetMonitoringStore.getNotifications(props.matterId)
    }

    const markAllReadHandler = async () => {
        const unreadNotificationIds = assetMonitoringStore.userNotifications
            .filter((notification) => !notification.isRead)
            .map((notification) => notification.notificationId)

        await assetMonitoringStore.dismissNotifications(unreadNotificationIds)
    }
</script>

<style scoped lang="scss">
    @import './am-header-actions';
</style>
