<template>
    <div class="register-card-actions d-flex justify-space-between w-100 align-center gc-3 mt-3 flex-wrap">
        <div class="d-flex align-center gc-2 flex-grow-1">
            <ow-textfield v-model="searchText"
                          class="register-card__actions-search"
                          clearable
                          data-test="title-details-panel-register-search"
                          data-track="TITLE-DETAILS-PANEL - search register entries"
                          hide-details
                          placeholder="Filter..."
                          prepend-inner-icon="$search"
                          small
                          @click:clear="$emit('search-clear')">
                <template #iconPrefix>
                    <v-icon>$search</v-icon>
                </template>
                <template #iconSuffix>
                    <v-icon>$filter</v-icon>
                </template>
            </ow-textfield>
        </div>
        <div class="d-flex align-center caption-regular gc-1">
            <label for="titlePanelRegisterHighlightDates">Highlight dates &amp; times</label>
            <ow-checkbox id="titlePanelRegisterHighlightDates"
                         v-model="highlightDates"
                         class="flex-grow-0"
                         data-test="title-details-panel-register-highlight-dates"
                         data-track="TITLE-DETAILS-PANEL - highlight dates in register"
                         hide-details />
        </div>
        <template v-if="!hideFlags">
            <ow-button-menu v-model="showButtonMenu"
                            :close-on-content-click="false"
                            :disabled="entryTypes.length === 0"
                            button-data-test-attribute="title-details-panel-register-flags-btn"
                            button-text="Flags"
                            full-height>
                <template #links>
                    <ul class="register-card__flag-links flex-column d-flex">
                        <li v-for="(item, index) in entryTypes"
                            :key="index"
                            :data-test="`filter-flags-entry-${index}`"
                            :data-track-attribute="item.label"
                            data-track="TITLE-DETAILS-PANEL - Register flag"
                            @click="onSelectEntry(item)">
                            <div :class="{'selected': entryTypeIsSelected(item)}"
                                 class="d-flex align-center justify-space-between w-100 pa-2">
                                <span :class="{
                                          'caption-highlight': item.selected,
                                          'caption-regular': !item.selected
                                      }"
                                      class="w-100">
                                    {{ item.label }}
                                </span>
                                <span :style="{ background: item.colour}"
                                      class="caption-highlight px-2 rounded">{{ item.codes.length }}</span>
                            </div>
                        </li>
                    </ul>
                </template>
                <template #iconSuffix>
                    <v-icon v-if="selectedEntryTypes.length === 0">
                        $filter
                    </v-icon>
                    <v-icon v-if="selectedEntryTypes.length > 0"
                            color="primary">
                        $filter
                    </v-icon>
                </template>
            </ow-button-menu>
        </template>
    </div>
</template>

<script lang="ts" setup>
    import OwButtonMenu from '@/components/core/ow-button-menu.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { ISimplifiedRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'

    const emit = defineEmits<{
        (e: 'toggle-entry-type', value: ISimplifiedRegisterLinkedIndicator),
        (e: 'search-clear'): void,
    }>()

    const searchText = defineModel<string>('searchText')
    const highlightDates = defineModel<boolean>('highlightDates')
    const showButtonMenu = defineModel<boolean>('showButtonMenu')

    const props = defineProps<{
        selectedEntryTypes: ISimplifiedRegisterLinkedIndicator[]
        entryTypes: ISimplifiedRegisterLinkedIndicator[]
        hideFlags: boolean
    }>()

    const entryTypeIsSelected = (item: ISimplifiedRegisterLinkedIndicator) => {
        return props.selectedEntryTypes
            .find((t) => t.label === item.label) !== undefined
    }

    const onSelectEntry = (item: ISimplifiedRegisterLinkedIndicator) => {
        item.selected = !item.selected
        emit('toggle-entry-type', item)
    }
</script>

<style scoped>

</style>
