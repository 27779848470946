<template>
    <div class="ow-splash-page"
         :data-test="`${ dataTestPrefix }-splash-page`">
        <div class="ow-splash-page__content">
            <div class="ow-splash-page__content--icon">
                <ow-icon :icon="icon" />
            </div>

            <div class="ow-splash-page__content--text">
                <h3 class="headers-display">
                    {{ title }}
                </h3>
                <p class="body-regular">
                    {{ subtitle }}
                </p>
                <slot name="footer" />

                <ow-button :data-button-type="buttonEnabled ? 'Enable' : 'Interested'"
                           class="ow-splash-page__content--cta"
                           :data-test="`${dataTestPrefix}-splash-page-cta`"
                           :data-track="`${dataTrackPrefix}: Splash page CTA`"
                           is-primary
                           @click="ctaHandler">
                    <span v-if="buttonEnabled">{{ buttonTitle }}</span>
                    <span v-else>{{ t('action.imInterested') }}</span>
                </ow-button>
            </div>
        </div>

        <ow-modal v-model="showRegisterInterestSuccess"
                  class="mb-4"
                  :title="buttonEnabled ? dataTrackPrefix : t('dialog.registerInterest.title')"
                  data-test="register-interest-confirmation-modal"
                  @close="showRegisterInterestSuccess = false">
            <div class="w-100">
                {{ $t('splashPage.registerInterestCtaThanks') }}
            </div>
        </ow-modal>
    </div>
</template>

<script lang="ts" setup>
    import {
        ref,
    } from 'vue'
    import { useI18n } from "vue-i18n"
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwIcon from '@/components/core/ow-icon.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    const props = defineProps<{
        icon: string,
        title: string,
        subtitle: string,
        dataTrackPrefix: string,
        dataTestPrefix: string,
        buttonEnabled: boolean
        buttonTitle: string
        matterId: Number
    }>()

    const store = useStore()
    const { t } = useI18n()
    const showRegisterInterestSuccess = ref(false)
    const emit = defineEmits(['button-click'])
    const ctaHandler = async () => {
        if (props.buttonEnabled) {
            emit('button-click')
        } else {
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: `${ props.dataTrackPrefix }: Register interest`,
                metadata: {
                    matterId: props.matterId,
                },
            })
            showRegisterInterestSuccess.value = true
        }
    }
</script>

<style lang="scss" scoped>
    @import './ow-splash-page.scss';
</style>
