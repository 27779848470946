<template>
    <div class="company-alert-filters">
        <div class="company-alert-filters__top">
            <am-header-actions>
                <ow-button is-secondary
                           @click="onFilterButtonClicked">
                    <template #iconPrefix>
                        <v-icon class="ow-button-menu__open-icon">
                            {{ '$filter' }}
                        </v-icon>
                    </template>
                    <p>{{ areFilterOptionsOpen ? t('assetMonitoring.actions.closeFilters') : t('assetMonitoring.actions.filters') }}</p>
                </ow-button>
            </am-header-actions>
        </div>
        <div class="company-alert-filters__pills">
            <v-chip v-for="(item, index) in enabledFilters"
                    :key="index"
                    class="company-alert-filters__pill"
                    size="large"
                    @click="removeFilter(item)">
                <div class="company-alert-filters__pill--content">
                    <span class="body-regular">{{ item }}</span>
                    <v-icon size="x-small">
                        $close
                    </v-icon>
                </div>
            </v-chip>
        </div>
        <v-expansion-panels v-model="panel"
                            class="company-alert-filters__panel--wrapper">
            <!-- Only render the style if the panel is open, otherwise some of it persists when collapsed-->
            <v-expansion-panel value="filters"
                               elevation="0"
                               :class="{'company-alert-filters__panel': areFilterOptionsOpen === true}">
                <v-expansion-panel-text>
                    <div class="company-alert-filters__panel--content">
                        <h3 class="body-highlight main-content__info--type">
                            {{ t('assetMonitoring.filters.filterSection') }}
                        </h3>
                        <div class="company-alert-filters__panel--filters">
                            <div class="company-alert-filters__panel__filter--column">
                                <h4 class="caption-highlight">
                                    {{ t('assetMonitoring.filters.types') }}
                                </h4>
                                <ow-checkbox v-for="(type, index) in filterTypes"
                                             :id="'type-filters--' + index.toString()"
                                             :key="index"
                                             v-model="type.selected"
                                             :label="type.title"
                                             @update:model-value="toggleTypeFilter" />
                            </div>
                            <div class="company-alert-filters__panel__filter--column">
                                <h4 class="caption-highlight">
                                    {{ t('assetMonitoring.filters.groups') }}
                                </h4>
                                <!-- TODO: Add in group filters. Use checkboxes from above -->
                            </div>
                            <div class="company-alert-filters__panel__filter--column">
                                <h4 class="caption-highlight">
                                    {{ t('assetMonitoring.filters.companies') }}
                                </h4>
                                <!-- TODO: Add in company filters. Use checkboxes from above -->
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script setup lang="ts">

    import {computed,
            ref} from "vue"
    import {useI18n} from "vue-i18n"

    import AmHeaderActions from "@/components/asset-monitoring/am-header-actions.vue"
    import {NotificationSubType} from "@/components/asset-monitoring/notification-sub-type.enum"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import {IOptionItem} from "@/interfaces/option-item.interface"

    const { t } = useI18n()

    // Panel opening and closing
    const areFilterOptionsOpen = ref<boolean>(false)
    const panel = ref<Array<string>>([])
    const onFilterButtonClicked = () => {
        areFilterOptionsOpen.value = !areFilterOptionsOpen.value
        panel.value = areFilterOptionsOpen.value ? ['filters'] : []
    }

    // Panel data
    // TODO: populate from AM store
    const filterGroups = computed<Array<string>>((): Array<string> => [])
    const filterCompanies = computed<Array<string>>((): Array<string> => [])
    const filterTypes = computed((): Array<IOptionItem> => {
        return [
            {
                title: t('assetMonitoring.subType.boundaryUpdated.title'),
                value: NotificationSubType.BoundaryChange,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.EditionDateDoesntMatchOcdaResponse.title'),
                value: NotificationSubType.EditionDateDoesntMatchOcdaResponse,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.oc2DocumentUpdated.title'),
                value: NotificationSubType.NewDocumentInOCDA,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.ownershipUpdated.title'),
                value: NotificationSubType.OwnershipChange,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.pendingApplicationsUpdated.title'),
                value: NotificationSubType.CompletedPendingApplication,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
                value: NotificationSubType.CompaniesHouse,
                selected: false,
            },
        ]
    })

    // Panel functions
    const toggleTypeFilter = (type: NotificationSubType) => {
        // TODO: Call store to toggle filtered types
    }
    const toggleGroupFilter = (type: string) => {
        // TODO: Call store to toggle filtered groups
    }
    const toggleCompanyFilter = (type: string) => {
        // TODO: Call store to toggle filtered companies
    }

    // Pills
    // TODO: replace with enabled filters from AM store
    const enabledFilters = computed<Array<string>>((): Array<string> => [])

    const removeFilter = (filterName: string) => {
        // TODO: remove active filter from AM store
    }

</script>

<style scoped lang="scss">
    @import './company-alert-filters';
</style>
