import type { RouteRecordRaw } from 'vue-router'

import { Route } from '@/enums/route.enum'
import i18n from '@/plugins/i18n'
import AssetMonitoring from '@/views/asset-monitoring/asset-monitoring.vue'
import CompanyAlerts from "@/views/asset-monitoring/company-alerts.vue"
import CompanyAlertsPreferences from "@/views/asset-monitoring/company-alerts-preferences.vue"
import CompanyGroupsCreate from "@/views/asset-monitoring/company-groups-create.vue"
import TitleAlerts from "@/views/asset-monitoring/title-alerts.vue"
import TitleAlertsPreferences from "@/views/asset-monitoring/title-alerts-preferences.vue"
import CheckoutProcessingPage from '@/views/checkout-processing.vue'
import CheckoutReviewPage from '@/views/checkout-review.vue'
// Matter Pages
import DocumentViewer from '@/views/document.vue'
import DocumentLibrary from '@/views/document-library.vue'
import Homepage from '@/views/homepage.vue'
import LinkShareLandingPage from '@/views/link-share-landing.vue'
import MatterMap from '@/views/map.vue'
import MapOverlays from '@/views/map-overlays.vue'
import MatterSearches from '@/views/matter-searches.vue'
import MatterSearchesCreate from '@/views/matter-searches-create.vue'
import MatterSearchesDetails from '@/views/matter-searches-details.vue'
import MattersCreate from '@/views/matters-create.vue'
import MattersList from '@/views/matters-list.vue'
import MattersSettings from '@/views/matters-settings.vue'
import ReportingPage from '@/views/reporting.vue'
import ReportingSelectTitlesPage from '@/views/reporting-select-titles.vue'
import ReviewAssistant from '@/views/review-assistant.vue'
import Search from '@/views/search.vue'
import SearchDocumentViewer from '@/views/search-document-viewer.vue'
import SearchesDraftBasket from '@/views/searches-draft-basket.vue'
import AccountPage from '@/views/settings/account.vue'
import AdminOrganisationsPage from '@/views/settings/admin-organisations.vue'
import AdminSettingsPage from '@/views/settings/admin-settings.vue'
import SettingsPage from '@/views/settings/layout.vue'
import LogsPage from '@/views/settings/logs.vue'
import OrganisationChargesPage from '@/views/settings/organisation-charges.vue'
import OrganisationMatters from '@/views/settings/organisation-matters.vue'
import UsersPage from '@/views/settings/users.vue'
import Snapshot from '@/views/snapshot.vue'
import SnapshotAssistant from '@/views/snapshot-assistant.vue'
import TitleAnalysis from '@/views/title-analysis.vue'
import TitleDetails from '@/views/title-details.vue'
import Unauthorised from '@/views/unauthorised.vue'

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: to => {
            return {
                name: Route.Homepage,
                params: to.params,
            }
        },
    },
    {
        path: '/home',
        name: Route.Homepage,
        component: Homepage,
    },
    {
        path: '/matters',
        name: Route.MattersList,
        component: MattersList,
        meta: {
            globalNavName: 'matters',
            title: 'Matters',
            availableForInactiveMatter: true,
        },
    },
    {
        path: '/matters/create',
        name: Route.MattersCreate,
        component: MattersCreate,
        meta: {
            globalNavName: 'home',
            title: 'Create Matter',
            availableForInactiveMatter: true,
        },
    },
    // Redirect old 'checkout' route
    {
        path: '/checkout',
        name: Route.Checkout,
        redirect: to => {
            return {
                name: Route.CheckoutReview,
                params: to.params,
            }
        },
    },
    {
        path: '/checkout/review',
        name: Route.CheckoutReview,
        component: CheckoutReviewPage,
        meta: {
            title: 'Checkout - Review Order',
        },
    },
    {
        path: '/checkout/processing',
        name: Route.CheckoutProcessing,
        component: CheckoutProcessingPage,
        meta: {
            title: 'Checkout - Processing Order',
        },
    },
    {
        path: '/search',
        name: Route.Search,
        component: Search,
        meta: {
            globalNavName: 'search',
            title: 'Search',
            availableForInactiveMatter: true,
        },
    },
    {
        path: '/search/title/:titleNumber',
        name: Route.TitleDetails,
        component: TitleDetails,
        meta: {
            globalNavName: 'search',
            title: 'Title - {{titleNumber}}',
            availableForInactiveMatter: true,
        },
    },
    {
        path: '/matters/:matterId/map',
        name: Route.MatterMap,
        component: MatterMap,
        meta: {
            title: 'Map',
            globalNavName: 'map',
            uses: [ 'map' ],
            allowLinkShareClient: true,
        },
    },
    {
        path: '/matters/:matterId/map/overlays/create',
        name: Route.OverlaysCreate,
        component: MapOverlays,
        meta: {
            title: i18n.global.t('pageTitles.overlays'),
            globalNavName: 'map',
            uses: [ 'overlays' ],
            allowLinkShareClient: false,
        },
        props: true,
        beforeEnter: (to, from) => {
            to.params.fromRouteName = from.name as string
            return true
        },
    },
    {
        path: '/matters/:matterId/map/overlays',
        name: Route.OverlaysList,
        component: MatterMap,
        meta: {
            title: i18n.global.t('pageTitles.overlays'),
            globalNavName: 'map',
            uses: [ 'map', 'overlays' ],
            allowLinkShareClient: false,
        },
    },
    {
        path: '/matters/:matterId/map/overlays/:overlayId',
        name: Route.Overlay,
        component: MatterMap,
        meta: {
            title: i18n.global.t('pageTitles.overlays'),
            globalNavName: 'map',
            uses: [ 'map', 'overlays' ],
            allowLinkShareClient: false,
        },
    },
    {
        path: '/matters/:matterId/searches/create/:step/:args?',
        name: Route.MatterSearchesCreate,
        component: MatterSearchesCreate,
        meta: {
            title: 'Create Search',
            globalNavName: 'searches',
            uses: [ 'searches' ],
            allowLinkShareClient: false,
        },
    },
    {
        path: '/matters/:matterId/searches/:id/:tab?',
        name: Route.MatterSearchesDetails,
        component: MatterSearchesDetails,
        meta: {
            title: 'View Search',
            globalNavName: 'searches',
            uses: [ 'searches' ],
            allowLinkShareClient: false,
        },
    },
    {
        path: '/matters/:matterId/searches/:orderId/:itemId?/document',
        name: Route.SearchesDocument,
        component: SearchDocumentViewer,
        meta: {
            title: 'View Search Document',
            globalNavName: 'document-library',
            allowLinkShareClient: false,
            uses: [ 'searches' ],
        },
    },
    {
        path: '/matters/:matterId/searches',
        name: Route.MatterSearches,
        component: MatterSearches,
        meta: {
            title: i18n.global.t('searches.title'),
            globalNavName: 'searches',
            uses: [ 'searches' ],
            allowLinkShareClient: false,
        },
    },
    {
        path: '/searches/:draftOrderId',
        name: Route.SearchesDraftOrder,
        props: true,
        component: SearchesDraftBasket,
        meta: {
            title: 'Searches Draft Basket',
            hideGlobalNavigation: true,
            allowLinkShareClient: false,
            requiresAuth: false,
            uses: [ 'searches' ],
        },
    },
    {
        path: '/matters/:matterId/map/walkthrough',
        name: Route.MatterMapWalkthrough,
        component: MatterMap,
        meta: {
            title: 'Walkthrough',
            globalNavName: 'map',
            uses: [ 'map' ],
            allowLinkShareClient: true,
        },
    },
    // Redirect old 'map' route
    {
        path: '/matters/:matterId/titles',
        name: Route.MatterTitles,
        redirect: to => {
            return {
                name: Route.MatterMap,
                params: to.params,
            }
        },
    },
    {
        path: '/matters/:matterId/map/:titleNumber',
        name: Route.MatterMapTitle,
        component: MatterMap,
        meta: {
            globalNavName: 'map',
            title: 'Map - {{titleNumber}}',
            uses: [ 'map' ],
            allowLinkShareClient: true,
        },
    },
    // Redirect old 'map' selected title route
    {
        path: '/matters/:matterId/titles/:titleNumber',
        name: 'matter-title',
        redirect: to => {
            return {
                name: Route.MatterMapTitle,
                params: to.params,
            }
        },
    },
    {
        // Notes and Sketches
        path: '/matters/:matterId/sketches',
        name: Route.MatterSketches,
        component: MatterMap,
        meta: {
            title: 'Sketches',
            globalNavName: 'sketches',
            uses: [ 'map' ],
        },
    },
    // Matter
    {
        path: '/matters/:matterId/settings',
        name: Route.MattersSettings,
        component: MattersSettings,
        meta: {
            title: '{{matterName}} Settings',
            globalNavName: 'matter',
            availableForInactiveMatter: true,
        },
    },
    {
        path: '/matters/:matterId/settings/monitoring-preferences',
        name: Route.MattersMonitoringPreferences,
        redirect: to => {
            return {
                name: Route.AssetMonitoringTitleAlertsPreferences,
                params: to.params,
            }
        },
    },

    // Document library
    {
        path: '/matters/:matterId/documents/:orderId?',
        name: Route.DocumentsLibrary,
        component: DocumentLibrary,
        meta: {
            title: 'Documents',
            globalNavName: 'document-library',
            availableForInactiveMatter: true,
        },
    },

    {
        path: '/matters/:matterId/documents/title/:titleNumber',
        name: Route.DocumentsLibraryTitleDetails,
        component: DocumentLibrary,
        meta: {
            title: '{{titleNumber}} - Documents',
            globalNavName: 'document-library',
        },
    },

    // Document viewer - within the context of a matter
    {
        path: '/documents/:documentType/:documentId',
        name: Route.DocumentViewer,
        component: DocumentViewer,
        meta: {
            title: 'Document Viewer',
            globalNavName: null,
        },
        beforeEnter: (to, from) => {
            to.params.fromRouteName = from.name as string
            to.params.fromHash = from.hash as string
            to.params.fromFullPath = from.fullPath
            return true
        },
    },

    // Review assistant
    {
        path: '/matters/:matterId/review-assistant/:titleNumber',
        name: Route.ReviewAssistant,
        component: ReviewAssistant,
    },

    // Title analysis
    {
        path: '/matters/:matterId/analysis',
        name: Route.Analysis,
        component: TitleAnalysis,
        meta: {
            title: 'Analysis',
            globalNavName: 'analysis',
        },
    },
    {
        path: '/matters/:matterId/analysis/title/:titleNumber',
        name: Route.AnalysisTitleDetails,
        component: TitleAnalysis,
        meta: {
            title: '{{titleNumber}} - Analysis',
            globalNavName: 'analysis',
        },
    },

    // Reporting
    {
        path: '/reports',
        name: Route.Reports,
        component: ReportingPage,
        meta: {
            title: 'Reports',
            globalNavName: 'reports',
        },
    },
    {
        path: '/reports/select-titles',
        name: Route.ReportsSelectTitles,
        component: ReportingSelectTitlesPage,
        meta: {
            title: 'Reports',
            globalNavName: 'reports',
        },
    },
    {
        path: '/matters/:matterId/reports',
        name: Route.MatterReports,
        component: ReportingPage,
        meta: {
            title: 'Reports',
            globalNavName: 'reports',
        },
    },

    // Settings (combines account/organisation/admin)
    { path: '/settings', redirect: '/settings/account' },
    {
        path: '/settings',
        name: Route.SettingsPage,
        component: SettingsPage,
        meta: {
            title: 'Settings',
            globalNavName: 'account',
            availableForInactiveMatter: true,
        },
        children: [
            {
                path: 'account',
                component: AccountPage,
                name: Route.SettingsAccountPage,
                meta: {
                    title: 'Basic Info',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
            {
                path: 'admin/settings',
                name: Route.AdminSettingsPage,
                component: AdminSettingsPage,
                meta: {
                    title: 'System Settings',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
            {
                path: 'log',
                name: Route.AdminLogPage,
                component: LogsPage,
                meta: {
                    title: 'Log',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
            {
                path: 'admin/organisations',
                name: Route.AdminOrganisationsPage,
                component: AdminOrganisationsPage,
                meta: {
                    title: 'Organisations',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
            {
                path: '/users',
                name: Route.UsersPage,
                component: UsersPage,
                meta: {
                    title: 'Users',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
            {
                path: 'organisation/charges',
                name: Route.OrganisationChargesPage,
                component: OrganisationChargesPage,
                meta: {
                    title: 'Charges',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
            {
                path: 'organisation/matters',
                name: Route.OrganisationMattersPage,
                component: OrganisationMatters,
                meta: {
                    title: 'Matters',
                    globalNavName: 'account',
                    availableForInactiveMatter: true,
                },
            },
        ],
    },

    // Link share landing page
    {
        path: '/share/:linkGuid',
        name: Route.LinkShareLanding,
        props: true,
        component: LinkShareLandingPage,
        meta: {
            title: 'Orbital Witness',
            hideGlobalNavigation: true,
            allowLinkShareClient: true,
            requiresAuth: false,
            optionalAuthPath: '/share',
        },
    },

    // Snapshots
    {
        path: '/matters/:matterId/snapshot-assistant/:configId',
        name: Route.SnapshotAssistant,
        component: SnapshotAssistant,
        meta: {
            title: 'Snapshot Assistant',
            globalNavName: 'map',
            allowLinkShareClient: false,
        },
    },
    {
        path: '/snapshot/:configId',
        name: Route.Snapshot,
        props: true,
        component: Snapshot,
        meta: {
            title: 'Snapshot',
            hideGlobalNavigation: true,
            allowLinkShareClient: false,
            requiresAuth: false,
            optionalAuthPath: '/snapshot',
        },
    },

    // Asset Monitoring
    {
        path: '/matters/:matterId/alerts',
        redirect: to => {
            const { params } = to
            return `/matters/${ params.matterId }/alerts/titles`
        },
    },
    {
        path: '/matters/:matterId/alerts',
        name: Route.AssetMonitoring,
        props: true,
        component: AssetMonitoring,
        meta: {
            title: 'Alerts',
            hideGlobalNavigation: false,
            allowLinkShareClient: false,
            requiresAuth: true,
            globalNavName: 'alerts',
        },
        children: [
            {
                path: 'titles',
                name: Route.AssetMonitoringTitleAlerts,
                props: true,
                component: TitleAlerts,
                meta: {
                    title: 'Title Alerts',
                    hideGlobalNavigation: false,
                    allowLinkShareClient: false,
                    requiresAuth: true,
                    globalNavName: 'alerts',
                    childNavName: 'titles',
                    subChildNavName: 'alerts',
                },
            },
            {
                path: 'titles/preferences',
                name: Route.AssetMonitoringTitleAlertsPreferences,
                props: true,
                component: TitleAlertsPreferences,
                meta: {
                    title: 'Title Alerts Preferences',
                    hideGlobalNavigation: false,
                    allowLinkShareClient: false,
                    requiresAuth: true,
                    globalNavName: 'alerts',
                    childNavName: 'titles',
                    subChildNavName: 'preferences',
                },
            },
            {
                path: 'companies',
                name: Route.AssetMonitoringCompanyAlerts,
                props: true,
                component: CompanyAlerts,
                meta: {
                    title: 'Company Alerts',
                    hideGlobalNavigation: false,
                    allowLinkShareClient: false,
                    requiresAuth: true,
                    globalNavName: 'alerts',
                    childNavName: 'companies',
                    subChildNavName: 'alerts',
                },
            },
            {
                path: 'companies/preferences',
                name: Route.AssetMonitoringCompanyAlertsPreferences,
                props: true,
                component: CompanyAlertsPreferences,
                meta: {
                    title: 'Company Alerts Preferences',
                    hideGlobalNavigation: false,
                    allowLinkShareClient: false,
                    requiresAuth: true,
                    globalNavName: 'alerts',
                    childNavName: 'companies',
                    subChildNavName: 'preferences',
                },
            },
            {
                path: 'companies/preferences/groups/create',
                name: Route.AssetMonitoringCompanyGroupsCreate,
                props: true,
                component: CompanyGroupsCreate,
                meta: {
                    title: 'Add Company Group',
                    hideGlobalNavigation: false,
                    allowLinkShareClient: false,
                    requiresAuth: true,
                    globalNavName: 'alerts',
                    childNavName: 'companies',
                    subChildNavName: 'preferences',
                },
            },
            {
                path: 'companies/preferences/groups/:groupId',
                name: Route.AssetMonitoringCompanyGroupsEdit,
                props: true,
                component: CompanyGroupsCreate,
                meta: {
                    title: 'Add Company Group',
                    hideGlobalNavigation: false,
                    allowLinkShareClient: false,
                    requiresAuth: true,
                    globalNavName: 'alerts',
                    childNavName: 'companies',
                    subChildNavName: 'preferences',
                },
            },
        ],
    },

    // Unauthorised users
    {
        path: '/unauthorised',
        name: Route.Unauthorised,
        component: Unauthorised,
        meta: {
            title: i18n.global.t('pageTitles.unauthorised'),
            requiresAuth: true,
            hideGlobalNavigation: true,
        },
    },

    // Everything else
    {
        path: '/:catchAll(.*)',
        name: 'other',
        redirect: { name: Route.MattersList },
    },
]
