import {
    computed,
    ref,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from "vue-router"
import { useStore } from 'vuex'

import {
    NavAction,
    NavItem,
} from '@/components/top-nav/types'
import { Route } from '@/enums/route.enum'
import {checkFlag} from "@/feature-flags"

const useAssetMonitoringTopNav = () => {

    const { t } = useI18n()
    const route = useRoute()

    const topNavItems = computed<NavItem[]>(() => {
        let navItems: NavItem[] = [
            {
                title: t('assetMonitoring.navigationHeadings.titles').toString(),
                route: Route.AssetMonitoringTitleAlerts,
                childNavName: 'titles',
                dataTrackId: 'AM Top Nav - Titles clicked',
                dataTestId: 'am-top-nav-titles',
            },
        ]

        if(checkFlag('asset-monitoring-company-monitors-tabs', false)){
            navItems.push(
                {
                    title: t('assetMonitoring.navigationHeadings.companies').toString(),
                    route: Route.AssetMonitoringCompanyAlerts,
                    childNavName: 'companies',
                    dataTrackId: 'AM Top Nav - Companies clicked',
                    dataTestId: 'am-top-nav-companies',
                })
        }

        return [...navItems]
    })

    const store = useStore()
    const isCompanyMonitorsEnabled = computed<boolean>(() => store.state.config.featureFlags?.assetMonitoringCompanyMonitorsTabs)

    const subNavItems = computed<NavItem[]>(() => {
        switch (route.meta.childNavName) {
            case 'titles':
                return [
                    {
                        title: t('assetMonitoring.navigationHeadings.alerts').toString(),
                        route: Route.AssetMonitoringTitleAlerts,
                        childNavName: 'titles',
                        subChildNavName: 'alerts',
                        dataTrackId: 'AM Sub Nav - Titles clicked',
                        dataTestId: 'am-sub-nav-titles',
                    },
                    {
                        title: t('assetMonitoring.navigationHeadings.preferences').toString(),
                        route: Route.AssetMonitoringTitleAlertsPreferences,
                        childNavName: 'titles',
                        subChildNavName: 'preferences',
                        dataTrackId: 'AM Sub Nav - Title Preferences clicked',
                        dataTestId: 'am-sub-nav-titles',
                    },
                ]
            case 'companies':
                if (!isCompanyMonitorsEnabled.value) {
                    return []
                }
                return [
                    {
                        title: t('assetMonitoring.navigationHeadings.alerts').toString(),
                        route: Route.AssetMonitoringCompanyAlerts,
                        childNavName: 'companies',
                        subChildNavName: 'alerts',
                        dataTrackId: 'AM Sub Nav - Companies clicked',
                        dataTestId: 'am-sub-nav-companies',
                    },
                    {
                        title: t('assetMonitoring.navigationHeadings.preferences').toString(),
                        route: Route.AssetMonitoringCompanyAlertsPreferences,
                        childNavName: 'companies',
                        subChildNavName: 'preferences',
                        dataTrackId: 'AM Sub Nav - Company Preferences clicked',
                        dataTestId: 'am-sub-nav-preferences',
                    },
                ]
            default:
                return []
        }
    })

    return {
        topNavItems,
        subNavItems,
    }
}

export default useAssetMonitoringTopNav
