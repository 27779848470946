<template>
    <div class="ow-pdf-viewer">
        <!-- Loading progress-->
        <div v-if="!isLoaded"
             class="ow-pdf-viewer__loader"
             data-test="ow-pdf-viewer-loading">
            <ow-loading-logo class="ow-pdf-viewer__loader--logo" />
        </div>

        <!-- Loading error-->
        <div v-if="hasLoadingError">
            <h2>Error loading the PDF</h2>
            <p>Something went wrong loading the PDF. Our engineers are are aware and will investigate the problem.</p>
        </div>

        <div id="viewerContainer"
             ref="container"
             :class="{
                 'enhanced-document': isEnhancedDocument,
                 'full-width': isFullWidth,
             }"
             class="ow-pdf-viewer__container"
             data-test="ocr-pdf-viewer-container"
             tabindex="0">
            <div id="viewer"
                 class="pdfViewer" />
        </div>
    </div>
</template>

<script>
    import * as pdfjsLib from 'pdfjs-dist/build/pdf.js'
    import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer'

    import DocumentUploadApi from '@/api/document-upload.api'
    import SecurityService from '@/api/security'
    import OwLoadingLogo from '@/components/core/ow-loading-logo.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentTypes } from '@/utils/document-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    let PDFJSWorker;
    (async () => {
        PDFJSWorker = await import('pdfjs-dist/build/pdf.worker.entry')
        pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker
    })()

    const CMAP_URL = '../../../../node_modules/pdfjs-dist/cmaps'

    const EVENTS = {
        currentZoom: 'current-zoom',
        documentScrolled: 'document-scrolled',
        hasInteracted: 'has-interacted',
        isLoaded: 'is-loaded',
        loadingError: 'loading-error',
        numOfPages: 'pages',
        pageChanged: 'page-changed',
        searchLoading: 'search-loading',
        searchResultsCount: 'search-result-count',
        selectedSearchResultNumber: 'selected-search-result-number',
    }

    export default {
        name: 'OwPdfViewer',

        components: { OwLoadingLogo },
        findController: null,
        linkService: null,
        eventBus: null,
        pdfViewer: null,
        pdf: null,

        props: {
            currentMatterId: {
                type: Number,
                required: false,
            },
            documentMetadata: {
                type: Object,
                required: false,
            },
            documentUrl: {
                type: String,
                required: true,
            },
            fitTo: {
                type: String,
                required: false,
                validator: (value) => [ 'height', 'width', 'auto' ].includes(value.toLowerCase()),
                default: 'auto',
            },
            nextSearchResult: {
                type: Number, // Negative numbers for previous, positive numbers for next
                required: false,
            },
            pageNumber: {
                type: Number,
                required: false,
            },
            resetViewer: {
                type: Boolean,
                required: false,
            },
            searchText: {
                type: String,
                required: false,
            },
            zoom: {
                type: String,
                required: false,
                validator: (value) => [ 'in', 'out', 'reset' ].some((v) => value.toLowerCase().startsWith(v)),
                default: 'reset',
            },
            isFullWidth: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                // PDFJS objects
                loadingTask: null,
                zoomLevel: 0.6,

                isLoaded: false,
                hasLoadingError: false,

                // Search
                isSearchLoading: false,
                searchResultsCount: 0,
                selectedSearchResultNumber: null,

                // Zoom related properties
                minZoom: 25,
                maxZoom: 250,
                zoomButtonDelta: 10,

                // Logging properties
                hasInteractedWithDocument: false,
                hasScrolledDocument: false,
                active: true,
            }
        },

        computed: {
            isEnhancedDocument() {
                if (this.documentMetadata) {
                    return !DocumentTypes.NO_ENHANCED_DOCUMENT_AVAILABLE.has(this.documentMetadata.documentType)
                } else {
                    return false
                }
            },

            numOfPages() {
                return this.pdf?.numPages
            },

            currentZoomLevel: {
                get() {
                    return (Math.round((this.zoomLevel * 100) / 10) * 10)
                },
                set(val) {
                    if (this.pdfViewer) {
                        if (this.isLoaded) {
                            const scale = val / 100
                            const clampedScale = scale <= 0 ? 0.1 : scale
                            this.pdfViewer.currentScale = clampedScale
                            this.zoomLevel = clampedScale
                        }
                    }
                },
            },

            scale() {
                switch (this.fitTo.toLowerCase()) {
                    case 'height':
                        return 'page-height'
                    case 'width':
                        return 'page-width'
                    default:
                        return 'page-fit'
                }
            },
        },

        watch: {
            searchText(newVal) {
                this.resetViewerSearch()
                this.doTextSearch(newVal)
            },

            pageNumber(newVal) {
                if (this.pdfViewer?.currentPageNumber !== newVal) {
                    this.pdfViewer.currentPageNumber = newVal
                }
            },

            documentUrl: {
                async handler() {
                    if (!isNullOrWhitespace(this.documentUrl) && this.active) {
                        this.resetViewerCompletely()
                        await this.displayPDF()
                    }
                },
                immediate: true,
            },

            fitTo() {
                this.pdfViewer.currentScaleValue = this.scale
                this.setHasInteracted()
            },

            zoom(newVal) {
                if (newVal.includes('in')) {
                    this.currentZoomLevel = Math.min(this.currentZoomLevel + this.zoomButtonDelta, this.maxZoom)
                }
                if (newVal.includes('out')) {
                    this.currentZoomLevel = Math.max(this.currentZoomLevel - this.zoomButtonDelta, this.minZoom)
                }
                if (newVal.includes('reset')) {
                    this.pdfViewer.currentScaleValue = 'page-fit'
                }
            },
            hasLoadingError(hasError) {
                if (hasError) {
                    this.$emit(EVENTS.loadingError)
                }
            },
            nextSearchResult(newVal) {
                const findPrevious = newVal < 0
                this.doTextSearch(this.searchText, true, findPrevious)
            },
            resetViewer(newVal) {
                if (newVal) {
                    this.resetViewerCompletely()
                }
            },
            currentZoomLevel(zoomLevel) {
                this.$emit(EVENTS.currentZoom, zoomLevel)
            },

            isLoaded(isLoaded) {
                this.$emit(EVENTS.isLoaded, isLoaded)
            },
            searchResultsCount(newValue) {
                this.$emit(EVENTS.searchResultsCount, newValue)
            },
            selectedSearchResultNumber(newValue) {
                this.$emit(EVENTS.selectedSearchResultNumber, newValue)
            },
            isSearchLoading(isLoading) {
                this.$emit(EVENTS.searchLoading, isLoading)
            },
        },

        async activated() {
            this.active = true
            if (!isNullOrWhitespace(this.documentUrl)) {
                this.resetViewerCompletely()
                await this.displayPDF()
            }
        },

        deactivated() {
            this.active = false
        },

        methods: {
            async displayPDF() {
                if (this.documentMetadata.documentType === HighLevelDocumentType.Uploaded) {
                    await this.displayUploadedDocumentPdf()
                } else if (this.documentMetadata.documentType === HighLevelDocumentType.Searches) {
                    await this.displaySearchesDocumentPdf()
                } else {
                    await this.displayPurchasedDocumentPdf()
                }
            },

            async displayUploadedDocumentPdf() {
                let headers
                let uri

                try {
                    const uploadedDocResponse =
                        await DocumentUploadApi.getDocumentUrlWithToken(this.currentMatterId, this.documentMetadata.documentId)
                    headers = {
                        'Response-Type': 'blob',
                    }
                    headers[uploadedDocResponse.data.header.key] = uploadedDocResponse.data.header.value
                    uri = uploadedDocResponse.data.uri
                } catch (e) {
                    this.loadingError = `Unable to get access url for uploaded document: matterId ${ this.currentMatterId }, documentId ${ this.documentMetadata.documentId }`
                    console.error(this.loadingError, e)
                    this.hasLoadingError = true
                    return
                }
                await this.loadPdf(uri, headers, false)
            },

            async displayPurchasedDocumentPdf() {
                const token = await SecurityService.getAccessToken()
                const uri = this.documentUrl
                const headers = { Authorization: `Bearer ${ token }` }
                await this.loadPdf(uri, headers, true)
            },

            async displaySearchesDocumentPdf() {
                const uri = this.documentUrl
                await this.loadPdf(uri, {}, false)
            },

            /**
             * Load the PDF document
             * @param url
             * @param httpHeaders
             * @param withCredentials
             * @returns {Promise<void>}
             */
            async loadPdf(url, httpHeaders, withCredentials) {
                // need to have a url, or can't do anything
                if (isNullOrWhitespace(url)) {
                    return
                }

                this.isLoaded = false
                // Load the document
                try {
                    this.pdf = await pdfjsLib.getDocument({
                        url,
                        cMapUrl: CMAP_URL,
                        cMapPacked: true,
                        maxImageSize: Number.MAX_VALUE,
                        disableAutoFetch: true,
                        disableStream: true,
                        httpHeaders,
                        withCredentials,
                        isEvalSupported: false,
                    }).promise

                    if (this.pdf != null) {
                        this.$emit(EVENTS.numOfPages, this.numOfPages)

                        // Initialise the document viewer, if it's not already initialised!
                        const container = this.$refs.container
                        if (!this.eventBus) {
                            this.eventBus = new pdfjsViewer.EventBus()
                        }
                        if (!this.linkService) {
                            this.linkService = new pdfjsViewer.PDFLinkService({
                                eventBus: this.eventBus,
                            })
                        }
                        if (!this.findController) {
                            // The find controller still needs to be created and added to the viewer,
                            // although searching is now done via the eventbus
                            this.findController = new pdfjsViewer.PDFFindController({
                                eventBus: this.eventBus,
                                linkService: this.linkService,
                            })
                        }
                        if (!this.pdfViewer) {
                            this.pdfViewer = window.pdfv = new pdfjsViewer.PDFViewer({ // added globally for convenient debugging purposes
                                container,
                                eventBus: this.eventBus,
                                findController: this.findController,
                                linkService: this.linkService,
                            })
                        } else {
                            this.pdfViewer.eventBus = this.eventBus
                            this.pdfViewer.findController = this.findController
                            this.pdfViewer.linkService = this.linkService
                        }

                        this.linkService.setDocument(this.pdf)
                        this.linkService.setViewer(this.pdfViewer)
                        this.pdfViewer.maxCanvasPixels = Number.MAX_VALUE
                        // Initialise events to listen for
                        this.eventBus.on('pagesinit', () => {
                            this.pdfViewer.currentScaleValue = this.scale
                            const initialZoomValue = this.pdfViewer.currentScale * 100
                            this.currentZoomLevel = (Math.round(initialZoomValue / 10) * 10)
                        })
                        if (this.isEnhancedDocument) {
                            // Intercept text rendering and append spaces to the enhanced PDF words
                            this.eventBus.on('textlayerrendered', (e) => {
                                e.source.textLayer.textDivs.forEach(s => {
                                    s.textContent = `${ s.textContent } `
                                })
                            })
                        }
                        // Add the document into the viewer.
                        // Need the above events adding to the event bus before adding the doc.
                        this.pdfViewer.setDocument(this.pdf)
                        this.eventBus.on('updatefindmatchescount', data => {
                            this.searchResultsCount = data.matchesCount.total
                            this.selectedSearchResultNumber = data.matchesCount.current
                            this.isSearchLoading = false
                        })
                        this.eventBus.on('updatetextlayermatches', () => {
                        // TODO: possibly use this event to indicate search loading
                        })
                        this.eventBus.on('updatefindcontrolstate', data => {
                            const FindState = {
                                FOUND: 0,
                                NOT_FOUND: 1,
                                WRAPPED: 2,
                                PENDING: 3,
                            }

                            this.isSearchLoading = data.state === FindState.PENDING // Pending search
                            switch (data.state) {
                                case FindState.FOUND:
                                case FindState.WRAPPED:
                                    this.selectedSearchResultNumber = data.matchesCount.current
                                    break

                                case FindState.NOT_FOUND:
                                    this.selectedSearchResultNumber = 0
                                    this.searchResultsCount = 0
                                    break
                            }
                        })
                        this.eventBus.on('pagechanging', (data) => {
                            this.setHasInteracted()
                            if (data?.pageNumber !== data?.previous) {
                                this.$emit(EVENTS.pageChanged, data.pageNumber)
                            }
                        })
                        this.eventBus.on('pagerendered', () => {
                        // Not sure if this will be of use, but I'll leave it in case.
                        })

                        this.isLoaded = true
                        this.scrollToTop()
                        this.doTextSearch(this.searchText)
                    }
                } catch (error) {
                    console.error(`Received error following PDF request: ${ error?.message } for ${ url }`)
                }
            },

            setHasInteracted() {
                if (this.hasInteractedWithDocument === false) {
                    this.hasInteractedWithDocument = true
                    this.$emit(EVENTS.hasInteracted, true)
                }
            },

            scrollToTop() {
                // Need to check both as undefined !== 1, but don't want to trigger in that case.
                // Need the viewer initialised first.
                if (this.pdfViewer?.currentPageNumber && this.pdfViewer?.currentPageNumber !== 1) {
                    this.pdfViewer.currentPageNumber = 1
                }
            },

            doTextSearch(searchText, repeatSearch = false, findPrevious = false) {
                if (isNullOrWhitespace(searchText)) {
                    return
                }

                const sanitisedSearchText = this.getSanitisedSearchText(searchText)
                this.isSearchLoading = true
                this.eventBus?.dispatch('find', {
                    type: repeatSearch ? 'again' : '',
                    caseSensitive: false,
                    entireWord: false,
                    findPrevious,
                    highlightAll: true,
                    matchDiacritics: false,
                    phraseSearch: true,
                    query: sanitisedSearchText,
                })
            },

            /**
             * Resets the viewer and all related data
             */
            resetViewerCompletely() {
                const container = document.querySelector('#viewerContainer>#viewer')
                if (container) {
                    container.innerHTML = null
                }
                if (this.pdf) {
                    this.pdf.destroy()
                    this.pdf = null
                }
                if (this.loadingTask) {
                    this.loadingTask.destroy()
                    this.loadingTask = null
                }
                if (this.eventBus) {
                    this.eventBus.off('updatefindmatchescount')
                    this.eventBus.off('textlayerrendered')
                    this.eventBus.off('pagesinit')
                    this.eventBus.off('updatetextlayermatches')
                    this.eventBus.off('updatefindcontrolstate')
                    this.eventBus.off('pagechanging')
                    this.eventBus.off('pagerendered')
                    this.eventBus = null
                }
                this.isLoaded = false
                this.currentZoomLevel = this.currentZoom
                this.scrollToTop()
                this.resetViewerSearch()
                this.hasInteractedWithDocument = false
                this.hasScrolledDocument = false
                this.hasLoadingError = false
                this.findController = null
                this.linkService = null
                this.pdf = null
            },

            resetViewerSearch() {
                this.searchResultsCount = 0
                this.isSearchLoading = false
                this.selectedSearchResultNumber = 1
                this.doTextSearch('')
            },

            getSanitisedSearchText(searchText) {
                if (!isNullOrWhitespace(searchText)) {
                    if (this.isEnhancedDocument) {
                        return searchText.trim().replace(/\s/g, '') // remove spaces to support search
                    } else {
                        return searchText.trim()
                    }
                }
                return ''
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-pdf-viewer';
</style>
