<template>
    <div class="title-alerts">
        <div v-if="showLoadingSkeleton"
             class="title-alerts__loading-skeleton"
             data-test="asset-monitoring-loading-skeleton">
            <ow-loading-logo class="title-alerts__loading-skeleton__logo" />
        </div>
        <div v-else-if="showSplashPage"
             class="title-alerts__splash-page">
            <splash-page context="title"
                         :title="t('assetMonitoring.splashPage.title')"
                         :button-title="t('action.enableMonitoring')"
                         :matter-id="matterId"
                         :is-enabled="canEnableMonitoring"
                         @button-click="onEnableFeature" />
        </div>

        <div v-else>
            <am-manage-monitors-header context="title" />
            <am-header-actions :matter-id="matterId"
                               :has-items="hasItems"
                               class="title-alerts__filters">
                <ow-select v-model="selectedTitles"
                           :items="titleOptions"
                           :disabled="filtersDisabled"
                           class="title-alerts__filters--titles"
                           clearable
                           data-test-attribute="asset-monitoring-title-filter"
                           data-track-attribute="Asset Monitoring: Filter by Title"
                           flat
                           hide-details
                           is-dense
                           is-outlined
                           is-single-line
                           item-title="title"
                           item-value="value"
                           label="Titles"
                           multiple>
                    <template #selection="{ item, index }">
                        <p v-if="index ===0">
                            <span>{{ item.title }}</span> <span v-if="selectedTitles.length > 1">(+ {{
                                selectedTitles.length - 1
                            }})</span>
                        </p>
                    </template>
                </ow-select>
                <ow-select v-model="selectedNotificationSubTypes"
                           :items="notificationSubTypeOptions"
                           :disabled="filtersDisabled"
                           class="title-alerts__filters--types"
                           clearable
                           data-test-attribute="asset-monitoring-type-filter"
                           data-track-attribute="Asset Monitoring: Filter by sub-type"
                           hide-details
                           is-dense
                           is-flat
                           is-outlined
                           is-single-line
                           item-title="title"
                           item-value="value"
                           label="Types"
                           multiple>
                    <template #selection="{ item, index }">
                        <p v-if="index ===0">
                            <span>{{ item.title }}</span> <span v-if="selectedNotificationSubTypes.length > 1">(+ {{
                                selectedNotificationSubTypes.length - 1
                            }})</span>
                        </p>
                    </template>
                </ow-select>
            </am-header-actions>

            <am-data-grid :is-loading="assetMonitoringStore.isLoading"
                          :items="items"
                          :matter-id="matterId"
                          data-test="asset-monitoring-grid"
                          class="title-alerts__data-grid"
                          @refresh="refreshNotificationsHandler"
                          @sort-by="sortItemsByHandler"
                          @load-next-page="loadNextNotificationsPageHandler" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import debounce from 'lodash.debounce'
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import { NotificationSorting } from '@/api/notifications.api'
    import AmHeaderActions from "@/components/asset-monitoring/am-header-actions.vue"
    import AmManageMonitorsHeader from "@/components/asset-monitoring/am-manage-monitors-header.vue"
    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import AmDataGrid from '@/components/asset-monitoring/grid/data-grid.vue'
    import {NotificationSubType} from "@/components/asset-monitoring/notification-sub-type.enum"
    import SplashPage from "@/components/asset-monitoring/splash-page.vue"
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingLogo from "@/components/core/ow-loading-logo.vue"
    import OwSelect from "@/components/core/ow-select.vue"
    import OwSplashPage from "@/components/core/ow-splash-page.vue"
    import OwToggle from "@/components/core/ow-toggle.vue"
    import { Route } from '@/enums/route.enum'
    import { IOptionItem } from '@/interfaces/option-item.interface'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import {
        isNullOrEmpty,
        unique,
    } from '@/utils/array-utils'

    const assetMonitoringStore = useAssetMonitoringStore()

    const { t } = useI18n()
    const route = useRoute()
    const store = useStore()

    const items = computed((): Array<IAssetMonitoringNotification> => assetMonitoringStore.userNotifications)
    const matterId = computed<number>((): number => parseInt(route.params?.matterId?.toString()))
    const isLoadingSettings = computed(() => assetMonitoringStore.isLoadingSettings)
    const isLoadingNotifications = computed(() => assetMonitoringStore.isLoading)
    const isMonitoringActive = computed(() => assetMonitoringStore.notificationSettings.isActive)
    const canEnableMonitoring = computed(() => store.state.config?.featureFlags?.assetMonitoring && !isMonitoringActive.value)
    const isActive = computed (() => assetMonitoringStore.notificationSettings.isActive)
    const router = useRouter()

    // Need to add addresses from the VueX store into the notifications
    const totalNotificationsCount = computed<number>((): number => assetMonitoringStore.totalResults)
    const hasItems = computed((): boolean => !isNullOrEmpty(items.value) || totalNotificationsCount.value > 0)
    //If there are no notifications and no filters enabled there are truely no notifications, therefore disable the filters
    const filtersDisabled = computed((): boolean => {
        return hasItems.value === false &&
            selectedTitles?.value === null &&
            selectedNotificationSubTypes?.value === null
    })
    const matterAddresses = computed((): Record<string, Array<any>> => store.state?.matter?.currentMatter?.addresses)
    const setAddresses = () => {
        if (matterAddresses.value &&
            Object.keys(matterAddresses.value).length > 0 &&
            !isNullOrEmpty(items.value)) {
            items.value.forEach((item) => {
                item.address = matterAddresses.value[item.titleNumber]?.[0]?.address ?? null
            })
        }
    }
    watch(() => items.value, (notifications: Array<IAssetMonitoringNotification>): void => {
        setAddresses()
    }, { immediate: true })
    watch(() => matterAddresses.value, (addresses: Record<string, Array<any>>): void => {
        setAddresses()
    }, { immediate: true })

    const sortItemsByHandler = (value: string) => {
        switch (value) {
            case 'asc':
                assetMonitoringStore.updateSortBy(NotificationSorting.dateAscending, matterId.value)
                break
            default:
                assetMonitoringStore.updateSortBy(NotificationSorting.dateDescending, matterId.value)
                break
        }
    }

    const selectedTitles = ref<Array<string>>(null)
    const titleOptions = computed(() => unique(items.value.map((item) => item.titleNumber)))
    watch(() => selectedTitles.value, (titleNumbers: Array<string>): void => {
        debounceUpdateTitleNumbers()
    })
    const updateTitleNumbers = (): void => {
        assetMonitoringStore.updateSelectedTitleNumbers(matterId.value, selectedTitles.value)
    }
    const debounceUpdateTitleNumbers = debounce(updateTitleNumbers, 1000)
    const selectedNotificationSubTypes = ref<Array<IOptionItem>>(null)
    const notificationSubTypeOptions = computed((): Array<IOptionItem> => {
        return [
            {
                title: t('assetMonitoring.subType.boundaryUpdated.title'),
                value: NotificationSubType.BoundaryChange,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.EditionDateDoesntMatchOcdaResponse.title'),
                value: NotificationSubType.EditionDateDoesntMatchOcdaResponse,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.oc2DocumentUpdated.title'),
                value: NotificationSubType.NewDocumentInOCDA,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.ownershipUpdated.title'),
                value: NotificationSubType.OwnershipChange,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.pendingApplicationsUpdated.title'),
                value: NotificationSubType.CompletedPendingApplication,
                selected: false,
            },
            {
                title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
                value: NotificationSubType.CompaniesHouse,
                selected: false,
            },
        ]
    })
    watch(() => selectedNotificationSubTypes.value, (NotificationSubTypes: Array<IOptionItem>): void => {
        assetMonitoringStore.updateSelectedNotificationSubTypes(matterId.value, NotificationSubTypes.map(t => t.value.toString()))
    })

    // TODO: Add back in when required
    // const filterText = ref<string>('')

    const onEnableFeature = async () => {
        await router.push({
            name: Route.MattersMonitoringPreferences,
            params: { matterId: matterId.value.toString() },
        })
    }

    const refreshNotificationsHandler = async () => {
        await assetMonitoringStore.getNotifications(matterId.value)
    }

    const loadNextNotificationsPageHandler = async () => {
        await assetMonitoringStore.getNextNotificationsPage(matterId.value)
    }

    const showLoadingSkeleton = computed(() => {
        return isLoadingNotifications.value || isLoadingSettings.value
    })

    const showSplashPage = computed(() => {
        return !isLoadingSettings.value && !isActive.value && !isLoadingNotifications.value && items.value.length <= 0
    })
</script>

<style scoped lang="scss">
    @import './title-alerts';
</style>
