<template>
    <ow-modal v-model="dialogModel"
              persistent
              :data-test="dataTestId"
              :title="header">
        <div class="user-dialog px-3">
            <v-form ref="form"
                    v-model="isFormValid"
                    data-test="user-dialog-form">
                <section>
                    <p v-t="'dialog.user.userDetails'"
                       class="body-highlight" />
                    <section class="d-flex flex-column">
                        <fieldset class="d-flex gc-4">
                            <section v-if="userIsSystemAdmin"
                                     class="w-50"

                                     :class="{
                                         'w-100': !showOrganisationSections,
                                     }">
                                <label id="organisation-label"
                                       class="caption-regular"
                                       :class="{
                                           'disabled': !createUser,
                                       }"
                                       for="organisation">
                                    {{ $t('form.label.organisation') }}
                                </label>
                                <v-autocomplete id="organisation-id"
                                                v-model="formData.organisationId"
                                                :items="organisations"
                                                :rules="[rules.required]"
                                                auto-select-first
                                                :clearable="true"
                                                data-test="user-dialog-organisation"
                                                item-title="name"
                                                item-value="organisationId"
                                                variant="solo"
                                                density="compact"
                                                :disabled="!createUser"
                                                required />
                            </section>
                            <section v-if="showOrganisationSections"
                                     :class="{
                                         'w-100': !userIsSystemAdmin,
                                         'w-50': userIsSystemAdmin,
                                     }">
                                <label id="section-id-label"
                                       class="caption-regular"
                                       :class="{
                                           'disabled': !createUser || !formData.organisationId,
                                       }"
                                       for="section-id">
                                    {{ `${$t('form.label.section')}${createUser ? '*' : ''}` }}
                                </label>
                                <v-select id="section-id"
                                          v-model="formData.sectionId"
                                          :items="selectedOrganisation.sections"
                                          :disabled="!createUser || !formData.organisationId"
                                          data-test="user-dialog-section"
                                          item-title="name"
                                          item-value="organisationSectionId"
                                          variant="solo" />
                            </section>
                        </fieldset>
                        <fieldset class="d-flex gc-4">
                            <section :class="{
                                'disabled': !createUser,
                                'w-50': createUser,
                                'w-100': !createUser,
                            }">
                                <label id="email-label"
                                       class="caption-regular"
                                       for="email">
                                    {{ $t('form.label.email') }}
                                </label>
                                <v-text-field id="email"
                                              v-model.trim="email"
                                              :rules="createUser ? [rules.required, rules.validEmail, emailDomainValidation] : []"
                                              :disabled="!createUser"
                                              variant="solo"
                                              required
                                              data-test="user-dialog-email"
                                              :hint="createUser ? 'This will be their login' : null"
                                              type="email" />
                            </section>
                            <section v-if="createUser && selectedOrganisation.ssoOption !== ssoOptions.SSO_FORCED && userIsSystemAdmin"
                                     class="w-50">
                                <label id="email-label"
                                       class="caption-regular"
                                       for="email">
                                    {{ $t('form.label.password') }}
                                </label>
                                <v-text-field v-model="formData.password"
                                              :append-icon="showPassword ? '$visibility-off' : '$visibility'"
                                              :rules="createUser ? [rules.required, rules.passwordStrength]: []"
                                              :type="!showPassword ? 'password' : 'text'"
                                              data-test="user-dialog-password"
                                              :hint="$t('admin.user.label.passwordHint')"
                                              min="8"
                                              :required="createUser"
                                              variant="solo"
                                              :disabled="!createUser"
                                              @click:append="() => (showPassword = !showPassword)" />
                            </section>
                        </fieldset>
                        <fieldset class="d-flex gc-4">
                            <section class="w-33">
                                <label id="first-name-label"
                                       class="caption-regular"
                                       for="first-name">
                                    {{ $t('form.label.firstName') }}*
                                </label>
                                <v-text-field id="first-name"
                                              v-model="formData.firstName"
                                              :rules="[rules.required]"
                                              data-test="user-dialog-first-name"
                                              variant="solo"
                                              required />
                            </section>
                            <section class="w-33">
                                <label id="last-name-label"
                                       class="caption-regular"
                                       for="last-name">
                                    {{ $t('form.label.lastName') }}*
                                </label>
                                <v-text-field id="last-name"
                                              v-model="formData.lastName"
                                              :rules="[rules.required]"
                                              data-test="user-dialog-last-name"
                                              variant="solo"
                                              required />
                            </section>
                            <section class="w-33">
                                <label id="phone-label"
                                       class="caption-regular"
                                       for="phone">
                                    {{ $t('form.label.phoneNumber') }}
                                </label>
                                <v-text-field id="phone"
                                              v-model="formData.phone"
                                              variant="solo"
                                              data-test="user-dialog-phone" />
                            </section>
                        </fieldset>
                    </section>
                </section>
                <section class="d-flex row">
                    <fieldset class="w-50 d-flex flex-column flex-grow-1">
                        <p v-t="'dialog.user.products'"
                           class="body-highlight" />
                        <div class="user-dialog__product-container">
                            <div v-if="isNullOrEmpty(formData?.products)"
                                 class="body-caption">
                                {{ formData.organisationId ? $t('admin.user.label.noProducts') : $t('admin.user.label.selectOrganisation') }}
                            </div>
                            <ow-product v-for="(formProduct) in formData?.products ?? []"
                                        v-else
                                        :key="formProduct.id"
                                        v-model="formProduct.selected"
                                        v-model:licence-type="formData.licenceType"
                                        :product-name="formProduct.name"
                                        :account-active="formData.active"
                                        :max-premium-users="selectedOrganisation?.maxPremiumUsers ?? 0" />
                        </div>
                    </fieldset>
                    <v-divider class="mx-4"
                               vertical />
                    <fieldset class="w-50 d-flex flex-column">
                        <p v-t="'dialog.user.userSettings'"
                           class="body-highlight" />
                        <ow-checkbox id="active"
                                     v-model="formData.active"
                                     class="user-dialog__check"
                                     data-test="user-dialog-active"
                                     :label="$t('admin.user.label.activeAccount')" />
                        <ow-checkbox v-if="userIsSystemAdmin"
                                     id="admin"
                                     v-model="formData.admin"
                                     class="user-dialog__check"
                                     data-test="user-dialog-sys-admin"
                                     :label="$t('admin.user.label.systemAdminAccount')"
                                     :hint="$t('admin.user.hint.systemAdminAccount')"
                                     :disabled="!isOwUser" />
                        <ow-checkbox id="orgAdmin"
                                     v-model="formData.orgAdmin"
                                     :disabled="userEmail === formData.email && formData.orgAdmin"
                                     class="user-dialog__check"
                                     data-test="user-dialog-org-admin"
                                     :label="$t('admin.user.label.organisationAdminAccount')" />
                    </fieldset>
                </section>
                <section v-if="hasErrors"
                         class="d-flex error-text user-dialog__errors">
                    {{ displayErrorMessage }}
                </section>
            </v-form>
        </div>
        <template #actions>
            <ow-card-actions no-padding
                             has-secondary-button
                             :primary-button-text="createUser ? $t('action.add') : $t('action.save')"
                             :primary-button-loading="loading"
                             :primary-button-disabled="!isFormValid || !dirty"
                             primary-button-data-test="user-dialog-btn-submit"
                             secondary-button-data-test="user-dialog-btn-cancel"
                             @primary-button-click="$emit('submit', formData)"
                             @secondary-button-click="$emit('cancel')" />
        </template>
    </ow-modal>
</template>

<script lang="ts" setup>
    import isEqual from 'lodash.isequal'
    import {computed,
            nextTick,
            onMounted,
            ref,
            toRaw,
            watch} from 'vue'
    import {useI18n} from "vue-i18n"
    import {useStore} from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwProduct from '@/components/settings/product/ow-product.vue'
    import {ssoOptions} from "@/consts/sso-types"
    import { LicenceType } from "@/enums/licenceType"
    import {isNullOrEmpty} from "@/utils/array-utils"
    import {emailValidationRegExp,
            isNullOrWhitespace,
            unprintableCharactersRegExp} from '@/utils/string-utils'
    import {isOwEmailDomain} from '@/utils/user.utils'

    interface IFormData {
        sectionId: number | null,
        organisationId: number | null,
        email: string | null,
        password: string | null,
        firstName: string | null,
        lastName: string | null,
        phone: string | null,
        admin: boolean,
        orgAdmin: boolean,
        active: boolean,
        licenceType: number | null,
        products: any[],
    }

    const props = withDefaults(defineProps<{
        errorMessage?: string,
        loading: boolean,
        userIsSystemAdmin: boolean,

        organisations?: any[],
        selectedOrganisationId?: number,
        clearForm?: boolean,
        createUser?: boolean,

        dataTestId?: string,
        user?: any,
    }>(), {
        user: {},
    })

    const emit = defineEmits<{
        (e: 'submit', form: any),
        (e: 'cancel'),
    }>()

    const { t } = useI18n()
    const form = ref<any>()
    const selectedOrganisation = ref<any>({})
    const showPassword = ref(false)

    let formData = ref<IFormData>({
        organisationId: null,
        sectionId: null,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        phone: null,
        admin: false,
        orgAdmin: false,
        active: false,
        licenceType: null,
        products: [],
    })

    const originalFormData = ref<IFormData>(null)

    const emailPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|_)).{8,}$/
    const passwordValid = computed(() => {
        return emailPattern.test(formData.value.password)
    })

    const rules = ref({
        required: (value: string) => !isNullOrWhitespace(value) || t('label.required'),
        passwordStrength: (value: string) => {
            if (!(selectedOrganisation.value.ssoOption !== ssoOptions.SSO_FORCED)) {
                const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/
                return pattern.test(value) || t('label.weakPassword')
            }
        },
        validEmail: (value: string) => emailValidationRegExp.test(value) || t('label.invalidEmail'),
    })

    const isFormValid = ref(false)
    const displayErrorMessage = ref('')
    const store = useStore()

    const userEmail = computed(() => store.state.user.email)
    const hasErrors = computed(() => !isNullOrWhitespace(props.errorMessage))
    const organisation = computed(() => selectedOrganisation.value)
    const organisationName = computed(() => selectedOrganisation.value?.name)
    const showOrganisationSections = computed(() => formData.value.organisationId && selectedOrganisation.value?.sections?.length > 1)
    const isOwUser = computed(() => isOwEmailDomain(formData.value.email))
    const email = computed({
        get: () => formData.value.email,
        set: (value: string) => {
            formData.value.email = value.replace(unprintableCharactersRegExp, '').trim()
        },
    })
    const header = computed(() => props.createUser ? t('admin.user.label.addUser') : t('admin.user.label.editUser'))
    const dirty = ref(false)
    const dialogModel = defineModel()

    watch(() => props.errorMessage, (newVal) => {
        displayErrorMessage.value = newVal
        if (isNullOrWhitespace(newVal)) {
            displayErrorMessage.value = t('admin.user.error.createAccount')
        }
    }, {
        immediate: true,
    })

    watch(() => props.clearForm, (newVal) => {
        if (newVal) {
            resetFormData()
        }
    })

    onMounted(() => {
        setSelectedOrganisation(props.selectedOrganisationId)
        setFormData()
    })

    watch(() => props.selectedOrganisationId, (newVal) => {
        if (newVal) {
            setSelectedOrganisation(props.selectedOrganisationId)
        }
    })

    watch(() => formData.value.organisationId, (newVal) => {
        if (newVal) {
            setSelectedOrganisation(formData.value.organisationId)
        }
    })

    watch(() => formData.value, () => {
        nextTick(() => {
            checkDirty()
        })
    }, {
        deep: true,
    })

    const checkDirty = () => {
        dirty.value = !isEqual(toRaw(formData.value), toRaw(originalFormData.value))
    }

    const setFormData = () => {
        formData.value = {
            ...toRaw(props.user),
            products: props.user?.products?.map(p => (
                {
                    ...toRaw(p),
                    selected: props.createUser ? p.selected : p.availableToUser,
                }
            )) ?? [],
            licenceType: props.createUser ? LicenceType.Standard : props.user?.licenceType ?? LicenceType.Standard,
            active: props.createUser ? true : props.user.active,
            sectionId: props.user?.sectionId ?? null,
        }

        originalFormData.value = JSON.parse(JSON.stringify({ ...formData.value }))
    }

    const setSelectedOrganisation = (organisationId: number) => {
        let organisation: any
        if (organisationId) {
            selectedOrganisation.value = {}
            organisation = props.organisations.find(o => o.organisationId === organisationId)
            if (organisation) {
                nextTick(() => {
                    selectedOrganisation.value = organisation

                    if (props.createUser) {
                        formData.value.sectionId = organisation?.sections[0]?.organisationSectionId
                    }
                    formData.value.organisationId = organisation.organisationId
                })
            }
        } else {
            selectedOrganisation.value = {}
            formData.value.sectionId = null
        }

        // TODO: Why are we setting products here and then again in setFormData?
        if (props.createUser && organisation?.products) {
            formData.value.products = organisation?.products.map(x => ({ ...x, selected: false }))
        }
    }

    const resetFormData = () => {
        formData.value = {
            organisationId: null,
            sectionId: null,
            email: null,
            password: null,
            firstName: null,
            lastName: null,
            phone: null,
            admin: false,
            orgAdmin: false,
            active: false,
            licenceType: null,
            products: [],
        }
        form.value?.resetValidation()
    }

    const emailDomainValidation = (email: string) => {
        const validDomains = selectedOrganisation.value.validEmailDomains?.filter(d => !isNullOrWhitespace(d)) ?? []
        if (validDomains?.length > 0 && email) {
            return validDomains.some( (domain: string) => email.endsWith(domain)) || t('admin.user.label.invalidEmailDomain', { validDomains: validDomains.join(', ') })
        }
        return true
    }

    defineExpose({
        formData,
        setSelectedOrganisation,
        selectedOrganisation,
        email,
    })
</script>

<style lang="scss" scoped>
@import './user-dialog';
</style>
