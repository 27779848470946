<template>
    <div class="company-alerts-preferences d-flex flex-column gr-4">
        <section class="d-flex align-center justify-space-between gc-4">
            <p v-t="'assetMonitoring.preferences.companyGroupsMonitoring'"
               class="body-regular" />
            <ow-button is-primary
                       @click="onAddGroup">
                {{ $t('action.addCompanies') }}
            </ow-button>
        </section>
        <ow-card has-outline
                 border-rounded
                 rounded-lg
                 border>
            <div v-if="loading"
                 class="d-flex gr-4 flex-column">
                <ow-loading-skeleton v-for="i in 3"
                                     :key="i"
                                     height="100px" />
            </div>
            <div v-else-if="companyGroups?.length === 0"
                 class="d-flex gr-4 flex-column">
                <p v-t="'assetMonitoring.preferences.noCompanyGroups'"
                   class="body-copy--greyout body-italic text-center" />
            </div>
            <template v-for="(company, index) of companyGroups"
                      v-else
                      :key="company.id">
                <company-group-card class="pa-6"
                                    v-bind="company"
                                    :company-group="company"
                                    @toggle-group="onToggleGroup(company)"
                                    @edit-group="onEditGroup(company)" />
                <v-divider v-if="index !== companyGroups.length - 1" />
            </template>
        </ow-card>
    </div>
</template>

<script setup lang="ts">

    import {
        onMounted,
        ref,
    } from "vue"
    import { useRouter } from "vue-router"

    import CompanyGroupsApi, {
        IGetCompanyGroupResponse,
        IGetCompanyGroupsResponse,
    } from "@/api/company-groups.api"
    import CompanyGroupCard from "@/components/asset-monitoring/company-groups/company-group-card.vue"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwCard from "@/components/core/ow-card.vue"
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import { Route } from "@/enums/route.enum"
    const router = useRouter()
    const companyGroups = ref<IGetCompanyGroupResponse[]>()

    const props = defineProps<{
        matterId: string
    }>()

    const loading = ref(false)

    const onAddGroup = () => {
        router.push({
            name: Route.AssetMonitoringCompanyGroupsCreate,
            params: {
                matterId: props.matterId,
            },
        })
    }

    const onEditGroup = (company: IGetCompanyGroupResponse) => {
        router.push({
            name: Route.AssetMonitoringCompanyGroupsEdit,
            params: {
                matterId: props.matterId,
                groupId: company.id,
            },
        })
    }

    const onToggleGroup = async (company: IGetCompanyGroupResponse) => {
        const resp = await CompanyGroupsApi.updateCompanyGroup(company.id, {
            groupName: company.groupName,
            isEnabled: !company.isEnabled,
        })
        if (resp?.ok) {
            const index = companyGroups.value.findIndex((c) => c.id === company.id)
            if (index !== -1) {
                companyGroups.value[index].isEnabled = !company.isEnabled
            }
        }
    }

    onMounted(async () => {
        // Fetch company groups
        loading.value = true
        try {
            const resp = await CompanyGroupsApi.getCompanyGroups(props.matterId)
            if (resp?.data?.companyGroups) {
                companyGroups.value = resp.data.companyGroups
            }
        } finally {
            loading.value = false
        }
    })
</script>

<style scoped>

</style>