<template>
    <div class="ownership-corporate">
        <div v-if="hasWarnings"
             class="ownership-corporate__warnings">
            <v-icon class="ownership-corporate__warnings--icon"
                    size="small">
                $flag
            </v-icon>
            <ul>
                <li v-for="(flag, index) in warnings"
                    :key="index"
                    class="body-regular-small">
                    {{ flag }}
                </li>
            </ul>
        </div>

        <header class="ownership-corporate__header">
            <div :class="{
                'ownership-corporate-owner__split-container': useSplitContainer,
                'ownership-corporate-owner__container': !useSplitContainer,
            }">
                <div>
                    <span v-if="isHmlr"
                          class="ownership-corporate-owner__source-header">
                        <ow-icon-hmlr height="24px"
                                      width="24px" />
                        <span v-t="'titlePanel.cards.ownership.labels.register'"
                              class="body-highlight" />
                    </span>
                    <span v-if="isScottish"
                          class="ownership-corporate-owner__source-header">
                        <ow-icon-scotlis />
                        <span v-t="'titlePanel.cards.ownership.labels.titleSheet'"
                              class="body-highlight" />
                    </span>
                </div>
            </div>

            <div v-if="useSplitContainer"
                 class="ownership-corporate-owner__split-container ownership-corporate-owner__split-container--bordered">
                <div class="ownership-corporate-owner__source-header pl-2">
                    <ow-companies-house-icon class="ownership-corporate-owner__source-header--ch-icon"
                                             height="24px"
                                             width="50px" />
                    <span v-t="'titlePanel.cards.ownership.labels.companiesHouse'"
                          class="body-highlight" />
                </div>
            </div>
        </header>
    </div>

    <ul>
        <ownership-corporate-owner v-for="(owner, index) in owners"
                                   :key="index"
                                   :companies-house-profile="getCompaniesHouseProfile(owner.companyRegNumber)"
                                   :index="hasMultipleOwners ? index : -1"
                                   :is-companies-house-integration-enabled="isCompaniesHouseIntegrationEnabled"
                                   :is-hmlr="isHmlr"
                                   :is-loading="isLoading"
                                   :is-loading-companies-house-data="isLoadingCompaniesHouseData"
                                   :is-overseas-entity-matched="isOverseasEntityMatched(owner)"
                                   :is-owner-mismatched="isOwnerMismatched(owner)"
                                   :is-ocod-ccod-matched="ownerMatchesCcodOcod"
                                   :is-scottish="isScottish"
                                   :matter-id="matterId"
                                   :owner="owner"
                                   :show-companies-house-data="showCompaniesHouseInformation(owner)" />
    </ul>

    <div class="ownership-corporate__footer">
        <div :class="{
            'ownership-corporate-owner__split-container': useSplitContainer,
            'ownership-corporate-owner__container': !useSplitContainer,
        }">
            <span v-if="isLoading"
                  class="accents-italic expander--source">
                <span>{{ t('label.source') }}: </span>
                <ow-loading-skeleton v-if="isLoading"
                                     height="14px"
                                     width="100px" />
            </span>
            <i18n-t v-else
                    :keypath="source?.path"
                    class="accents-italic expander--source hide-in-percy"
                    scope="global"
                    tag="span">
                <template #date>
                    {{ source?.date }}
                </template>
                <template #icon>
                    <ow-icon-hmlr v-if="isHmlr"
                                  height="12"
                                  width="12" />
                    <ow-icon-scotlis v-if="isScottish" />
                </template>
            </i18n-t>
        </div>

        <div v-if="useSplitContainer"
             class="ownership-corporate-owner__split-container ownership-corporate-owner__split-container--bordered">
            <div class="ownership-corporate__footer">
                <i18n-t v-if="lastUpdatedDate"
                        :keypath="'titlePanel.cards.ownership.sources.companiesHouse'"
                        class="accents-italic expander--source hide-in-percy"
                        scope="global"
                        tag="span">
                    <template #date>
                        {{ lastUpdatedDate }}
                    </template>
                    <template #icon>
                        <ow-companies-house-icon class="ownership-corporate-owner__source-header--ch-icon" />
                    </template>
                </i18n-t>
                <i18n-t v-else
                        :keypath="'titlePanel.cards.ownership.sources.companiesHouseNoDate'"
                        class="accents-italic expander--source hide-in-percy"
                        scope="global"
                        tag="span">
                    <template #icon>
                        <ow-companies-house-icon class="ownership-corporate-owner__source-header--ch-icon" />
                    </template>
                </i18n-t>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import CompaniesHouseApi from '@/api/companies-house.api'
    import TitleInformationApi from '@/api/title-information.api'
    import OwCompaniesHouseIcon from '@/components/core/icons/ow-companies-house-icon.vue'
    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwIconScotlis from '@/components/core/icons/ow-icon-scotlis.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwnershipCorporateOwner from '@/components/title-panel/v2/cards/ownership-corporate-owner.vue'
    import { checkFlag } from '@/feature-flags'
    import { ICompaniesHouseData } from '@/interfaces/title/companies-house.interface'
    import { ICompaniesHouseROEData } from '@/interfaces/title/companies-house-roe-data.interface'
    import { IOwner } from '@/interfaces/title/owner.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { format } from '@/utils/date-utils'
    import {
        isNullOrWhitespace,
        pad,
    } from '@/utils/string-utils'

    const props = defineProps({
        isHmlr: Boolean,
        isScottish: Boolean,
        isLoading: Boolean,
        isRegisterPurchased: Boolean,
        isRegisterUploaded: Boolean,
        owners: Array as () => IOwner[],
        matterId: Number,
        selectedTitleNumber: String,
        selectedTitle: Object as () => any,
        isRegisterDataAvailable: Boolean,
        source: Object as () => { path: string, date: string },
    })

    const { t } = useI18n()
    const isSelectedTitleLoaded = computed(() => Boolean(props.selectedTitle))
    const hasMultipleOwners = computed(() => props.owners.length > 1)
    const useSplitContainer = computed<boolean>(() => {
        return isAnyCompaniesHouseDataShown.value || hasOverseasOwner.value || hasOwnerWithROE.value
    })

    // Companies House Data
    const retryAttempts = ref(0) // has the companies house data may not yet be available.
    const retryInterval = ref(2000) // retry the companies house datarequest interval (doubles on fail).
    const retryTimeout = ref(null) // the setTimeout value.
    const errorState = ref(false) // the any error staes.
    const isLoadingCompaniesHouseData = ref<boolean>(false)
    const  isCompaniesHouseIntegrationEnabled = computed(() => checkFlag('companies-house-integration', true))
    const ownerMatchesCcodOcod = ref<boolean>(false)
    const companiesHouseData = ref(null)
    const lastUpdatedDate = ref(null)

    const fetchCompaniesHouseDataForTitles = async ({ titleNumber, matterId }: { titleNumber: string, matterId?: number }) => {
        const data = await TitleInformationApi.getCompaniesHouseDataByTitleNumbers([ titleNumber ], matterId)
        if (!data) {
            return data
        }

        // Return the result
        return data.map(r => {
            // Add isActive property to the companies house data
            const companiesHouseData = r.companiesHouseData?.map(companyProfile => {
                companyProfile.isActive = companyProfile.companyStatus === 'Active'
                return companyProfile
            })

            return {
                companiesHouseData,
                titleNumber: r.titleNumber,
                lastUpdated: r.lastUpdatedCompaniesHouseDateTime,
            }
        }).find(x => x.titleNumber === titleNumber)
    }

    const getCompaniesHouseROEData = async (companyName: string): Promise<ICompaniesHouseROEData> => {
        try {
            const response = await CompaniesHouseApi.getROEData(companyName)
            if (response.ok) {
                return response.data
            }
        } catch (error) {
            // companiesHouseApiError.value = "There was a problem retrieving the ROE data from Companies House."
            console.error('There was a problem retrieving the ROE data from Companies House.', error)
        }
        return null
    }

    const refresh = async () => {
        // NOTE: If the title is purchased/uploaded don't use the matter id in the Companies House call
        const useMatterId = !(props.isRegisterUploaded || props.isRegisterPurchased)
        // Request the Companies House data
        const result = useMatterId
            ? await fetchCompaniesHouseDataForTitles({
                matterId: props.matterId,
                titleNumber: props.selectedTitleNumber,
            })
            : await fetchCompaniesHouseDataForTitles({
                titleNumber: props.selectedTitleNumber,
            })

        // Null means there was an error when Doc Manager tried to
        // get the Companies House info, in this case we don't do anything
        if (result === null) {
            isLoadingCompaniesHouseData.value = false
            return
        }

        // Undefined means network error, so let's re-try
        if (result === undefined) {
            if (retryInterval.value < 5 * 60 * 1000) { // stop trying after 5 minutes
                retryTimeout.value = setTimeout(async () => {
                    await refresh()
                }, retryInterval.value)
                retryInterval.value = retryInterval.value * 2
                retryAttempts.value++
            } else {
                errorState.value = true
                console.error('Unable to load Companies House data')
            }
        } else {
            companiesHouseData.value = result?.companiesHouseData !== undefined
                ? Object.fromEntries(result?.companiesHouseData?.map((x: ICompaniesHouseData) =>
                    [ x.companyRegistrationNumber, x ]))
                : null
            lastUpdatedDate.value = format(result?.lastUpdated, 'dd-MM-yyyy HH:mm:ss')
            for (const co of props.owners) {
                if (!isNullOrWhitespace(co.name) && co.isOverseas) {
                    const roeData = await getCompaniesHouseROEData(co.name)
                    co.overseasRegistrationNumber = roeData?.companyNumber
                }
            }

            // Info retrieved is available, reset error/retry state
            isLoadingCompaniesHouseData.value = false
            retryAttempts.value = 0
            errorState.value = false
        }
    }

    watch(() => isSelectedTitleLoaded.value, async (isLoaded: boolean) => {
        if (!isLoaded) {
            return
        }

        isLoadingCompaniesHouseData.value = true

        ownerMatchesCcodOcod.value = await TitleInformationApi.checkOwnerMatchWithCcodOcodByTitleNumber(props.selectedTitleNumber)

        // Register is unpurchased, so we can assume the owner matches the ccod/ocod as that is all we have
        if (ownerMatchesCcodOcod.value === undefined && !props.isRegisterPurchased) {
            // Assume they match so no flags are displayed
            ownerMatchesCcodOcod.value = true
        }

        if (isCompaniesHouseIntegrationEnabled.value) {
            retryAttempts.value = 0
            retryInterval.value = 2000

            // Load the Companies House data
            // We use a try finally block to ensure the loading state is reset
            try {
                await refresh()
            } finally {
                isLoadingCompaniesHouseData.value = false
            }
        } else {
            isLoadingCompaniesHouseData.value = false
        }

    }, { immediate: true })

    const formatCompanyRegNumber = (companyRegNumber: string): string => {
        return pad(companyRegNumber, 8, '0')
    }

    const getCompaniesHouseProfile = (companyRegNumber: string): ICompaniesHouseData | null => {
        if (!companiesHouseData.value) {
            return null
        }
        return companiesHouseData.value[formatCompanyRegNumber(companyRegNumber)]
    }
    const hasOwnerWithROE = computed<boolean>(() => props.owners.some((owner: IOwner) => owner?.overseasRegistrationNumber))
    const hasOverseasOwner = computed<boolean>(() => props.owners.some((owner: IOwner) => owner.isOverseas))

    const showCompaniesHouseInformation = (owner: IOwner) => {
        const enabled = isCompaniesHouseIntegrationEnabled.value
        const hasData = Boolean(companiesHouseData.value)
        const hasCompaniesHouseProfile = hasData ? Boolean(getCompaniesHouseProfile(owner.companyRegNumber)) : false

        return enabled && hasData && hasCompaniesHouseProfile
    }

    const isAnyCompaniesHouseDataShown = computed(() => props.owners.some((owner: IOwner) => showCompaniesHouseInformation(owner)))

    const isOwnerMismatched = (owner: IOwner) => {
        if (!isNullOrWhitespace(owner?.companyRegNumber)) {
            const companyHouseProfile = getCompaniesHouseProfile(owner.companyRegNumber)
            if (!companyHouseProfile) {
                return false
            }
            if (companyHouseProfile?.companyName.toUpperCase() !== owner.name.toUpperCase()) {
                return true
            }
        }
        return false
    }

    const isOverseasEntityMatched = (owner: IOwner): boolean => {
        return !isNullOrWhitespace(owner.overseasRegistrationNumber)
    }

    const anyCompanyNotActive = computed(() => companiesHouseData.value
        ? Object.values(companiesHouseData.value)
            .filter((x: ICompaniesHouseData) => x?.companyStatus !== 'Active')
            .map((x: ICompaniesHouseData) => x.companyStatus)
        : [])
    const anyCompanyOwnerMismatch = computed(() =>
        !isNullOrEmpty(props.owners.filter((owner: IOwner) => isOwnerMismatched(owner))))
    const areAllOverseasEntitiesMatched = computed((): boolean => {
        return props.owners
            .filter((owner: IOwner) => owner.isOverseas)
            .every((owner: IOwner) => isOverseasEntityMatched(owner))
    })
    const includesOverseasProprietor = computed((): boolean => !isNullOrEmpty(props.owners?.filter((p: IOwner) => p.isOverseas)))
    const warnings = computed<string[]>(() => {
        const flags = []
        if (includesOverseasProprietor.value) {
            if (areAllOverseasEntitiesMatched.value) {
                flags.push(t('companiesHouse.flags.matched'))
            } else {
                flags.push(t('companiesHouse.flags.notMatched'))
            }
        }

        if (!isNullOrEmpty(anyCompanyNotActive.value)) {
            flags.push(t('companiesHouse.flags.status', {
                status: anyCompanyNotActive.value.join(', '),
            }))
        }
        if (anyCompanyOwnerMismatch.value) {
            flags.push(t('companiesHouse.flags.name', {
                source: props.isRegisterDataAvailable ? 'the Title Register' : 'Land Registry',
            }))
        }
        if (ownerMatchesCcodOcod.value === false) {
            flags.push(t('titlePanel.cards.ownership.flags.ccodOcodMismatch'))
        }

        return flags
    })
    const hasWarnings = computed(() => !isNullOrEmpty(warnings.value) && !isLoadingCompaniesHouseData.value)

</script>

<style lang="scss" scoped>
    @import './ownership-corporate.scss';
</style>
