<template>
    <div class="am-manage-monitors-header">
        <i18n-t keypath="assetMonitoring.text.description"
                tag="p"
                scope="global"
                class="am-manage-monitors-header__description body-regular">
            <template #link>
                <router-link :to="{
                                 name: routeName,
                                 params: { matterId: matterId?.toString() }
                             }"
                             class="body-highlight am-manage-monitors-header__link"
                             data-test="asset-monitoring-link-to-manage"
                             data-track="Asset Monitoring: Link in text to navigate & manage monitors">
                    {{ t('action.preferences').toLowerCase() }}
                </router-link>
            </template>
        </i18n-t>
    </div>
</template>

<script setup lang="ts">
    import {computed} from "vue"
    import {useI18n} from "vue-i18n"
    import {useRoute} from "vue-router"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import {Route} from "@/enums/route.enum"

    const props = defineProps<{
        context: 'company' | 'title'
    }>()

    const { t } = useI18n()
    const route = useRoute()
    const matterId = computed<number>((): number => parseInt(route.params?.matterId?.toString()))
    const routeName = computed<string>(() => props.context === 'company' ? Route.AssetMonitoringCompanyAlertsPreferences : Route.AssetMonitoringTitleAlertsPreferences)
</script>

<style scoped lang="scss">
    @import './am-manage-monitors-header';
</style>
