<template>
    <div v-if="map != null"
         v-hotkey="keymap"
         class="map-options"
         @mouseleave="onMouseLeave">
        <split-zoom-control v-if="!isCreatingWalkthrough"
                            :is-site-visit-enabled="siteVisitEnabledValue"
                            :zoom-in-disabled="isMinZoom"
                            :zoom-out-disabled="isMaxZoom"
                            class="map-options__button"
                            @zoom-in-start="onZoomButtonPressStart"
                            @zoom-in-end="onZoomButtonPressEnd"
                            @zoom-out-start="onZoomButtonPressStart(true)"
                            @zoom-out-end="onZoomButtonPressEnd()" />

        <ow-button v-if="!siteVisitSelectingStartLocation && canAccessMapLayers"
                   class="map-options__button map-options__layers-button"
                   data-test="map-options-map-layers-btn"
                   data-track="MAP - Open map options"
                   @click="setLayersPanel(!openLayerPanel)">
            <template v-if="!openLayerPanel"
                      #iconPrefix>
                <ow-map-layers-icon />
            </template>
            <template v-else
                      #iconSuffix>
                <v-icon>
                    $close
                </v-icon>
            </template>
            {{ $t('map.options.mapLayers') }}
        </ow-button>

        <ow-button v-if="showSiteVisitButton"
                   class="map-options__button"
                   data-test="map-options-site-visit-btn"
                   data-track="MAP - Site visit button"
                   @click="showSiteVisit">
            <template v-if="!siteVisitEnabledValue"
                      #iconPrefix>
                <ow-icon-site-visit />
            </template>
            <template v-else
                      #iconSuffix>
                <v-icon>
                    $close
                </v-icon>
            </template>
            {{ $t('map.options.siteVisit') }}
        </ow-button>

        <map-download-site-plan v-if="showSnapshotControl && !isTopNavVisible"
                                class="map-options__button" />
    </div>
</template>

<script lang="ts">
    import { inject } from "vue"
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwIconSiteVisit from '@/components/core/icons/ow-icon-site-visit.vue'
    import OwMapLayersIcon from '@/components/core/icons/ow-map-layers-icon.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import MapDownloadSitePlan from '@/components/map/map-download-site-plan.vue'
    import SplitZoomControl from '@/components/map/split-zoom-control.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { inject as userProvider } from "@/composables/use-user"
    import { Route } from '@/enums/route.enum'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        MAP_ZOOM_IN,
        MAP_ZOOM_OUT,
    } from '@/store/modules/map/types'
    import { SITE_VISIT_SET_ENABLED } from '@/store/modules/site-visit/types'
    import { TITLE_MUTATE_COLLAPSE_PANEL } from '@/store/modules/titles/types'
    import {
        USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const EVENTS = {
        updateLayersPanel: 'update-layers-panel',
    }

    export default {
        name: 'MapOptions',

        components: {
            MapDownloadSitePlan,
            OwButton,
            OwIconSiteVisit,
            OwMapLayersIcon,
            SplitZoomControl,
        },

        mixins: [FlagsMixin],

        props: {
            openLayerPanel: {
                type: Boolean,
                required: false,
            },
        },

        setup() {
            const { isTopNavVisible } = useMapTopNav()
            const { hasAccessToMapLayers } = userProvider()
            return {
                isTopNavVisible,
                hasAccessToMapLayers,
            }
        },

        data() {
            return {
                isMapLayerButtonVisible: true,

                // Indicates one of the zoom buttons is pressed.
                isZoomButtonPressed: false,

                // Used to gradually increment the zoom level while the zoom button is being pressed.
                gradualZoomInterval: null,

                zoomButtonPollingTime: 200, // ms

                titlesNavMemo: false,
                titlesPanelMemo: false,
                canAccessMapLayers: false,
            }
        },

        computed: {
            ...mapState({
                currentMatter: (state: IState) => state.matter.currentMatter,
                map: (state: IState) => state.map.map,
                siteVisitEnabledValue: (state: IState) => state.siteVisit.enabled,
                siteVisitSelectingStartLocation: (state: IState) => state.siteVisit.selectingStartLocation,
                isCreatingWalkthrough: (state: IState) => state.walkthrough.creating,
                showMapTitlesNav: (state: IState) => state.user.showMapTitlesNav,
                isPanelCollapsed: (state: IState) => state.title.collapsePanel,
            }),

            mapView(): any {
                return this.map.getView()
            },

            isMaxZoom(): boolean {
                return this.mapView.getZoom() <= this.mapView.getMinZoom()
            },

            isMinZoom(): boolean {
                return this.mapView.getZoom() >= this.mapView.getMaxZoom()
            },

            isWalkthrough(): boolean {
                return this.$route.name === Route.MatterMapWalkthrough
            },

            showSnapshotControl(): boolean {
                return this.map != null && !this.siteVisitEnabledValue
            },

            keymap() {
                return {
                    esc: this.handleHotkeys,
                }
            },

            isEmptyMatter(): boolean {
                return isNullOrEmpty(this.currentMatter?.selectedTitles)
            },

            showSiteVisitButton() {
                return !this.isWalkthrough && this.$route.name !== Route.MatterSketches
            },
        },

        watch: {
            openLayerPanel: {
                handler(isPanelOpen: boolean): void {
                    // On old map layers panel, button visibility is controlled via Vue transaction
                    // setting it here displays the button before the transaction ends
                    this.isMapLayerButtonVisible = !isPanelOpen

                    if (isPanelOpen) {
                        // When opening the panel, close the titles nav and title panel and save their state
                        this.titlesNavMemo = this.showMapTitlesNav
                        this.mutateTitleListState(false)

                        this.titlesPanelMemo = this.isPanelCollapsed
                        this.collapseTitlePanel(true)
                    } else {
                        // When closing the panel, restore the titles nav state
                        // or do nothing if it's been expanded while the panel was open
                        this.mutateTitleListState(this.showMapTitlesNav || this.titlesNavMemo)

                        if (this.isPanelCollapsed && !this.titlesPanelMemo) {
                            this.collapseTitlePanel(false)
                        }
                    }
                },
                immediate: true,
            },

            'currentMatter.id': {
                handler(value): void {
                    if (value !== null && this.isEmptyMatter) {
                        this.mutateTitleListState(true)
                    }
                },
                immediate: true,
            },
        },

        async mounted() {
            this.canAccessMapLayers = await this.hasAccessToMapLayers()
        },

        methods: {
            ...mapActions({
                zoomIn: MAP_ZOOM_IN,
                zoomOut: MAP_ZOOM_OUT,
                setSiteVisitEnabled: SITE_VISIT_SET_ENABLED,
            }),

            ...mapMutations({
                mutateTitleListState: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                collapseTitlePanel: TITLE_MUTATE_COLLAPSE_PANEL,
            }),

            showSiteVisit(): void {
                this.setLayersPanel(false)
                this.setSiteVisitEnabled(!this.siteVisitEnabledValue)
            },

            setLayersPanel(show: boolean) {
                // Used to suppress layers panel v-transition in between
                // map (on) -> site visit (off) -> street view (on)
                // TODO: (DOBO) Remove the if condition the newMapLayersPanel ff
                if (!show || !this.siteVisitSelectingStartLocation) {
                    this.$emit(EVENTS.updateLayersPanel, show)
                }
            },

            // Handles zoom button click / hold for zoom in/out functionality.
            onZoomButtonPressStart(isZoomOut: boolean): void {
                this.isZoomButtonPressed = true

                setTimeout(() => {
                    if (this.isZoomButtonPressed === true && !this.siteVisitEnabledValue) {
                        // Zoom button is still being pressed.
                        clearInterval(this.gradualZoomInterval)
                        this.gradualZoomInterval = setInterval(() => {
                            // Increment/reduce zoom by a small amount.
                            const currentZoom = this.map.getView().getZoom()
                            const increment = isZoomOut === true ? -0.005 : 0.005
                            // Vuex doesn't need to be aware of these quick incremental changes to the underlying
                            // map object, so make the change directly.
                            this.map.getView().setZoom(currentZoom + increment)
                        }, 10)
                    } else {
                        // Zoom button is not currently pressed, so it's a 'click' event.
                        clearInterval(this.gradualZoomInterval)
                        if (isZoomOut === true) {
                            this.zoomOut()
                        } else {
                            this.zoomIn()
                        }
                    }
                }, this.zoomButtonPollingTime)
            },

            onZoomButtonPressEnd(): void {
                this.isZoomButtonPressed = false
                clearInterval(this.gradualZoomInterval)
            },

            onMouseLeave(): void {
                this.onZoomButtonPressEnd()
            },

            handleHotkeys(evt) {
                switch (evt.key) {
                    case 'Escape':
                        this.setLayersPanel(false)
                        break
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './map-options';
</style>
