<template>
    <matter-title-details-list-item :key="titleNumber"
                                    class="expandable-matter-title-details-list-item"
                                    :class="{ 'expanded': isExpanded }"
                                    :title-number="titleNumber"
                                    :address="addresses && addresses.length ? addresses.join(', ') : $t('titles.addressUnavailable')"
                                    :tenure="tenure"
                                    :is-visible="isVisible && !hasNoBoundary"
                                    :is-address-visible="!isExpanded"
                                    :is-selected="isSelected"
                                    :is-selectable="isSelectable"
                                    :is-highlighted="isHighlighted"
                                    :has-dropdown-menu="hasDropdownMenu"
                                    :visibility-button-tooltip="visibilityButtonTooltip"
                                    :custom-name="customName"
                                    :clickable-area="clickableArea"
                                    :label-colour="labelColour"
                                    :is-register-ordered="isRegisterOrdered"
                                    :is-register-uploaded="isRegisterUploaded"
                                    :register-response-date="registerResponseDate"
                                    :is-matter-link-share-user="isMatterLinkShareUser"
                                    :disable-visibility-button="disableVisibilityButton"
                                    :has-light-background="hasLightBackground"
                                    :has-visibility-button="hasVisibilityButton"
                                    :disabled="disabled"
                                    @click="onExpand"
                                    @selection-change="onSelectionChange"
                                    @visibility-button-click="onVisibilityButtonClick">
        <template v-if="isExpanded"
                  #belowContent>
            <div class="expandable-matter-title-details-list-item__below-content"
                 data-test="expandable-matter-title-details-list-item-expanded-content">
                <address-owner-grid :addresses="addresses"
                                    :owners="ownerNames"
                                    :is-loading-owners="isLoadingOwners"
                                    :owner-data-is-unavailable="ownerDataIsUnavailable" />
            </div>
        </template>
        <template v-if="isExpanded"
                  #actions>
            <ow-button v-if="!isTitlePanelVisible"
                       class="expandable-matter-title-details-list-item__action-button elevation-2"
                       data-test="expandable-matter-title-details-list-item-expanded-content-button"
                       :data-track="seeDetailsButtonDataTrack"
                       size="large"
                       @click="onClick">
                {{ $t('buttons.openTitle') }}
            </ow-button>
            <ow-button v-else-if="isTitlePanelV2Enabled"
                       data-test="expandable-matter-title-details-list-item-show-on-map-button"
                       :disabled="isLoading"
                       :is-loading="isLoading"
                       class="expandable-matter-title-details-list-item__action-button elevation-2"
                       data-track="show-on-map"
                       size="large"
                       @click="$emit('clear-titles')">
                {{ $t('buttons.showOnMap') }}
            </ow-button>
            <ow-button v-else
                       class="expandable-matter-title-details-list-item__action-button elevation-2"
                       data-test="expandable-matter-title-details-list-item-expanded-content-button"
                       :data-track="seeDetailsButtonDataTrack"
                       size="large"
                       @click="$emit('clear-titles')">
                {{ $t('buttons.closeTitle') }}
            </ow-button>
            <div v-if="hasNoBoundary"
                 class="expandable-matter-title-details-list-item__no-boundary-warning-wrapper"
                 data-test="expandable-matter-title-details-list-item-no-boundary-warning-wrapper">
                <ow-icon-text icon="$warning"
                              :text="$t('titles.unavailableBoundary')" />
            </div>
        </template>
        <template v-if="isExpanded"
                  #afterLabel>
            <ow-colored-label class="expandable-matter-title-details-list-item__tenure-label"
                              data-test="expandable-matter-title-details-list-item-tenure-label"
                              :theme="colouredLabelTheme"
                              is-extra-small>
                {{ tenure }}
            </ow-colored-label>
        </template>
        <template #dropdownMenuItems>
            <slot name="dropdownMenuItems" />
        </template>
    </matter-title-details-list-item>
</template>

<script lang="ts">
    import { computed } from "vue"

    import TitleInformationApi from '@/api/title-information.api'
    import OwButton from '@/components/core/ow-button.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import AddressOwnerGrid from '@/components/matter/titles/address-owner-grid.vue'
    import MatterTitleDetailsListItem from '@/components/matter/titles/matter-title-details-list-item.vue'
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import useTitlePanel from '@/composables/use-title-panel'
    import { TenureType } from '@/enums/tenure-type'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'ExpandableMatterTitleDetailsListItem',

        components: {
            AddressOwnerGrid,
            MatterTitleDetailsListItem,
            OwButton,
            OwColoredLabel,
            OwIconText,
        },

        mixins: [FeatureFlagsMixin],

        props: {
            tenure: {
                type: String,
                required: true,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            addresses: {
                type: Array,
                default: () => [],
                required: false,
            },
            isVisible: {
                type: Boolean,
                required: false,
            },
            visibilityButtonTooltip: {
                type: String,
                required: false,
                default: null,
            },
            isSelected: {
                type: Boolean,
                required: false,
                default: false,
            },
            isSelectable: {
                type: Boolean,
                required: false,
                default: true,
            },
            hasDropdownMenu: {
                type: Boolean,
                required: false,
                default: true,
            },
            clickableArea: {
                type: String,
                default: 'content',
                required: false,
                validator(val: string) {
                    return ['content', 'whole-item', 'none'].includes(val)
                },
            },
            customName: {
                type: String,
                required: false,
                default: null,
            },
            isRegisterOrdered: {
                type: Boolean,
                required: false,
            },
            isRegisterUploaded: {
                type: Boolean,
                required: false,
            },
            registerResponseDate: {
                type: String,
                required: false,
            },
            labelColour: {
                type: String,
                required: false,
                default: null,
            },
            disableVisibilityButton: {
                type: Boolean,
                required: false,
            },
            isHighlighted: {
                type: Boolean,
                required: false,
            },
            isExpanded: {
                type: Boolean,
                required: false,
            },
            hasNoBoundary: {
                type: Boolean,
                required: false,
            },
            seeDetailsButtonDataTrack: {
                type: String,
                required: false,
                default: undefined,
            },
            hasLightBackground: {
                type: Boolean,
                default: false,
            },
            isMatterLinkShareUser: {
                type: Boolean,
                default: false,
            },
            hasVisibilityButton: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            disableExpandCollapse: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'on-expand',
            'on-collapse',
            'selection-change',
            'click',
            'visibility-button-click',
            'title-click',
            'clear-titles',
        ],

        setup() {
            const { titleRecordSource } = useSelectedTitle()
            const { isTitlePanelVisible } = useTitlePanel()
            const isLoading = computed<boolean>(() => !titleRecordSource.value)
            return {
                isTitlePanelVisible,
                isLoading,
            }
        },
        data() {
            return {
                ownerNames: new Array<string>(),
                isLoadingOwners: false,
                hasLoadedOwners: false,
                ownerDataIsUnavailable: false,
            }
        },

        computed: {
            colouredLabelTheme() {
                switch (this.tenure) {
                    case TenureType.Freehold:
                        return 'tenure-freehold'
                    case TenureType.Leasehold:
                        return 'tenure-leasehold'
                    default:
                        return 'tenure-other'
                }
            },
        },
        watch: {
            async isExpanded(val: boolean) {
                if (val === true) {
                    if (!this.hasLoadedOwners) {
                        await this.populateOwnerData()
                    }
                    await this.$nextTick()
                    this.$el?.scrollIntoView({ block: 'nearest' })
                }
            },
        },
        mounted() {
            if (this.isExpanded) {
                this.populateOwnerData()
            }
        },
        methods: {
            onSelectionChange(val: boolean) {
                this.$emit('selection-change', val)
            },
            onClick() {
                this.$emit('click', this.titleNumber)
            },
            onVisibilityButtonClick() {
                this.$emit('visibility-button-click', this.titleNumber)
            },
            onExpand() {
                if (this.disableExpandCollapse) {
                    this.$emit('title-click', this.titleNumber)
                    return
                }
                if (this.isExpanded) {
                    this.$emit('on-collapse', this.titleNumber)
                } else {
                    this.$emit('on-expand', this.titleNumber)
                }
            },
            async populateOwnerData() {
                this.isLoadingOwners = true
                this.ownerNames = await TitleInformationApi.getOwnerNamesByTitleNumber(this.titleNumber)
                this.ownerDataIsUnavailable = isNullOrEmpty(this.ownerNames)
                this.hasLoadedOwners = true
                this.isLoadingOwners = false
            },
        },
    }
</script>

<style lang="scss">
    @import 'expandable-matter-title-details-list-item';
</style>
