<template>
    <div class="company-alerts">
        <div v-if="showLoadingSkeleton">
            <div class="company-alerts__loading-skeleton"
                 data-test="asset-monitoring-loading-skeleton">
                <ow-loading-logo class="company-alerts__loading-skeleton__logo" />
            </div>
        </div>
        <div v-else-if="showSplashPage"
             class="company-alerts__splash-page">
            <splash-page context="company"
                         :title="t('assetMonitoring.splashPage.titleCompany')"
                         :button-title="t('action.addCompany')"
                         :matter-id="matterId"
                         :is-enabled="isCompanyMonitorsEnabled"
                         @button-click="onAddToGroup" />
        </div>
        <div v-else>
            <am-manage-monitors-header context="company" />
            <company-alert-filters />
            <!-- Is loading will probably need it's own state for retrieving company monitor alerts. -->
            <am-data-grid :is-loading="false"
                          :items="items"
                          :matter-id="matterId"
                          data-test="company-monitors-grid"
                          class="company-alerts__data-grid"
                          @refresh="refreshNotificationsHandler"
                          @sort-by="sortItemsByHandler"
                          @load-next-page="loadNextNotificationsPageHandler" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
    } from "vue"
    import {useI18n} from "vue-i18n"
    import {useRoute,
            useRouter} from "vue-router"
    import {useStore} from "vuex"

    import CompanyGroupsApi from "@/api/company-groups.api"
    import {NotificationSorting} from "@/api/notifications.api"
    import AmManageMonitorsHeader from "@/components/asset-monitoring/am-manage-monitors-header.vue"
    import {IAssetMonitoringNotification} from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import CompanyAlertFilters from "@/components/asset-monitoring/company-alert-filters.vue"
    import AmDataGrid from "@/components/asset-monitoring/grid/data-grid.vue"
    import SplashPage from "@/components/asset-monitoring/splash-page.vue"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwLoadingLogo from "@/components/core/ow-loading-logo.vue"
    import AddToGroup from "@/components/matter/titles/matter-side-panel-add-titles/add-to-group.vue"
    import {Route} from "@/enums/route.enum"
    import {useAssetMonitoringStore} from "@/stores/asset-monitoring"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const assetMonitoringStore = useAssetMonitoringStore()
    const companyGroups = ref<any[]>([])

    const matterId = computed<number>((): number => parseInt(route.params?.matterId?.toString()))
    const isCompanyMonitorsEnabled = computed<boolean>(() => store.state.config.featureFlags?.assetMonitoringCompanyMonitorsTabs)
    onBeforeMount(async () => {
        if(!isCompanyMonitorsEnabled.value){
            await router.push({
                name: Route.Homepage,
            })
        }

        // check for company groups
        const resp = await CompanyGroupsApi.getCompanyGroups(matterId.value)
        companyGroups.value = resp.data?.companyGroups
        showSplashPage.value = isNullOrEmpty(companyGroups.value)
    })

    const onAddToGroup = () => {
        router.push({
            name: Route.AssetMonitoringCompanyGroupsCreate,
            params: {
                matterId: matterId.value,
            },
        })
    }

    // TODO: Implement in wire up ticket
    const items = computed((): Array<IAssetMonitoringNotification> => [])

    // TODO: Implement in future ticket
    const showLoadingSkeleton = computed<boolean>(() => false)
    const showSplashPage = ref<boolean>(false)

    // Utils
    const refreshNotificationsHandler = async () => {
        // Implement in future ticket
    }

    const sortItemsByHandler = (value: string) => {
        // Implement in future ticket
    }

    const loadNextNotificationsPageHandler = async () => {
        // Implement in future ticket
    }

</script>

<style scoped lang="scss">
    @import './company-alerts';
</style>
