<template>
    <div class="title-panel-register pa-0 --disable-scroll"
         data-test="title-details-panel-register">
        <div class="title-panel-register__content"
             :style="{
                 width: `${contentWidth}`,
             }">
            <order-documents-card data-section="registerDocument"
                                  :current-matter-id="currentMatterId"
                                  :is-loading="isLoading"
                                  :is-ordering-allowed="isOrderingAllowed"
                                  :official-copies="officialCopies"
                                  :selected-title="selectedTitle"
                                  :selected-title-number="selectedTitleNumber"
                                  :user-has-vts-charge="userHasVTSCharge"
                                  hide-title-plan
                                  :tab-controller="tabController"
                                  no-padding />

            <div v-if="hasBackdatedInfo"
                 class="title-panel-register__container--backdated">
                <div>
                    <ow-icon-text :is-italic-text="true"
                                  :is-single-line="false"
                                  :text="backdatedText"
                                  icon="$info" />
                </div>
            </div>

            <ow-register-card v-model:show-document="showDocument"
                              class="title-panel-register__entries"
                              :hide-flags="hideFlags"
                              :loading="isLoading"
                              :registers="enrichedRegisters"
                              :schedule-of-leases="scheduleOfLeases"
                              :tab-controller="tabController"
                              :is-loading="isLoading"
                              @title-number-selected="goToTitle"
                              @scroll-to-entry="filterTitlesText = $event"
                              @entry-clicked="onEntryClicked"
                              @view-plan-clicked="onViewPlanClick"
                              @view-link-clicked="onViewLinkClick" />
        </div>
        <document-tab v-if="showDocument"
                      ref="documentTabRef"
                      :style="{
                          height: `${documentHeight}px`,
                          width: `${documentWidth}`,
                      }"
                      :active-tab="activeDocumentTab"
                      :in-focus="false"
                      :matter-id="currentMatterId"
                      :pdf-page="pdfPage"
                      :register-metadata="registerMetadata"
                      :related-document-metadata="relatedDocumentMetadata"
                      :search-index="documentSearchResultIndex"
                      :search-text="filterTitlesText"
                      :title-number="selectedTitleNumber"
                      :title-plan-metadata="titlePlanMetadata"
                      class="title-panel-register__document"
                      @page-changed="pageChangedHandler"
                      @search-count-changed="documentSearchResultCount = $event"
                      @tab-changed="tabChangeHandler" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        nextTick,
        onMounted,
        provide,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { useStore } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import TitleInformationApi from '@/api/title-information.api'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OrderDocumentsCard from '@/components/title-panel/v2/cards/order-documents.vue'
    import DocumentTab from '@/components/title-panel/v2/register/document.vue'
    import OwRegisterCard from '@/components/title-panel/v2/register/register-card.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { ITitlePanelTabController } from "@/composables/use-title-panel"
    import useWindowResize from "@/composables/use-window-resize"
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { IEnrichedRegister } from '@/interfaces/documents/register.interface'
    import { ReviewAssistantDocumentTab } from '@/interfaces/review-assistant.interface'
    import { format } from '@/utils/date-utils'

    const props = withDefaults(defineProps<{
        isLoading: boolean,
        isOrderingAllowed: boolean,
        hideFlags: boolean,
        tabController: ITitlePanelTabController
    }>(), {
        isOrderingAllowed: true,
    })

    const emit = defineEmits([
        'title-number-selected',
    ])

    const store = useStore()
    const { t } = useI18n()
    const {
        hasBackdatedInfo,
        isRegisterAvailable,
        officialCopies,
        planAvailability,
        registerAvailability,
        registerEntriesDateMap,
        registerEntryDocuments,
        registerEntries,
        selectedTitle,
        selectedTitleNumber,
    } = useSelectedTitle()

    const currentMatterId = computed(() => store.state.matter.currentMatter.id)
    const titleSummaryCharge = computed(() => store.state.user.titleSummaryCharge)
    const userHasVTSCharge = computed(() => titleSummaryCharge.value !== null)
    const backdatedText = computed(() => t('backdatedInfoFlag', { date: selectedTitle?.value.record?.bgProprietorshipData?.dateDataOC }))

    const showDocument = ref<boolean>(false)
    const activeDocumentTab = ref<number>(ReviewAssistantDocumentTab.TitleRegister)
    const pdfPage = ref<number>(1)
    const documentSearchResultIndex = ref<number>(0)
    const documentSearchResultCount = ref<number>(0)
    const registerMetadata = ref<IDocumentMetadata>(null)
    const titlePlanMetadata = ref<IDocumentMetadata>(null)
    const relatedDocumentMetadata = ref<IDocumentMetadata>(null)
    const filterTitlesText = ref<string>(null)
    const documentTabRef = ref<any>(null)
    const contentWidth = ref<string>('100%')
    const documentHeight = ref<number>(0)
    const documentWidth = ref<string>('0%')

    const {debouncedResize} = useWindowResize({
        debounceInterval: 50,
        onResize: async ({ offsetHeight }) => {
            documentWidth.value = showDocument.value ? '30%' : '0'
            documentHeight.value = offsetHeight
            await nextTick()

            // get actual client width of the document
            if (showDocument.value) {
                const documentClientWidth = documentTabRef.value?.$el?.clientWidth
                contentWidth.value = `calc(100% - ${ documentClientWidth }px)`
            } else {
                contentWidth.value = '100%'
            }
        },
    })

    onMounted(() => {
        debouncedResize()

        props.tabController.addTabSection(TitlePanelTabName.Register, {
            id: 'registerDocument',
            order: -1,
        })
    })

    watch(() => showDocument.value, () => {
        debouncedResize()
    })

    watch(() => registerAvailability.value, (newVal: any) => {
        if (newVal) {
            registerMetadata.value = {
                documentType: 'Register',
                documentDownloadUrl: newVal.downloadUrl,
                documentId: newVal.documentId,
            }
        }
    }, { immediate: true, deep: true })

    watch(() => planAvailability.value, async (newVal) => {
        if (!newVal) {
            return
        }

        if (!newVal.documentId) {
            titlePlanMetadata.value = newVal
        } else {
            titlePlanMetadata.value = await DocumentsApi.getDocumentMetadataByTypeAndId(
                HighLevelDocumentType.TitlePlan,
                newVal.documentId,
            )
        }
    }, { immediate: true, deep: true })

    const pageChangedHandler = (newPage: number): void => {
        pdfPage.value = newPage
        filterTitlesText.value = null
    }

    const tabChangeHandler = async (newTab: ReviewAssistantDocumentTab): Promise<void> => {
        activeDocumentTab.value = newTab
    }

    const goToTitle = (titleNumber: string) => {
        emit('title-number-selected', titleNumber)
    }

    const enrichedRegisters = computed<IEnrichedRegister[]>(() => {
        const landInPlanRegex = /the[\s]*?( freehold| leasehold)?[\s]+?land[\s\S]+?on[\s]+?the[\s]+?plan/i

        for (const register of registerEntries.value) {
            for (const entry of register.entries) {
                entry.referencedDocuments = registerEntryDocuments.value?.filter(({ entryNumberField }) => entryNumberField.includes(entry.code))

                const date = registerEntriesDateMap.value.get(entry.code)

                if (Date.parse(date)) {
                    entry.date = format(date)
                }

                entry.hasPlanReference = landInPlanRegex.test(entry.displayString)
            }
        }

        return registerEntries.value
    })

    const route = useRoute()
    const titleNumber = computed<string>(() => Array.isArray(route.params.titleNumber) ? route.params.titleNumber[0] : route.params.titleNumber)
    const scheduleOfLeases = ref<any>(null)
    watch(() => route.name, async (routeName: string) => {
        if (routeName === Route.MatterMapTitle) {
            if (isRegisterAvailable.value) {
                const scheduleOfNoticeOfLeasesList = await TitleInformationApi.getTitleScheduleOfNoticeOfLeases(currentMatterId.value, [ titleNumber.value ])
                scheduleOfLeases.value = scheduleOfNoticeOfLeasesList[0]
            }
        }
    }, { immediate: true })

    const onViewPlanClick = (): void => {
        showDocument.value = true
        activeDocumentTab.value = ReviewAssistantDocumentTab.TitlePlan
    }

    const onViewLinkClick = (document: ICopiesFiledDocument): void => {
        showDocument.value = true
        activeDocumentTab.value = ReviewAssistantDocumentTab.RelatedDocument
        relatedDocumentMetadata.value = document
    }

    const onEntryClicked = (text: string): void => {
        filterTitlesText.value = text
        showDocument.value = true
        activeDocumentTab.value = ReviewAssistantDocumentTab.TitleRegister
    }
</script>

<style lang="scss">
    @import './register';
</style>
