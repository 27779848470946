<template>
    <section class="title-panel-card ownership"
             data-section="ownership">
        <div class="title-panel-card__content">
            <h2 v-t="'titlePanel.cards.ownership.title'"
                class="label-caps-medium ownership__content--title" />

            <ul v-if="isRegisterUnpuchasedAndPrivatelyOwned"
                class="card-content ownership__unpurchased">
                <unpurchased-register :is-loading="isLoading"
                                      label-path="titlePanel.cards.ownership.labels.purchaseRegister" />
            </ul>

            <ul v-else-if="onlyPrivateOwnersAndNotOverseas">
                <ownership-private-owner v-for="(owner, index) in deduplicatedOwners"
                                         :key="index"
                                         :index="hasMultipleOwners ? index : -1"
                                         :is-hmlr="isHMLR"
                                         :is-loading="isLoading"
                                         :is-scottish="isScottish"
                                         :owner="owner"
                                         :source="registerSourceI18NPath" />
            </ul>

            <div v-else
                 class="ownership__data">
                <ownership-corporate :is-hmlr="isHMLR"
                                     :is-loading="isLoading"
                                     :is-register-data-available="isRegisterAvailable"
                                     :is-register-purchased="isRegisterPurchased"
                                     :is-register-uploaded="isRegisterUploaded"
                                     :is-scottish="isScottish"
                                     :matter-id="matterId"
                                     :owners="deduplicatedOwners"
                                     :selected-title="selectedTitle"
                                     :selected-title-number="selectedTitleNumber"
                                     :source="registerSourceI18NPath" />
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'

    import OwnershipCorporate from '@/components/title-panel/v2/cards/ownership-corporate.vue'
    import OwnershipPrivateOwner from '@/components/title-panel/v2/cards/ownership-private-owner.vue'
    import UnpurchasedRegister from '@/components/title-panel/v2/cards/unpurchased-register.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { ITitlePanelTabController } from '@/composables/use-title-panel'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { format } from '@/utils/date-utils'

    const props = defineProps<{
        isLoading?: boolean,
        tabController: ITitlePanelTabController
        matterId: number,
    }>()

    props.tabController.addTabSection(TitlePanelTabName.Summary, {
        id: 'ownership',
        order: 100,
    })

    const {
        isRegisterPurchased,
        isRegisterUploaded,
        deduplicatedOwners,
        onlyPrivateOwnersAndNotOverseas,
        titleMetadata,
        isScottish,
        editionDate,
        ocodCcodLastUpdated,
        isHMLR,
        selectedTitle,
        selectedTitleNumber,
        isRegisterAvailable,
        isRegisterOrdering,
        isSelectedTitleLoaded,
    } = useSelectedTitle()

    const hasMultipleOwners = computed(() => deduplicatedOwners.value.length > 1)

    const isRegisterUnpuchasedAndPrivatelyOwned = computed(() => !isRegisterPurchased.value && onlyPrivateOwnersAndNotOverseas.value)

    const registerSourceI18NPath = computed<any>(() => {
        const formattedEditionDate = format(new Date(editionDate.value), 'dd-MM-yyyy')
        const formattedLastUpdated = format(new Date(ocodCcodLastUpdated.value), 'dd-MM-yyyy')
        if (isScottish.value && isRegisterAvailable.value) {
            return {
                path: 'titlePanel.cards.ownership.sources.titleSheet',
                date: formattedEditionDate,
            }
        }
        if (isHMLR.value && isRegisterAvailable.value) {
            return {
                path: 'titlePanel.cards.ownership.sources.register',
                date: formattedEditionDate,
            }
        }
        if (titleMetadata.value?.isCCOD) {
            return {
                path: 'titlePanel.cards.ownership.sources.ccod',
                date: formattedLastUpdated,
            }
        }
        if (titleMetadata.value?.isOCOD) {
            return {
                path: 'titlePanel.cards.ownership.sources.ocod',
                date: formattedLastUpdated,
            }
        }
        if (!isNullOrEmpty(deduplicatedOwners.value)) {
            return {
                path: 'titlePanel.cards.ownership.sources.unavailable',
                date: '',
            }
        }
        return ''
    })
</script>

<style lang="scss"
       scoped>
@import './ownership';
</style>
