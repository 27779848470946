<template>
    <div class="title-alerts-preferences d-flex flex-column gr-4">
        <div class="monitoring-preferences__instructions">
            <p v-t="'assetMonitoring.preferences.instructions'"
               class="body-regular" />
        </div>

        <section class="monitoring-preferences__content">
            <preference-presets-card :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     data-track="Asset Monitoring: Orbital recommended preference card"
                                     data-test="orbital-recommended-preference-card"
                                     @update="updateSettings" />

            <title-details-card :is-loading="isUpdating"
                                :settings="settings"
                                class="monitoring-preferences__content--row"
                                @update="updateSettings" />
        </section>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'

    import { INotificationSettings } from '@/api/notifications.api'
    import PreferencePresetsCard from '@/components/matter/settings/monitoring-preferences/preference-presets-card.vue'
    import TitleDetailsCard from '@/components/matter/settings/monitoring-preferences/title-details-card.vue'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const { t } = useI18n()
    const route = useRoute()
    const store = useAssetMonitoringStore()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const settings = computed<INotificationSettings>(() => store.notificationSettings)
    const isUpdating = ref(false)

    onMounted(() => {
        if (!store.areNotificationSettingsLoaded) {
            store.getNotificationSettings(currentMatterId.value)
        }
    })

    const updateSettings = async (request: INotificationSettings) => {
        isUpdating.value = true
        let data = {
            ...request,
        }
        try {
            await store.updateNotificationSettings(currentMatterId.value, data)
            store.notificationSettings = data
        } catch (error) {
            console.error('Error updating notification settings', error)
        } finally {
            isUpdating.value = false
        }
    }
</script>

<style lang="scss" scoped>
    @import './title-alerts-preferences';
</style>
