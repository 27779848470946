import { computed } from 'vue'
import { useStore } from 'vuex'

import { DOCUMENT_SOURCE } from '@/consts/document-source'
import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
import { ILinkedIndicator } from '@/interfaces/title/linked-indicators.interface'
import { IOwner } from '@/interfaces/title/owner.interface'
import { MATTER_HAS_TITLE_NUMBER } from '@/store/modules/matter/types'
import {
    TITLE_GET_IS_PLAN_PURCHASED,
    TITLE_GET_IS_REGISTER_PURCHASED,
    TITLE_GET_OWNS_OC2_DOCUMENTS,
} from '@/store/modules/titles/types'
import { isNullOrEmpty } from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { TENURE_FULL_FROM_CODE } from '@/utils/title-enums'

export const useSelectedTitle = (): any => {
    const store = useStore()

    const selectedTitleNumber = computed<string>(() => store.state.title.selectedTitleNumber)
    const selectedTitle = computed<any>(() => store.state.title.selectedTitle)
    const isSelectedTitleInCurrentMatter = computed<boolean>(() => store.getters[MATTER_HAS_TITLE_NUMBER](selectedTitleNumber.value))
    const isSelectedTitleLoaded = computed<boolean>(() => selectedTitle.value?.record ? Object.keys(selectedTitle.value?.record).length > 0 : false)
    const isScottish = computed((): boolean => selectedTitle.value?.record?.source === DOCUMENT_SOURCE.SCOTLAND)
    const isHMLR = computed((): boolean => selectedTitle.value?.record?.source === DOCUMENT_SOURCE.HMLR)
    const allAddresses = computed<string[]>(() => selectedTitle.value?.record?.addresses?.map((a: any) => a.address))
    const titleAddress = computed<string>(() => {
        return allAddresses.value?.[0]
    })
    const editionDate = computed<string>((): string => selectedTitle.value?.record?.editionDate)
    const titleRecordSource = computed<string>(() => selectedTitle.value?.record?.source)
    const officialCopiesAvailability = computed<any>(() => selectedTitle.value?.officialCopiesAvailability)

    // Title Register
    const isRegisterPurchased = computed<boolean>(() => store.getters[TITLE_GET_IS_REGISTER_PURCHASED])
    const isRegisterUploaded = computed<boolean>(() => officialCopiesAvailability.value?.results?.titleRegister?.documentSource === DOCUMENT_SOURCE.UPLOADED)
    const registerDocumentId = computed<string>(() => selectedTitle.value?.record?.titleMetadata?.registerDocumentId)
    const isRegisterViewable = computed<boolean>(() => Boolean(officialCopiesAvailability.value.results?.titleRegister?.documentId))
    const isRegisterOrdering = computed<boolean>(() => Boolean(officialCopiesAvailability.value.results?.titleRegister?.loadingOrder))
    const registerResponseDate = computed<string>(() => officialCopiesAvailability.value.results?.titleRegister?.responseDateValue)
    const isRegisterAvailable = computed<boolean>((): boolean => {
        return isSelectedTitleInCurrentMatter.value
            && (isRegisterPurchased.value || isRegisterUploaded.value)
    })
    const leaseTermText = computed<string[]>(() => { //
        const proprietorshipData = selectedTitle.value?.record?.bgProprietorshipData
        if (proprietorshipData != null) {
            if (proprietorshipData.leaseField != null) {
                return proprietorshipData.leaseField.map((item: any) => item.leaseTermField)
            }
        }
        return []
    })
    const linkedIndicators = computed<ILinkedIndicator[]>(() => selectedTitle.value?.record?.bgProprietorshipData?.linkedIndicators)
    const registerAvailability = computed<any>(() => selectedTitle.value?.officialCopiesAvailability?.results?.titleRegister)

    const registerEntryData = computed(() => selectedTitle.value?.record?.bgProprietorshipData)
    const registerEntryDocuments = computed(() => registerEntryData.value?.documentDetails ?? [])
    const registerEntries = computed(() => registerEntryData.value?.registers ?? [])
    const registerEntriesDateMap = computed((): any => {
        const data = registerEntryData.value

        if (!data) {
            return new Map()
        }

        const entries = Object.keys(data)
            .map(key => data[key]?.entry)
            .filter(entry => entry)
            .flat()
            .map(({ code, date }) => [ code, date ]) as [ string, string ][]

        return new Map(entries)
    })

    const titleMetadata = computed((): any => selectedTitle.value?.record?.titleMetadata)
    const ocodCcodLastUpdated = computed<string>(() => titleMetadata.value?.lastUpdated)
    const proprietorships = computed<any[]>((): any => selectedTitle.value?.record?.proprietorships)
    const proprietorshipIncludesCorporateOrCompany = computed((): boolean =>
        !isNullOrEmpty(proprietorships.value) &&
        proprietorships.value.some((owner: any) =>
            owner.proprietorship?.toLowerCase().includes('corporate') ||
            owner.proprietorship?.toLowerCase().includes('company')))
    const owners = computed((): IOwner[] => proprietorships.value ?? [])
    const deduplicatedOwners = computed((): IOwner[] => {
        return owners.value
            .reduce((deduplicated, owner) => {
                if (!deduplicated.some(o => o.name.toLowerCase() === owner.name.toLowerCase())) {
                    deduplicated.push(owner)
                }

                return deduplicated
            }, [])
    })
    const hasCompanyRegNumber = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.companyRegNumber)
    const onlyPrivateOwnersAndNotOverseas = computed(() => deduplicatedOwners.value.every(owner =>
        !hasCompanyRegNumber(owner) &&
        !owner.isOverseas &&
        !proprietorshipIncludesCorporateOrCompany.value))
    const hasBackdatedInfo = computed(() => titleMetadata.value?.isBackOrderCopy)

    // Title Plan
    const isSelectedTitlePlanPurchased = computed<boolean>(() => store.getters[TITLE_GET_IS_PLAN_PURCHASED])
    const titlePlanDocumentId = computed<string>(() => selectedTitle.value?.record?.titleMetadata?.titlePlanDocumentId)
    const planAvailability = computed<any>(() => selectedTitle.value?.officialCopiesAvailability?.results?.titlePlan)

    // OC2 Documents
    const userOwnsOC2Documents = computed<boolean>(() => store.getters[TITLE_GET_OWNS_OC2_DOCUMENTS])
    const officialCopies = computed(() => selectedTitle.value?.officialCopiesAvailability?.results)
    const copiesFiledDocuments = computed<IDocumentMetadata[]>(() => officialCopies.value?.referredToDocuments)
    const copiesFiledDocumentsLoading = computed<boolean>(() => store.state.title?.selectedTitle?.officialCopiesAvailability?.loading)

    // Tenure
    const tenure = computed<string>(() => selectedTitle.value?.record?.tenure)
    const isFreehold = computed<boolean>(() => tenure.value?.toLowerCase().includes('freehold'))
    const isLeasehold = computed<boolean>(() => tenure.value?.toLowerCase().includes('leasehold'))
    const isOtherTenure = computed<boolean>(() => !isFreehold.value && !isLeasehold.value)
    const estateInterest = computed<string>(() => selectedTitle.value?.record?.estateInterest)
    const isCautionAgainstFirstRegistration = computed(() => selectedTitle?.value.record?.tenure === TENURE_FULL_FROM_CODE.CN)

    const boundaryAvailable = computed(() => {
        return selectedTitle.value.bboxGeom !== null
    })

    const daylist = computed(() => selectedTitle.value?.daylist)
    const loadingDaylist = computed(() => selectedTitle.value?.loadingDaylist)

    return {
        isSelectedTitleLoaded,
        selectedTitleNumber,
        selectedTitle,
        isRegisterPurchased,
        isRegisterUploaded,
        isSelectedTitleInCurrentMatter,
        isRegisterAvailable,
        isRegisterViewable,
        isRegisterOrdering,
        isScottish,
        isHMLR,
        editionDate,
        registerResponseDate,
        registerDocumentId,
        leaseTermText,
        isSelectedTitlePlanPurchased,
        titlePlanDocumentId,
        userOwnsOC2Documents,
        titleAddress,
        allAddresses,
        tenure,
        isFreehold,
        isLeasehold,
        isOtherTenure,
        estateInterest,
        titleRecordSource,
        boundaryAvailable,
        isCautionAgainstFirstRegistration,
        officialCopiesAvailability,
        deduplicatedOwners,
        owners,
        proprietorships,
        titleMetadata,
        ocodCcodLastUpdated,
        daylist,
        loadingDaylist,
        copiesFiledDocuments,
        linkedIndicators,
        onlyPrivateOwnersAndNotOverseas,
        copiesFiledDocumentsLoading,
        hasBackdatedInfo,
        officialCopies,
        planAvailability,
        registerAvailability,
        registerEntryData,
        registerEntryDocuments,
        registerEntries,
        registerEntriesDateMap,
    }
}
