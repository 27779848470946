<template>
    <ow-full-page-lock v-model="showLock"
                       :feature-id="FeatureId.Planning"
                       class="pa-6 full-width full-height"
                       is-card>
        <div class="d-flex flex-column planning-container-results"
             data-test="planning-results-list">
            <title-panel-card :subtitle="planningData ? subtitle : null"
                              data-test="planning-container"
                              title="Planning applications">
                <template #titleSuffix>
                    <ow-button :disabled="disableExport"
                               class="boundary__content--matter-button mx-2"
                               data-test="planning-actions-export-as-csv"
                               data-track="planning-actions-export-as-csv"
                               is-flat
                               is-text
                               @click="exportAsCSV">
                        {{ $t('action.exportToExcel') }}
                    </ow-button>
                </template>
                <section>
                    <!-- Rate limited -->
                    <div v-if="planningData"
                         class="planning-container__planning-view">
                        <v-btn v-if="promptForRetry"
                               :disabled="isRateLimited"
                               class="planning-container__retry-button"
                               color="primary"
                               data-test="planning-retry-button"
                               data-track="planning-retry-button"
                               @click="onRetryClick">
                            Retry
                        </v-btn>

                        <!-- Not rate limited -->
                        <div v-else>
                            <planning-actions v-if="!loadingResults"
                                              :show-advanced-options="showAdvancedOptions" />

                            <v-progress-linear v-if="loadingResults"
                                               :indeterminate="true"
                                               color="primary"
                                               data-test="title-details-panel-loading" />

                            <div class="d-flex grow">
                                <planning-results v-if="!loadingResults"
                                                  :scroll-element-container="scrollElementContainer" />
                                <ow-planning-map :title-number="selectedTitleNumber"
                                                 class="planning-map"
                                                 data-test="ow-planning-map"
                                                 enable-map-interactions
                                                 style="height: inherit;" />
                            </div>
                        </div>
                    </div>
                    <div v-else
                         class="planning-container__promo-view">
                        Key benefits:
                        <ul>
                            <li>Search for planning applications up to 100m from the property</li>
                            <li>Filter by planning decision</li>
                            <li>Search by keyword</li>
                            <li>Sort by date</li>
                        </ul>
                        <v-btn class="planning-container__button-register-interest"
                               color="primary"
                               data-test="planning-container-button-register-interest"
                               @click="registerPlanningInterest">
                            Request Access
                        </v-btn>
                    </div>
                </section>
            </title-panel-card>
        </div>
    </ow-full-page-lock>
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import {
        onBeforeMount,
        ref,
    } from 'vue'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwPlanningMap from '@/components/core/maps/ow-planning-map.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwFullPageLock from '@/components/core/ow-full-page-lock.vue'
    import TitlePanelCard from '@/components/title-panel/v2/cards/title-panel-card.vue'
    import PlanningActions from '@/components/title-panel/v2/planning/planning-actions.vue'
    import PlanningResults from '@/components/title-panel/v2/planning/planning-results.vue'
    import { FeatureId } from '@/composables/use-licence-controller'
    import { inject as userProvider } from '@/composables/use-user'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import {
        PLANNING_EXPORT_CSV,
        PLANNING_MUTATE_DISTANCE,
        PLANNING_MUTATE_FORCE_PLANNING_PROVIDER,
        PLANNING_RETRY_CURRENT_SEARCH,
        PLANNING_UPDATE_FILTERED_RESULTS,
    } from '@/store/modules/planning/types'
    import {
        LOGGING_LOG_FEATURE_USAGE,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'

    export default {
        name: 'PlanningContainer',

        components: {
            OwButton,
            OwFullPageLock,
            OwPlanningMap,
            PlanningActions,
            PlanningResults,
            TitlePanelCard,
        },

        mixins: [ flagsMixin ],

        props: {
            scrollElementContainer: {
                type: String,
                required: true,
            },
            selectedTitleNumber: {
                type: String,
                required: true,
            },
        },

        setup() {
            const showLock = ref()
            const { hasAccessToPlanning } = userProvider()

            onBeforeMount(async () => {
                const hasAccess = await hasAccessToPlanning()
                showLock.value = !hasAccess
            })
            return {
                FeatureId,
                showLock,
            }
        },

        data() {
            return {
                debounceUpdateFilteredResults: debounce(() => {
                    this.updateFilteredResults()
                }, 20),
                debounceRefreshMapLayer: debounce(() => {
                    this.refreshMapLayer()
                }, 20),
            }
        },

        computed: {

            ...mapState({
                selectedDistance: state => state.planning.inputs.selectedDistance,
                selectedDateRange: state => state.planning.inputs.selectedDateRange,
                selectedTitleFeatures: state => state.planning.inputs.selectedTitleFeatures,
                searchedTitleNumbers: state => state.planning.inputs.searchedTitleNumbers,
                loadingResults: state => state.planning.loadingResults,
                textFilter: state => state.planning.inputs.textFilter,
                highlightedFeatures: state => state.planning.highlightedFeatures,
                selectedFeatures: state => state.planning.selectedFeatures,
                mapLayer: state => state.planning.resultsLayer,
                results: state => state.planning.results,
                filteredResultsCount: state => state.planning.filteredResultsCount,
                showAdvancedOptions: state => state.planning.showAdvancedOptions,
                unavailableApplications: state => state.planning.unavailableApplications,
                isRateLimitedRemainingSeconds: state => state.planning.isRateLimitedRemainingSeconds,
                promptForRetry: state => state.planning.promptForRetry,
                filteredResultsCount: state => state.planning.filteredResultsCount,
            }),

            resultsText() {
                let subject = `${ this.searchedTitleNumbers?.length } titles`
                if (this.searchedTitleNumbers?.length === 1) {
                    subject = this.searchedTitleNumbers[0]
                } else if (this.searchedTitleNumbers?.length === 0) {
                    subject = 'your area of interest'
                }

                const oldestAvailablePlanningApplication = this.results[this.results.length - 1]
                const partialResultsMessage = this.unavailableApplications
                    ? `. A further ${ this.unavailableApplications } planning applications prior to ${ oldestAvailablePlanningApplication.getProperties()
                        .receivedDate
                        .substr(0, 10) } are not shown.`
                    : ''

                return `Showing ${ this.filteredResultsCount } results within approximately ${ this.selectedDistance }m of ${ subject }${ partialResultsMessage }`
            },

            isRateLimited() {
                return this.isRateLimitedRemainingSeconds > 0
            },

            subtitle() {
                if (this.loadingResults) {
                    return null
                } else if (this.promptForRetry) {
                    const text = 'We were unable to provide planning application results at this time, please try again'
                    return this.isRateLimitedRemainingSeconds === 0
                        ? `${ text }.`
                        : `${ text } in ${ this.isRateLimitedRemainingSeconds } seconds.`
                } else {
                    return this.resultsText
                }
            },

            disableExport() {
                return this.filteredResultsCount === 0
            },
        },

        watch: {

            selectedTitleFeatures() {
                this.setSelectedDistance(this.selectedDistance)
            },

            selectedDistance() {
                this.debounceUpdateFilteredResults()
            },

            selectedDateRange() {
                this.debounceUpdateFilteredResults()
            },

            textFilter() {
                this.debounceUpdateFilteredResults()
            },

            highlightedFeatures() {
                this.debounceRefreshMapLayer()
            },

            selectedFeatures(val) {
                // More efficient to have one watch than a watch on each result.
                if (val.length > 0) {
                    // Not handling clusters until later PR, select the first one until then.
                    const firstResultItem = val[0]
                    // Scroll first result into view.
                    const resultElement = document.querySelector(`[data-pa-index="${ firstResultItem.getProperties().index }"]`)
                    if (resultElement) {
                        resultElement.scrollIntoView(true)
                    }
                }
            },
        },

        beforeCreate() {
            // To be used for testing purposes, consider removing.
            global.forcePlanningProvider = (provider) => {
                this.setForcePlanningProvider(provider)
            }
        },

        methods: {

            ...mapActions({
                updateFilteredResults: PLANNING_UPDATE_FILTERED_RESULTS,
                retryCurrentSearch: PLANNING_RETRY_CURRENT_SEARCH,
                showUserPopup: USER_SHOW_POPUP,
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
                exportAsCSV: PLANNING_EXPORT_CSV,
            }),

            ...mapMutations({
                setSelectedDistance: PLANNING_MUTATE_DISTANCE,
                setForcePlanningProvider: PLANNING_MUTATE_FORCE_PLANNING_PROVIDER,
            }),

            refreshMapLayer() {
                // eslint-disable-next-line no-unused-expressions
                this.mapLayer?.getSource().changed()
            },

            onRetryClick() {
                this.retryCurrentSearch()
            },

            registerPlanningInterest() {
                this.showUserPopup({
                    title: 'Thank you for your interest',
                    contentHTML: 'We\'ve notified our Support team and will be in touch soon.',
                })
                this.logFeatureUsage({
                    type: 'REGISTERED_PLANNING_INTEREST',
                    description: 'Clicked "register interest button" on planning tab',
                })
            },
        },
    }
</script>
<style lang="scss">
    @import './planning-container-with-map.scss';
</style>
