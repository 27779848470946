import {
    computed,
    inject as VueInject,
    onMounted,
    provide as VueProvide,
    ref,
    watch,
} from "vue"
import { useStore } from "vuex"

import SecurityApi from "@/api/security"
import { LicenceType } from "@/enums/licenceType"
import { isNullOrEmpty } from "@/utils/array-utils"

export enum FeatureId {
    Planning = 'planning',
    Nearby = 'nearby',
    ShapefileExport = 'shapefileExport',
    Alerts = 'alerts',
    MapLayers = 'mapLayers'
}

export interface LicencedFeature {
    id: FeatureId,
    licenceType: LicenceType,
}

export const LICENCE_PROVIDER_KEY = Symbol('licenceProvider')


export const inject = (): ReturnType<typeof useLicenceController> => {
    if (!VueInject(LICENCE_PROVIDER_KEY)) {
        throw new Error(`${ LICENCE_PROVIDER_KEY.toString() } has not been provided`)
    }
    return VueInject(LICENCE_PROVIDER_KEY) as ReturnType<typeof useLicenceController>
}

export const provide = () => {
    const debug =  import.meta.env.DEV
    if (debug) {
        // eslint-disable-next-line no-console
        console.trace(`providing: ${ LICENCE_PROVIDER_KEY.toString() }`)
    }
    VueProvide(LICENCE_PROVIDER_KEY, useLicenceController())
}

/**
 * Where possible use the exported inject function to access the composable
 */
const useLicenceController = (args: {
    debug?: boolean,
} = {
    debug: import.meta.env.DEV,

}) => {
    const { debug } = args
    const store = useStore()
    const userId = computed(() => store?.state?.user?.id)
    const licenceTypeOptions = computed(() => {
        return Object.keys(LicenceType).map(key => ({
            label: key,
            id: LicenceType[key],
        }))
    })
    const licencingActive = computed(() => store.state?.config?.featureFlags?.licencingEnabled ?? false)
    const licenceType = ref<string>()
    const grandfatheredModules = ref<string[]>()
    const accessTokenData = ref<{
        licenceType: string,
        grandfatheredModules: string[],
    }>()
    const convertLicenceTypeIdToGrandfatheredModule = (id: string) => {
        switch (id) {
            case FeatureId.Planning:
                return 'PLANNING'
            case FeatureId.Nearby:
                return 'FIND_NEARBY'
            case FeatureId.ShapefileExport:
                return 'SHAPEFILE_EXPORT'
            default:
                return null
        }
    }

    const checkLicenceType = (feature: LicencedFeature) => {
        const index = licenceTypeOptions.value.findIndex(item => item.id === feature.licenceType)
        const licenceIndex = licenceTypeOptions.value.findIndex(item => item.id === licenceType.value)
        if (index === -1 || licenceIndex === -1) {
            return false
        }
        if (licenceIndex >= index) {
            if (debug) {
                // eslint-disable-next-line
                console.debug(`User has access to ${ feature.id } as they have ${ licenceType.value }`)
            }
            return true
        }
    }

    const checkGrandfatheredModules = (feature: LicencedFeature) => {
        if (isNullOrEmpty(grandfatheredModules.value)) {
            return false
        }
        const module = convertLicenceTypeIdToGrandfatheredModule(feature.id)
        const hasGrandfatheredAccess = grandfatheredModules.value.includes(module)
        if (hasGrandfatheredAccess) {
            if (debug) {
                // eslint-disable-next-line
                console.debug(`User has access to ${ feature.id } as they have grandfathered access to ${ module }`)
            }
            return true
        }
    }

    const userHasAccess = async (featureId: FeatureId) => {
        const feature = licencedFeatures[featureId]

        if (!feature) {
            trace(`Feature ${ featureId } is not a licenced feature`)
            return false
        }

        // NOTE: This can be removed once licence ff is enabled for all users
        if (accessTokenData.value?.licenceType
            && accessTokenData.value.licenceType === LicenceType.MatterLinkShareUser) {
            return checkLicenceType(feature)
        }

        // if we have no active licence types, return true
        if (!licencingActive.value) {
            trace(`User has access to ${ feature.id } as there are no active licence types`)
            return true
        }

        // get the user access token data
        // if the user access token is provided, use that, otherwise get the user access token from the API
        if (!accessTokenData.value) {
            trace(`User does not have a valid access token`)
            return false
        }

        // if the user has the same licence type or higher, return true
        if (checkLicenceType(feature)) {
            return true
        }

        // if the user has grandfathered access to the module, return true
        if (checkGrandfatheredModules(feature)) {
            return true
        }

        trace(`User does not have access to ${ feature.id }`)
        return false
    }

    const trace = (message: string) => {
        if (debug) {
            // eslint-disable-next-line
            console.debug(message)
        }
    }

    const licencedFeatures = {
        planning: {
            id: FeatureId.Planning,
            licenceType: LicenceType.Premium,
        },
        nearby: {
            id: FeatureId.Nearby,
            licenceType: LicenceType.Premium,
        },
        shapefileExport: {
            id: FeatureId.ShapefileExport,
            licenceType: LicenceType.Premium,
        },
        alerts: {
            id: FeatureId.Alerts,
            licenceType: LicenceType.Premium,
        },
        mapLayers: {
            id: FeatureId.MapLayers,
            licenceType: LicenceType.Lite,
        },
    }

    const getAccessTokenData = async () => {
        if (args.debug) {
            // eslint-disable-next-line
            trace('User ID changed, getting access token')
        }
        accessTokenData.value = await SecurityApi.getAccessTokenData()

        // set the licence type and grandfathered modules
        if (!accessTokenData.value) {
            trace('User does not have a valid access token')
            return
        }
        licenceType.value = accessTokenData.value.licenceType
        grandfatheredModules.value = accessTokenData.value.grandfatheredModules
        trace(`User has licence type ${ licenceType.value } and grandfathered modules ${ isNullOrEmpty(grandfatheredModules.value) ? 'None' : grandfatheredModules.value }`)
    }

    watch(() => userId.value, async (newValue, oldValue) => {
        if (newValue === oldValue) {
            return
        }
        await getAccessTokenData()
    }, {
        immediate: true,
    })

    return {
        active: licencingActive,
        accessTokenData,
        licenceTypeOptions,
        licencedFeatures,
        getAccessTokenData,
        userHasAccess,
    }
}

export default useLicenceController
