<template>
    <section class="review-assistant-register-tab">
        <header style="display:flex;flex-direction:column">
            <ow-uploaded-document-warning v-if="isRegisterUploaded"
                                          class="review-assistant-register-tab__header--uploaded-doc-warning"
                                          data-test="review-assistant__uploaded-register-warning" />
            <div class="review-assistant-register-tab__header">
                <ow-button-menu v-model="showMenu"
                                :close-on-content-click="false"
                                :disabled="isLoading"
                                button-height="40px"
                                button-text="Highlight Entries"
                                class="review-assistant-register-tab__header--entries-button"
                                full-height
                                is-borderless
                                is-secondary
                                small>
                    <template #links>
                        <div class="review-assistant-register-tab__header--entries-dropdown">
                            <ow-checkbox v-for="entryType in localEntryTypes"
                                         :id="`review-assistant-register-tab__header--entries-dropdown-${entryType.label}}`"
                                         :key="entryType.label"
                                         v-model="entryType.selected"
                                         :label="entryType.label"
                                         data-test="review-assistant-register-tab-entries-dropdown-entry" />
                            <hr v-if="localEntryTypes.length"
                                class="divider" />
                            <ow-checkbox id="review-assistant-register-tab__header--entries-dropdown-dates"
                                         v-model="highlightDates"
                                         label="Dates" />
                        </div>
                    </template>
                </ow-button-menu>
                <div class="review-assistant-register-tab__header--data-source">
                    <span class="accents-small">
                        {{ $t('label.dataSource') }}
                    </span>
                    <ow-icon-hmlr alt="Logo of Her Majesty's Land Registry"
                                  class="review-assistant-register-tab__header--hmlr-badge"
                                  height="28"
                                  text
                                  width="100" />
                </div>
            </div>
        </header>
        <section v-if="selectedEntryTypes.length"
                 ref="entryTypesContainer"
                 class="review-assistant-register-tab__subheader">
            <div :class="{ wrap: showAll }"
                 class="review-assistant-register-tab__subheader--entries-list">
                <ow-chip v-for="entryType in displayedSelectedEntryTypes"
                         :key="entryType.label"
                         ref="entryType"
                         :color="entryType.colour"
                         :label="entryType.label"
                         class="review-assistant-register-tab__subheader--entry"
                         data-test="review-assistant-register-tab-entries-chip"
                         @click="entryType.selected = false" />
            </div>
            <button v-show="!showAll && selectedEntryTypes.length !== displayedSelectedEntryTypes.length"
                    class="review-assistant-register-tab__subheader--entry caption-highlight"
                    @click="showAll = true">
                + {{ selectedEntryTypes.length - displayedSelectedEntryTypes.length }} {{ $t('label.more') }}
            </button>
        </section>
        <body class="review-assistant-register-tab__body">
            <template v-if="isLoading">
                <register-record-loading-skeleton v-for="index in 3"
                                                  :key="index" />
            </template>
            <template v-else>
                <register-record v-for="(register, registerIndex) in enrichedRegisters"
                                 :key="register.prefix"
                                 :entry-types="localEntryTypes"
                                 :highlight-dates="highlightDates"
                                 :register="register"
                                 :selected-entry-number="selectedEntryNumber"
                                 @view-link-clicked="onViewLinkClicked"
                                 @entry-clicked="onRegisterEntryClicked"
                                 @match-count-changed="registerSearchMatchCount[registerIndex] = $event"
                                 @view-plan-clicked="onViewPlanClicked" />

                <schedule-of-lease-record v-if="scheduleOfLeases.length"
                                          :highlight-dates="highlightDates"
                                          :schedule-of-leases="scheduleOfLeases"
                                          :selected-entry-number="selectedEntryNumber"
                                          @entry-clicked="onSonolEntryClicked"
                                          @match-count-changed="sonolSearchMatchCount = $event" />
            </template>
        </body>
    </section>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwButtonMenu from '@/components/core/ow-button-menu.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwChip from '@/components/core/ow-chip.vue'
    import OwUploadedDocumentWarning from '@/components/documents/ow-uploaded-document-warning.vue'
    import RegisterRecord from '@/components/review-assistant/register-record.vue'
    import RegisterRecordLoadingSkeleton from '@/components/review-assistant/register-record-loading-skeleton.vue'
    import ScheduleOfLeaseRecord from '@/components/review-assistant/schedule-of-lease-record.vue'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import {
        IRegisterLinkedIndicator,
        ISimplifiedRegisterLinkedIndicator,
    } from '@/interfaces/documents/entry-type.interface'
    import { IReferencedDocument } from '@/interfaces/documents/referenced-document.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import { IRegisterEntry } from '@/interfaces/documents/register-entry.interface'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'
    import { format } from '@/utils/date-utils'

    const EVENTS = {
        VIEW_LINK_CLICKED: 'view-link-clicked',
        ENTRY_CLICKED: 'entry-clicked',
        MATCH_COUNT_CHANGED: 'match-count-changed',
        HIGHLIGHT_DATES: 'highlight-dates',
        VIEW_PLAN_CLICKED: 'view-plan-clicked',
    }

    export default {
        components: {
            OwButtonMenu,
            OwCheckbox,
            OwChip,
            OwIconHmlr,
            OwUploadedDocumentWarning,
            RegisterRecord,
            RegisterRecordLoadingSkeleton,
            ScheduleOfLeaseRecord,
        },

        props: {
            registers: {
                type: Array as PropType<Array<IRegister>>,
                default: () => [],
            },
            dateMap: {
                type: Map as PropType<Map<string, string>>,
                default: () => new Map(),
            },
            documents: {
                type: Array as PropType<Array<IReferencedDocument>>,
                default: () => [],
            },
            entryTypes: {
                type: Array as PropType<Array<IRegisterLinkedIndicator>>,
                default: () => [],
            },
            scheduleOfLeases: {
                type: Array as PropType<Array<IScheduleOfLeaseEntry>>,
                default: () => [],
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
            searchText: {
                type: String,
                required: false,
            },
            isRegisterUploaded: {
                type: Boolean,
                default: false,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                localEntryTypes: [] as ISimplifiedRegisterLinkedIndicator[],
                highlightDates: false,
                selectedEntryNumber: null,
                showAll: false,
                displayedSelectedEntryTypes: [],

                registerSearchMatchCount: new Array(this.registers.length).fill(0),
                sonolSearchMatchCount: 0,
                showMenu: false,
            }
        },

        computed: {
            enrichedRegisters() {
                const landInPlanRegex = /the[\s]*?( freehold| leasehold)?[\s]+?land[\s\S]+?on[\s]+?the[\s]+?plan/i

                for (const register of this.registers) {
                    for (const entry of register.entries) {
                        entry.referencedDocuments = this.documents.filter(({ entryNumberField }) => entryNumberField.includes(entry.code))

                        const date = this.dateMap.get(entry.code)

                        if (Date.parse(date)) {
                            entry.date = format(date, 'dd-MM-yyyy')
                        }

                        entry.hasPlanReference = landInPlanRegex.test(entry.displayString)
                    }
                }

                return this.registers
            },

            totalSearchMatchCount(): number {
                return this.registerSearchMatchCount.reduce((acc: number, v: number) => acc + v, 0) +
                    this.sonolSearchMatchCount
            },

            selectedEntryTypes(): ISimplifiedRegisterLinkedIndicator[] {
                return this.localEntryTypes.filter(({ selected }) => selected)
            },
        },

        watch: {
            entryTypes: {
                handler() {
                    this.localEntryTypes = this.entryTypes.map(({ label, colour, codes }) => ({
                        label,
                        colour,
                        codes: codes.reduce((acc: string[], code) => [ ...acc, code.code, ...code.subCodes ], []),
                        selected: false,
                    }))
                },
                immediate: true,
            },

            selectedEntryTypes() {
                this.updateFit()
            },

            showAll() {
                this.displayedSelectedEntryTypes = this.selectedEntryTypes
            },

            searchText(value: string) {
                this.selectedEntryNumber = null

                if (value.length) {
                    this.highlightDates = false
                }
            },

            totalSearchMatchCount(value: number) {
                this.$emit(EVENTS.MATCH_COUNT_CHANGED, value)
            },

            highlightDates(value: boolean) {
                if (value) {
                    this.$emit(EVENTS.HIGHLIGHT_DATES, value)
                }
            },
        },

        created() {
            window.addEventListener('resize', this.updateFit)
        },

        unmounted() {
            window.removeEventListener('resize', this.updateFit)
        },

        methods: {
            onViewLinkClicked(document: IDocumentMetadata): void {
                this.$emit(EVENTS.VIEW_LINK_CLICKED, document)
            },

            onViewPlanClicked() {
                this.$emit(EVENTS.VIEW_PLAN_CLICKED)
            },

            onRegisterEntryClicked(entry: IRegisterEntry): void {
                this.selectedEntryNumber = entry.nr
                this.$emit(EVENTS.ENTRY_CLICKED, entry.displayString.substring(0, 55))
            },

            onSonolEntryClicked(entry: IScheduleOfLeaseEntry, index: string) {
                this.selectedEntryNumber = index
                this.$emit(EVENTS.ENTRY_CLICKED, entry.entryText[0])
            },

            updateFit() {
                this.displayedSelectedEntryTypes = this.selectedEntryTypes

                if (!this.showAll) {
                    this.$nextTick(() => {
                        if (!this.$refs.entryTypesContainer) {
                            return
                        }

                        let containerWidth = this.$refs.entryTypesContainer?.offsetWidth - 24
                        const entryWidths = this.$refs.entryType.map((entry: any) => entry?.$el?.offsetWidth + 8)

                        let result = 0

                        for (const entryWidth of entryWidths) {
                            containerWidth -= entryWidth

                            if (containerWidth < 0) {
                                break
                            }

                            result += 1
                        }

                        if (containerWidth >= 0) {
                            return
                        }

                        this.displayedSelectedEntryTypes = this.selectedEntryTypes.slice(0, result - 1)
                    })
                }
            },
        },
    }
</script>

<style lang="scss">
    @import 'register-tab.scss';
</style>
