<template>
    <div v-if="hasRegisterEntries"
         :class="{ highlightDates }"
         class="register-record"
         data-test="register-record">
        <h3 class="register-record__header"
            data-test="title-detail-panel-register-entry-heading"
            data-track="Title Details Panel - Register entry heading"
            @click="isExpanded = !isExpanded">
            <ow-text-highlight :queries="[searchString]"
                               class="register-record__header--prefix label-caps-medium"
                               @match-count-changed="prefixSearchMatchCount = $event">
                {{ register.prefix }}
            </ow-text-highlight>
            <ow-text-highlight :queries="[searchString]"
                               class="register-record__header--name label-caps-medium"
                               @match-count-changed="nameSearchMatchCount = $event">
                {{ register.displayName }}
            </ow-text-highlight>
            <v-icon v-if="!isExpanded">
                $chevron-down
            </v-icon>
            <v-icon v-else>
                $chevron-up
            </v-icon>
        </h3>
        <div v-for="(entry, entryIndex) in register.entries"
             v-show="isExpanded"
             class="register-record__entry"
             data-test="title-details-panel-register-record-entry">
            <div :class="{ 'selected': entryTypes.filter(e => e.selected).some(e => e.codes) }"
                 :style="{ background: getHighlightColour(entry.code) }"
                 class="register-record__entry--code">
                <ow-text-highlight :queries="[searchString]"
                                   class="label-caps-medium"
                                   data-test="register-record-entry-code"
                                   data-track="REVIEW-ASSISTANT - Register entry value"
                                   @match-count-changed="codeSearchMatchCount = $event">
                    {{ entry.code }}
                </ow-text-highlight>
                <span v-if="entry.date"
                      class="body-regular-xx-small entry-code--date">
                    {{ entry.date }}
                </span>
            </div>
            <section>
                <register-record-entry :content="entry.displayString"
                                       :entry-number="entry.nr"
                                       :highlight-dates="highlightDates"
                                       :register="register"
                                       :search-filter="searchString"
                                       @scrolled="selectEntry(entry)"
                                       @match-count-changed="entrySearchMatchCount[entryIndex] = $event" />
                <ow-button v-if="entry.hasPlanReference"
                           class="register-record__entry--view-title-plan"
                           data-test="title-details-panel-register-entry-view-plan"
                           data-track="Title Details Panel - open title plan from register entry"
                           is-link
                           is-secondary
                           small
                           @click="onViewPlanClicked">
                    {{ t('buttons.viewTitlePlan') }}
                </ow-button>
                <!-- Document details -->
                <div v-for="(document, documentIndex) of entry.referencedDocuments"
                     :key="`${ document.documentType }-${ document.documentDate }-${ documentIndex }`"
                     class="register-record__entry--document"
                     data-test="register-record-entry-document">
                    <span class="body-highlight">
                        {{ document.documentType }}
                    </span>
                    <span :class="{ 'register-highlight-text': highlightDates }"
                          class="body-regular-small">
                        {{ document.documentDate }}
                    </span>
                    <ow-document-availability-label :hmlr-availability-code="getHmlrAvailabilityCodeForDocument(document)"
                                                    :is-ordered="isDocumentOrdered(document)" />
                    <document-ordering :document="getReferredToDocument(document)"
                                       :document-type="HighLevelDocumentType.OC2Document"
                                       :heap-metadata="{
                                           type: 'Title Details Panel - Order related copies filed document from register entry',
                                           metadata: {
                                               matterId: currentMatterId,
                                               hasOrderedRegister: false,
                                           },
                                       }"
                                       :index="documentIndex.toString()"
                                       :title-number="titleNumber"
                                       class="register-record__entry--document-order"
                                       is-small
                                       review-assistant
                                       @view-link-clicked="onViewLinkClick" />
                </div>
            </section>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import DocumentOrdering from '@/components/documents/document-ordering.vue'
    import RegisterRecordEntry from '@/components/title-panel/v2/register/register-record-entry.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import { ISimplifiedRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IReferencedDocument } from '@/interfaces/documents/referenced-document.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import { IRegisterEntry } from '@/interfaces/documents/register-entry.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { generateOrderKeyValueForOC2Document } from '@/utils/document-ordering-utils'

    const props = defineProps({
        register: {
            type: Object as PropType<IRegister>,
            required: true,
        },

        entryTypes: {
            type: Array as PropType<ISimplifiedRegisterLinkedIndicator[]>,
            default: () => [],
        },

        highlightDates: {
            type: Boolean,
            required: false,
        },

        searchString: {
            type: String,
            required: false,
        },
    })

    watch(() => props.register, (newVal) => {
        // console.log('register changed', newVal)
    }, { immediate: true })

    const emit = defineEmits<{
        (e: 'entry-clicked', value: IRegisterEntry),
        (e: 'view-link-clicked', value: ICopiesFiledDocument),
        (e: 'match-count-changed', value: number),
        (e: 'view-plan-clicked'): void,
    }>()

    const store = useStore()
    const route = useRoute()
    const { t } = useI18n()
    const {
        copiesFiledDocuments,
        copiesFiledDocumentsLoading,
    } = useSelectedTitle()

    const isExpanded = ref<boolean>(true)
    const prefixSearchMatchCount = ref<number>(0)
    const nameSearchMatchCount = ref<number>(0)
    const codeSearchMatchCount = ref<number>(0)

    const currentMatterId = computed<number>(() => store.state.matter?.currentMatter?.id)
    const titleNumber = computed<string>(() => {
        const titleNum = route.params?.titleNumber
        return typeof titleNum === 'string' ? titleNum : ''
    })

    const entrySearchMatchCount = computed<number[]>(() => new Array(props.register?.entries?.length ?? 0).fill(0))
    const totalSearchMatchCount = computed<number>(() => {
        return prefixSearchMatchCount.value +
            nameSearchMatchCount.value +
            codeSearchMatchCount.value +
            entrySearchMatchCount.value.reduce((acc: number, v: number) => acc + v, 0)
    })
    watch(totalSearchMatchCount, (value: number) => {
        emit('match-count-changed', value)
    })

    const hasRegisterEntries = computed<boolean>(() => !isNullOrEmpty(props.register?.entries))

    /**
     * Find the new OCDA document from the old register doc to display
     * @param entryDocument - found from the old register data
     */
    const getReferredToDocument = (entryDocument: IReferencedDocument): ICopiesFiledDocument => {
        if (copiesFiledDocumentsLoading.value && entryDocument) {
            return {
                loading: false,
                virtualId: 0,
                downloadAvailable: false,
                viewAvailable: false,
                hmlrAvailabilityCode: HmlrDocumentAvailabilityCode.Unknown,
            } as ICopiesFiledDocument
        }

        const keyValueOfEntryDoc = generateOrderKeyValueForOC2Document(
            titleNumber.value,
            entryDocument?.filedUnderField ?? titleNumber.value,
            entryDocument.documentDate,
            entryDocument.entryNumberField,
        )

        const doc = copiesFiledDocuments.value.find(doc => doc.keyValue === keyValueOfEntryDoc)
        return doc ?? {
            hmlrAvailabilityCode: HmlrDocumentAvailabilityCode.Unknown,
        } as ICopiesFiledDocument
    }

    const isDocumentOrdered = (entryDocument: IReferencedDocument): boolean => {
        if (copiesFiledDocumentsLoading.value) {
            return false
        }
        const ocdaDoc: ICopiesFiledDocument = getReferredToDocument(entryDocument)

        return Boolean(ocdaDoc?.downloadAvailable)
    }

    const getHmlrAvailabilityCodeForDocument = (entryDocument: IReferencedDocument): string => {
        if (copiesFiledDocumentsLoading.value) {
            return ''
        }
        const ocdaDoc: ICopiesFiledDocument = getReferredToDocument(entryDocument)
        if (ocdaDoc) {
            return ocdaDoc.hmlrAvailabilityCode
        }

        // If the document cannot be found in the OCDA response, then mark it as unavailable
        return HmlrDocumentAvailabilityCode.Unavailable
    }

    const getHighlightColour = (code: string): string => {
        return props.entryTypes
            .filter((entry: ISimplifiedRegisterLinkedIndicator) => entry.selected)
            .find((entry: ISimplifiedRegisterLinkedIndicator) => entry.codes.includes(code))?.colour
    }

    const onViewPlanClicked = (): void => {
        emit('view-plan-clicked')
    }

    const selectEntry = (entry: IRegisterEntry): void => {
        emit('entry-clicked', entry)
    }

    const onViewLinkClick = (document: ICopiesFiledDocument): void => {
        emit('view-link-clicked', document)
    }
</script>

<style lang="scss">
    @import './register-record.scss';
</style>
