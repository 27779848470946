<template>
    <ow-splash-page :title="title"
                    :subtitle="t('assetMonitoring.splashPage.description', {
                        context,
                    })"
                    icon="ow-icon-asset-monitoring"
                    :button-enabled="isEnabled"
                    data-track-prefix="Asset Monitoring"
                    data-test-prefix="asset-monitoring"
                    :button-title="buttonTitle"
                    :matter-id="matterId"
                    @button-click="onButtonClick">
        <template #footer>
            <div v-if="t(`assetMonitoring.splashPage.monitorOptions.${ props.context }`)">
                <p class="body-regular">
                    {{ t('assetMonitoring.splashPage.canMonitor') }}
                </p>
                <ul class="body-regular">
                    <li v-for="translation in tm(`assetMonitoring.splashPage.monitorOptions.${ props.context }`)"
                        :key="translation">
                        {{ translation }}
                    </li>
                </ul>
            </div>
        </template>
    </ow-splash-page>
</template>

<script lang="ts" setup>
    import {
        computed,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwSplashPage from "@/components/core/ow-splash-page.vue"
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const props = withDefaults(defineProps<{
        context: string,
        matterId: Number,
        buttonTitle: string,
        title: string
        isEnabled: boolean
    }>(), {
        buttonTitle: 'Enable monitoring',
    })

    const emit = defineEmits(['button-click'])

    const assetMonitoringStore = useAssetMonitoringStore()
    const { t, tm } = useI18n()
    const store = useStore()
    const isMonitoringActive = computed(() => assetMonitoringStore.notificationSettings.isActive)
    const canEnableMonitoring = computed(() => store.state.config?.featureFlags?.assetMonitoring && !isMonitoringActive.value)

    watch(isMonitoringActive, (newValue) => {
        console.info('isMonitoringActive', newValue)
    })
    watch(canEnableMonitoring, (newValue) => {
        console.info('canEnableMonitoring', newValue)
    })

    const onButtonClick = async () => {
        emit('button-click')
    }
</script>