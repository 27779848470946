<template>
    <ow-card v-if="isNewOwnershipCardUIEnabled"
             :loading="loading || isLoading"
             :loading-skeleton="{ rows: 3, columns: 0, expandRows: false }"
             :info-content="owCardInfoContent"
             class="ownership"
             info-content-data-track="TITLE-PANEL: overseas proprietor flag"
             title="Ownership">
        <div v-if="onlyPrivateOwnersAndNotOverseas">
            <div v-if="hasOwnerData"
                 class="d-flex">
                <div class="w-full pb-1">
                    <div class="ownership__card-title body-copy--bold">
                        <ow-icon-renderer :record-source="dataSource"
                                          hide-attribution-text
                                          align="left" />
                        <span class="ml-1">{{ titleSource }}</span>
                    </div>
                </div>
            </div>
            <div v-for="(owner, index) in deduplicatedOwners"
                 :key="index"
                 class="d-flex flex-column">
                <div v-if="owners.length > 1"
                     class="ownership__owner-separator p-4 mt-3">
                    <span class="ownership__owner-separator-text">
                        {{ $t("titlePanel.cards.ownership.labels.owner").toUpperCase() }}
                        <span>- #{{ index + 1 }}</span>
                    </span>
                </div>
                <div class="d-flex">
                    <div class="w-full py-5">
                        <ownership-card-field :title="$t('titlePanel.cards.ownership.labels.owner')"
                                              :description="owner.name"
                                              :warning="owner.isOverseas"
                                              :warning-text="owner.isOverseas ? 'overseas' : ''"
                                              all-caps />
                        <ownership-card-field v-if="hasCompanyRegNumber(owner)"
                                              class="mt-5"
                                              :title="$t('titlePanel.cards.ownership.labels.regNo')"
                                              :description="owner.companyRegNumber" />
                        <ownership-card-field v-if="hasProprietorship(owner)"
                                              class="mt-5"
                                              :title="$t('titlePanel.cards.ownership.labels.proprietorship')"
                                              :description="owner.proprietorship" />
                        <ownership-card-field v-if="hasCorrespondenceAddress(owner)"
                                              class="mt-5"
                                              data-track="TITLE-DETAILS-PANEL - Copy Owner Address info"
                                              :title="$t('titlePanel.cards.ownership.labels.ownerAddress')"
                                              :description="owner.correspondanceAddress" />
                        <ownership-card-field v-if="hasDateAdded(owner)"
                                              class="mt-5"
                                              :title="$t('titlePanel.cards.ownership.labels.dateAdded')"
                                              :description="owner.dateAdded" />
                        <ownership-card-field v-if="hasCountryIncorporated(owner)"
                                              class="mt-5"
                                              :title="$t('titlePanel.cards.ownership.labels.incorporated')"
                                              :description="owner.countryIncorporated" />
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div class="w-full align-middle">
                    <span class="ownership__container-source-text">{{ hmlrSource }}</span>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="hasOwnerData"
                 class="d-flex">
                <div class="ownership__container-split pb-1">
                    <div class="ownership__card-title body-copy--bold">
                        <ow-icon-renderer :record-source="dataSource"
                                          hide-attribution-text
                                          align="left" />
                        <span class="ml-1">{{ titleSource }}</span>
                    </div>
                </div>
                <div class="ownership__container-split__bordered pb-1">
                    <div class="ownership__card-title body-copy--bold">
                        <OwCompaniesHouseIcon height="24px"
                                              width="45px" />
                        <span class="ml-1">{{ $t('titlePanel.cards.ownership.labels.companiesHouse') }}</span>
                    </div>
                </div>
            </div>
            <div v-for="(owner, index) in deduplicatedOwners"
                 :key="index"
                 class="d-flex flex-column">
                <div v-if="owners.length > 1"
                     class="ownership__owner-separator p-4">
                    <span class="ownership__owner-separator-text">
                        {{ $t("titlePanel.cards.ownership.labels.owner").toUpperCase() }}
                        <span>- #{{ index + 1 }}</span>
                    </span>
                </div>
                <div class="d-flex">
                    <div class="ownership__container-split pt-5">
                        <ownership-card-field :title="$t('titlePanel.cards.ownership.labels.owner')"
                                              :description="owner.name"
                                              :warning="owner.isOverseas"
                                              :warning-text="owner.isOverseas ? 'overseas' : ''"
                                              all-caps />
                        <ownership-card-field v-if="hasCompanyRegNumber(owner)"
                                              class="mt-5"
                                              :title="$t('titlePanel.cards.ownership.labels.regNo')"
                                              :description="owner.companyRegNumber" />
                        <ownership-card-field v-if="hasProprietorship(owner)"
                                              class="mt-5"
                                              :title="$t('titlePanel.cards.ownership.labels.proprietorship')"
                                              :description="owner.proprietorship" />
                        <div v-if="showMoreArray[index]">
                            <ownership-card-field v-if="hasCorrespondenceAddress(owner)"
                                                  class="mt-5"
                                                  data-track="TITLE-DETAILS-PANEL - Copy Owner Address info"
                                                  :title="$t('titlePanel.cards.ownership.labels.ownerAddress')"
                                                  :description="owner.correspondanceAddress" />
                            <ownership-card-field v-if="hasDateAdded(owner)"
                                                  class="mt-5"
                                                  :title="$t('titlePanel.cards.ownership.labels.dateAdded')"
                                                  :description="owner.dateAdded" />
                            <ownership-card-field v-if="hasCountryIncorporated(owner)"
                                                  class="mt-5"
                                                  :title="$t('titlePanel.cards.ownership.labels.incorporated')"
                                                  :description="owner.countryIncorporated" />
                        </div>
                    </div>

                    <div class="ownership__container-split__bordered">
                        <div v-if="owner.isOverseas"
                             class="ownership__owner-separator__overseas pl-5"
                             data-test="title-summary-foreign-ownership-information-banner">
                            <span class="ownership__owner-separator-text label-caps-small">
                                {{ $t('titlePanel.cards.ownership.labels.foreignCompany') }}
                            </span>
                        </div>
                        <div v-if="showCompaniesHouseInformation(owner)">
                            <ownership-card-field v-if="getCompaniesHouseProfile(owner.companyRegNumber) !== null"
                                                  class="mt-5 pl-5"
                                                  match-icon-exists
                                                  :matches="!ownerMismatch(owner)"
                                                  :match-icon-tooltip="!ownerMismatch(owner) ? $t('titlePanel.cards.ownership.labels.companiesHouseMatchesSourceName') : $t('titlePanel.cards.ownership.labels.companiesHouseDoesntMatchSourceName')"
                                                  :title="$t('titlePanel.cards.ownership.labels.listedOwner')"
                                                  :description="getCompaniesHouseProfile(owner.companyRegNumber)?.companyName" />
                            <ownership-card-field v-if="getCompaniesHouseProfile(owner.companyRegNumber) !== null"
                                                  class="mt-5 pl-5"
                                                  link
                                                  :href="getCompaniesHouseLink(owner.companyRegNumber)"
                                                  :title="$t('titlePanel.cards.ownership.labels.regNo')"
                                                  :description="formatCompanyRegNumber(owner.companyRegNumber)" />
                        </div>
                        <div v-if="hasCompaniesHouseData">
                            <ownership-card-field v-if="isCompaniesHouseIntegrationEnabled && hasCompanyRegNumber(owner) && hasCompaniesHouseData"
                                                  class="mt-5 pl-5"
                                                  tag
                                                  :tag-theme="isCompanyActive(owner.companyRegNumber)"
                                                  :title="$t('titlePanel.cards.ownership.labels.companyStatus')"
                                                  :description="getCompaniesHouseProfile(owner.companyRegNumber)?.companyStatus" />
                            <div v-if="showMoreArray[index]">
                                <ownership-card-field v-if="isCompaniesHouseIntegrationEnabled && hasCompanyRegNumber(owner) && getCompaniesHouseProfile(owner.companyRegNumber)?.displayAddress"
                                                      class="mt-5 pl-5"
                                                      :title="$t('titlePanel.cards.ownership.labels.companyRegisteredAddress')"
                                                      :description="getCompaniesHouseProfile(owner.companyRegNumber)?.displayAddress" />
                                <ownership-card-field v-if="isCompaniesHouseIntegrationEnabled && hasCompanyRegNumber(owner) && getCompaniesHouseProfile(owner.companyRegNumber)?.displayNatureOfBusiness"
                                                      class="mt-5 pl-5"
                                                      :title="$t('titlePanel.cards.ownership.labels.companyNatureOfBusiness')"
                                                      :description="getCompaniesHouseProfile(owner.companyRegNumber)?.displayNatureOfBusiness" />
                            </div>
                        </div>
                        <div v-else
                             class="pl-5 pt-5">
                            <div class="ownership__missing-companies-house">
                                <v-icon color="warning"
                                        size="large">
                                    $warning
                                </v-icon>
                                <span v-t="'titlePanel.cards.ownership.labels.companyRegCannotBeMatched'"
                                      class="label-caps-small ownership__missing-companies-house__text" />
                            </div>
                            <div class="text-center pt-2">
                                <a id="openCompaniesHouse"
                                   :href="getCompaniesHouseLink()"
                                   data-track="TITLE-DETAILS-PANEL - Open companies house"
                                   target="_blank">
                                    <span v-t="'titlePanel.cards.ownership.labels.gotoCompaniesHouse'"
                                          class="label-caps-small" />
                                </a>
                            </div>
                        </div>
                        <div v-if="owner.isOverseas">
                            <div class="ownership__owner-separator__overseas pl-5 mt-5"
                                 data-test="title-summary-overseas-ownership-information-banner">
                                <span v-t="'titlePanel.cards.ownership.labels.overseasEntity'"
                                      class="ownership__owner-separator-text label-caps-small" />
                            </div>
                            <div v-if="hasOverseasRegNumber(owner)">
                                <ownership-card-field v-if="isCompaniesHouseIntegrationEnabled && owner.isOverseas"
                                                      class="mt-5 pl-5"
                                                      link
                                                      match-icon-exists
                                                      :matches="false"
                                                      :match-icon-tooltip="isOverseasEntityMatched(owner) ? $t('titlePanel.cards.ownership.labels.overseasEntityFoundOnCH'): $t('titlePanel.cards.ownership.labels.overseasEntityNotFoundOnCH')"
                                                      :href="getCompaniesHouseLink(owner.overseasRegistrationNumber)"
                                                      :title="$t('titlePanel.cards.ownership.labels.companiesOverseasEntity')"
                                                      :description="$t('companiesHouse.buttons.link', { companyRegNumber: formatCompanyRegNumber(owner.overseasRegistrationNumber) })"
                                                      data-track="TITLE-DETAILS-PANEL - Search for overseas entity on companies house" />
                            </div>
                            <div v-else
                                 class="pl-5 pt-5">
                                <div class="ownership__missing-companies-house">
                                    <v-icon color="warning"
                                            size="large">
                                        $warning
                                    </v-icon>
                                    <span v-t="'titlePanel.cards.ownership.labels.overseasEntityNotFound'"
                                          class="label-caps-small ownership__missing-companies-house__text" />
                                </div>
                                <div class="text-center pt-2">
                                    <a id="openCompaniesHouse"
                                       :href="getCompaniesHouseLink()"
                                       data-track="TITLE-DETAILS-PANEL - Open companies house"
                                       target="_blank">
                                        <span class="label-caps-small">{{ $t('companiesHouse.ownerText.searchForOverseasEntity') }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center my-2">
                    <a v-if="!showMoreArray[index]"
                       id="titlePanelShowMoreOwnershipData"
                       data-test="title-summary-more-ownership-information-button"
                       data-track="TITLE-DETAILS-PANEL - Show more ownership information"
                       href="#"
                       @click.prevent="showMoreArray[index] = true">
                        <span>{{ $t("buttons.seeMore") }}</span>
                    </a>
                    <a v-if="showMoreArray[index]"
                       id="titlePanelShowLessOwnershipData"
                       data-test="title-summary-less-ownership-information-button"
                       data-track="TITLE-DETAILS-PANEL - Show less ownership information"
                       href="#"
                       @click.prevent="showMoreArray[index] = false">
                        <span v-if="showMoreArray[index]"
                              data-test="title-summary-less-ownership-information-button">
                            {{ $t("buttons.showLess") }}
                        </span>
                    </a>
                </div>
            </div>
            <div class="d-flex">
                <div class="ownership__container-split align-middle">
                    <span class="ownership__container-source-text">{{ hmlrSource }}</span>
                </div>
                <div class="ownership__container-split__bordered align-middle pl-5">
                    <span class="ownership__container-source-text">{{ companiesHouseSource }}</span>
                </div>
            </div>
        </div>
    </ow-card>
    <ow-card v-else
             :loading="loading || isLoading"
             :loading-skeleton="{ rows: 3, columns: 0, expandRows: false }"
             :info-content="owCardInfoContent"
             :subtitle="subtitleText"
             class="ownership"
             info-content-data-track="TITLE-PANEL: overseas proprietor flag"
             title="Ownership">
        <div class="main-data-property">
            <div v-if="hasOwnerData"
                 class="ownership__container">
                <div v-for="(owner, index) in deduplicatedOwners"
                     :key="index"
                     class="d-flex flex-column">
                    <div v-if="hasOwnerName(owner)">
                        <span class="title-panel-subtitle">
                            {{ $t("titlePanel.cards.ownership.labels.owner") }}
                            <span v-if="owners.length > 1">- #{{ index + 1 }}</span>
                        </span>
                        <p>
                            <OwClipboard :text="owner.name"
                                         data-track="TITLE-DETAILS-PANEL - Copy Owner info">
                                <span data-test="title-summary-ccod-name">{{ owner.name }}</span>
                                <span v-if="hasCompanyRegNumber(owner) && !isCompaniesHouseIntegrationEnabled">
                                    -
                                    <a id="titlePanelSearchOnCompaniesHouse"
                                       :href="getCompaniesHouseLink(owner.companyRegNumber)"
                                       data-track="TITLE-DETAILS-PANEL - Search for owner on companies house"
                                       target="_blank">
                                        {{ $t("companiesHouse.buttons.search") }}
                                    </a>
                                </span>
                                <ow-colored-label v-if="owner.isOverseas"
                                                  :is-small="true"
                                                  class="ownership__label--overseas"
                                                  theme="warning">
                                    {{ $t("titlePanel.cards.ownership.labels.overseas") }}
                                </ow-colored-label>
                            </OwClipboard>
                        </p>
                    </div>
                    <div v-if="showCompaniesHouseInformation(owner)">
                        <span v-t="'titlePanel.cards.ownership.labels.companiesHouseOwner'"
                              class="title-panel-subtitle" />
                        <p data-test="title-summary-companies-house-name">
                            <span v-if="getCompaniesHouseProfile(owner.companyRegNumber) !== null">
                                {{ getCompaniesHouseProfile(owner.companyRegNumber)?.companyName }}<br />
                            </span>
                            <span class="title-panel-subtitle"
                                  data-test="title-summary-companies-house-text">
                                <span v-if="ownerMismatch(owner)">
                                    <v-icon color="warning"
                                            class="ownership__text-icon"
                                            data-test="title-summary-companies-house-mismatch-icon"
                                            size="medium">
                                        $warning
                                    </v-icon>
                                    <span v-t="'companiesHouse.ownerText.mismatchText'"
                                          data-test="title-summary-companies-house-mismatch-text" />
                                </span>

                                <span v-else>
                                    <v-icon color="success"
                                            class="ownership__text-icon"
                                            data-test="title-summary-companies-house-match-icon"
                                            size="medium">
                                        $success-circle
                                    </v-icon>
                                    <span v-t="'companiesHouse.ownerText.matchText'"
                                          data-test="title-summary-companies-house-match-text" />
                                </span>

                                <OwCompaniesHouseIcon />

                                <a id="titlePanelSearchOnCompaniesHouse"
                                   :href="getCompaniesHouseLink(owner.companyRegNumber)"
                                   class="ownership__link"
                                   data-track="TITLE-DETAILS-PANEL - Search for owner on companies house"
                                   target="_blank">
                                    {{ $t("companiesHouse.buttons.link", { companyRegNumber: formatCompanyRegNumber(owner.companyRegNumber) }) }}
                                </a>
                            </span>
                        </p>
                    </div>

                    <div v-if="isCompaniesHouseIntegrationEnabled && hasCompanyRegNumber(owner) && hasCompaniesHouseData">
                        <span v-t="'titlePanel.cards.ownership.labels.companiesHouseCompanyStatus'"
                              class="title-panel-subtitle" />
                        <ow-colored-label v-if="true"
                                          :is-small="true"
                                          :theme="isCompanyActive(owner.companyRegNumber)"
                                          class="ownership__label--companies-house"
                                          data-test="title-summary-companies-house-status">
                            {{ getCompaniesHouseProfile(owner.companyRegNumber)?.companyStatus }}
                        </ow-colored-label>
                        <p />
                    </div>

                    <div v-if="isCompaniesHouseIntegrationEnabled && hasCompanyRegNumber(owner) && hasCompaniesHouseData">
                        <span v-t="'titlePanel.cards.ownership.labels.companiesHouseCompanyRegisteredAddress'"
                              class="title-panel-subtitle" />
                        <p data-test="title-summary-companies-house-address">
                            {{ getCompaniesHouseProfile(owner.companyRegNumber)?.displayAddress }}
                        </p>
                    </div>

                    <div v-if="isCompaniesHouseIntegrationEnabled && hasCompanyRegNumber(owner) && hasCompaniesHouseData">
                        <span v-t="'titlePanel.cards.ownership.labels.companiesHouseCompanyNatureOfBusiness'"
                              class="title-panel-subtitle" />
                        <p data-test="title-summary-companies-house-natureOfBusiness">
                            {{ getCompaniesHouseProfile(owner.companyRegNumber)?.displayNatureOfBusiness }}
                        </p>
                    </div>

                    <div v-if="isCompaniesHouseIntegrationEnabled && owner.isOverseas">
                        <span v-t="'titlePanel.cards.ownership.labels.companiesOverseasEntity'"
                              class="title-panel-subtitle" />
                        <p data-test="title-summary-companies-house-overseas-entity-name">
                            <span class="title-panel-subtitle"
                                  data-test="title-summary-companies-house-overseas-entity-text">
                                <span v-if="isOverseasEntityMatched(owner)">
                                    <v-icon color="success"
                                            class="ownership__text-icon"
                                            data-test="title-summary-companies-house-overseas-entity-match-icon"
                                            size="medium">
                                        $success-circle
                                    </v-icon>
                                    <span v-t="'companiesHouse.ownerText.matchedOverseasEntityText'"
                                          data-test="title-summary-companies-house-overseas-entity-match-text" />
                                </span>

                                <span v-else>
                                    <v-icon color="warning"
                                            class="ownership__text-icon"
                                            data-test="title-summary-companies-house-overseas-entity-mismatch-icon"
                                            size="medium">
                                        $warning
                                    </v-icon>
                                    <span v-t="'companiesHouse.ownerText.mismatchedOverseasEntityText'"
                                          data-test="title-summary-companies-house-overseas-entity-mismatch-text" />
                                </span>

                                <OwCompaniesHouseIcon />

                                <a id="titlePanelSearchOnCompaniesHouse"
                                   :href="getCompaniesHouseLink(owner.overseasRegistrationNumber)"
                                   class="ownership__link"
                                   data-track="TITLE-DETAILS-PANEL - Search for overseas entity on companies house"
                                   target="_blank">
                                    <span v-if="hasOverseasRegNumber(owner)">
                                        {{ $t("companiesHouse.buttons.link", { companyRegNumber: formatCompanyRegNumber(owner.overseasRegistrationNumber) }) }}
                                    </span>
                                    <span v-else>{{ $t("companiesHouse.ownerText.searchForOverseasEntity") }}</span>
                                </a>
                            </span>
                        </p>
                    </div>

                    <div v-if="showMoreOwnershipInformation"
                         class="d-flex flex-column"
                         data-test="title-summary-more-ownership-information-section">
                        <div v-if="hasCompanyRegNumber(owner)">
                            <span class="title-panel-subtitle">
                                {{ $t("titlePanel.cards.ownership.labels.regNo") }}
                            </span>
                            <p>{{ owner.companyRegNumber }}</p>
                        </div>

                        <div v-if="hasProprietorship(owner)">
                            <span class="title-panel-subtitle">
                                {{ $t("titlePanel.cards.ownership.labels.proprietorship") }}
                            </span>
                            <p data-test="title-summary-ccod-proprietorship">
                                {{ owner.proprietorship }}
                            </p>
                        </div>

                        <div v-if="hasDateAdded(owner)">
                            <span class="title-panel-subtitle">
                                {{ $t("titlePanel.cards.ownership.labels.dateAdded") }}
                            </span>
                            <p data-test="title-summary-dateadded">
                                {{ owner.dateAdded }}
                            </p>
                        </div>

                        <div v-if="hasCountryIncorporated(owner)">
                            <span class="title-panel-subtitle">
                                {{ $t("titlePanel.cards.ownership.labels.incorporated") }}
                            </span>
                            <p>{{ owner.countryIncorporated }}</p>
                        </div>

                        <div v-if="hasCorrespondenceAddress(owner)">
                            <span class="title-panel-subtitle">
                                {{ $t("titlePanel.cards.ownership.labels.ownerAddress") }}
                            </span>

                            <p>
                                <OwClipboard :text="owner.correspondanceAddress"
                                             data-track="TITLE-DETAILS-PANEL - Copy Owner Address info">
                                    {{ owner.correspondanceAddress }}
                                </OwClipboard>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="d-flex">
                    <a v-if="!showMoreOwnershipInformation"
                       id="titlePanelShowMoreOwnershipData"
                       data-test="title-summary-more-ownership-information-button"
                       data-track="TITLE-DETAILS-PANEL - Show more ownership information"
                       href="#"
                       @click.prevent="showMoreOwnershipInformation = true">
                        <span>{{ $t("buttons.seeMore") }}</span>
                    </a>
                    <a v-if="showMoreOwnershipInformation"
                       id="titlePanelShowLessOwnershipData"
                       data-test="title-summary-less-ownership-information-button"
                       data-track="TITLE-DETAILS-PANEL - Show less ownership information"
                       href="#"
                       @click.prevent="showMoreOwnershipInformation = false">
                        <span v-if="showMoreOwnershipInformation"
                              data-test="title-summary-less-ownership-information-button">
                            {{ $t("buttons.showLess") }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </ow-card>
</template>

<script lang="ts">
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import CompaniesHouseApi from '@/api/companies-house.api'
    import TitleInformationApi from '@/api/title-information.api'
    import OwCompaniesHouseIcon from '@/components/core/icons/ow-companies-house-icon.vue'
    import OwIconRenderer from "@/components/core/icons/ow-icon-renderer.vue"
    import OwCard from '@/components/core/ow-card.vue'
    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwnershipCardField from "@/components/title-panel/tabs/summary-tab/ownership-card-field.vue"
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IInfoContentItem } from '@/interfaces/core-components/ow-card-info-content-item.interface'
    import { ICompaniesHouseData } from '@/interfaces/title/companies-house.interface'
    import { ICompaniesHouseROEData } from '@/interfaces/title/companies-house-roe-data.interface'
    import { IOwner } from '@/interfaces/title/owner.interface'
    import i18n from '@/plugins/i18n'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import {
        format,
        getDayBefore,
    } from '@/utils/date-utils'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'
    import {
        isNullOrWhitespace,
        pad,
    } from '@/utils/string-utils'

    export default {
        name: 'Ownership',

        components: {
            OwIconRenderer,
            OwnershipCardField,
            OwCard,
            OwClipboard,
            OwColoredLabel,
            OwCompaniesHouseIcon,
        },

        mixins: [flagsMixin],

        props: {
            selectedTitle: {
                type: Object,
                required: true,
            },
            titleNumber: {
                type: String,
                required: false,
            },
            matterId: {
                type: Number,
                required: false,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },
        setup(props) {
            // Load Companies House information
            const retryAttempts = ref(0) // has the companies house data may not yet be available.
            const retryInterval = ref(2000) // retry the companies house datarequest interval (doubles on fail).
            const retryTimeout = ref(null) // the setTimeout value.
            const errorState = ref(false) // the setTimeout value.
            const loading = ref(true)

            // Companies House data
            const companiesHouseData = ref(null)
            const lastUpdatedDate = ref(null)
            const store = useStore()
            // const companiesHouseApiError = ref(null)

            // Ownership
            const titleMetadata = computed((): any => props.selectedTitle?.record?.titleMetadata)
            const proprietorships = computed((): any => props.selectedTitle?.record?.proprietorships)
            const registerDataAvailable = computed((): boolean => !isNullOrWhitespace(props.selectedTitle?.record?.editionDate))
            const editionDate = computed((): string => props.selectedTitle?.record?.editionDate)
            const recordLoaded = computed(() => props.selectedTitle?.record)
            const isNewOwnershipCardUIEnabled = computed(() => store.state.config?.featureFlags?.newOwnershipCardUi)
            const ownerMatchesCcodOcod = ref<boolean>(false)

            const hasOwnerData = computed((): boolean => !isNullOrEmpty(deduplicatedOwners.value))
            const hasOwnerName = (owner: IOwner): boolean => !isNullOrWhitespace(owner.name)
            const hasCorrespondenceAddress = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.correspondanceAddress)
            const hasProprietorship = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.proprietorship)
            const proprietorshipIncludesCorporateOrCompany = (owner: IOwner): boolean => hasProprietorship(owner) && (owner.proprietorship?.toLowerCase().includes('corporate') || owner.proprietorship?.toLowerCase().includes('company'))
            const hasCountryIncorporated = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.countryIncorporated)
            const hasDateAdded = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.dateAdded)
            const hasCompanyRegNumber = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.companyRegNumber)
            const hasOverseasRegNumber = (owner: IOwner): boolean => !isNullOrWhitespace(owner?.overseasRegistrationNumber)
            const isScottish = computed((): boolean => props.selectedTitle?.record?.source === DOCUMENT_SOURCE.SCOTLAND)
            const dataSource = computed((): string =>  props.selectedTitle?.record?.source)

            const companiesHouseSource = computed(() => {
                if (companiesHouseData.value) {
                    return ` ${ i18n.global.t('titlePanel.cards.ownership.sources.companiesHouse', { date: format(lastUpdatedDate.value,'dd-MM-yyyy HH:mm:ss') }) }`
                }
                return ''
            })

            const titleSource = computed(() => getTitleSource())

            const hmlrSource = computed(() => {
                const isFromCcod = titleMetadata.value?.isCCOD
                const isFromOcod = titleMetadata.value?.isOCOD
                const ocodCcodLastUpdated = titleMetadata.value?.lastUpdated
                if(isScottish.value && registerDataAvailable.value) {
                    return `${ i18n.global.t('titlePanel.cards.ownership.sources.titleSheet', { date: format(editionDate.value,'dd-MM-yyyy') }) }`
                }
                if (registerDataAvailable.value) {
                    return `${ i18n.global.t('titlePanel.cards.ownership.sources.register', { date: format(editionDate.value, "dd-MM-yyyy") }) }`
                }
                if (!hasOwnerData.value) {
                    return `${ i18n.global.t('titlePanel.cards.ownership.sources.unavailable') }`
                }
                if (isFromCcod) {
                    return `${ i18n.global.t('titlePanel.cards.ownership.sources.ccod', { date: getDayBefore(ocodCcodLastUpdated) }) }`
                }
                if (isFromOcod) {
                    return `${ i18n.global.t('titlePanel.cards.ownership.sources.ocod', { date: getDayBefore(ocodCcodLastUpdated) }) }`
                }
                return ''
            })

            const subtitleText = computed((): string => {
                return `${ hmlrSource.value }${ companiesHouseSource.value }`
            })

            const owners = computed((): IOwner[] => proprietorships?.value ?? [])

            // TODO: This is a temporary fix until the BE starts returning the deduplicated list
            const showMoreArray = ref<boolean[]>([])
            const deduplicatedOwners = computed((): IOwner[] => {
                const dedupOwners = owners.value
                    .reduce((deduplicated, owner) => {
                        if (!deduplicated.some(o => o.name.toLowerCase() === owner.name.toLowerCase())) {
                            deduplicated.push(owner)
                        }

                        return deduplicated
                    }, [])
                showMoreArray.value = new Array(dedupOwners.length).fill(false)

                return dedupOwners
            })
            const onlyPrivateOwnersAndNotOverseas = computed(() =>
                deduplicatedOwners.value.every(owner => !hasCompanyRegNumber(owner) && !owner.isOverseas && !proprietorshipIncludesCorporateOrCompany(owner)))

            const areAllOverseasEntitiesMatched = computed((): boolean => {
                return deduplicatedOwners.value
                    .filter((owner: IOwner) => owner.isOverseas)
                    .every((owner: IOwner) => isOverseasEntityMatched(owner))
            })

            const includesOverseasProprietor = computed((): boolean => !isNullOrEmpty(deduplicatedOwners.value.filter((p: IOwner) => p.isOverseas)))
            const owCardInfoContent = computed((): IInfoContentItem[] => {
                const flags = []
                if (includesOverseasProprietor.value) {
                    if (areAllOverseasEntitiesMatched.value) {
                        flags.push({ text: `${ i18n.global.t('companiesHouse.flags.matched') }`, class: 'colour-warning' })
                    } else {
                        flags.push({ text: `${ i18n.global.t('companiesHouse.flags.notMatched') }`, class: 'colour-danger' })
                    }
                }

                if (!isNullOrEmpty(anyCompanyNotActive.value)) {
                    flags.push({ text: `${ i18n.global.t('companiesHouse.flags.status', { status: anyCompanyNotActive.value.join(', ') }) }` })
                }
                if (anyCompanyCompanyOwnerMismatch.value) {
                    flags.push({ text: `${ i18n.global.t('companiesHouse.flags.name', { source: registerDataAvailable.value ? 'the Title Register' : 'Land Registry' }) }` })
                }
                if (isNewOwnershipCardUIEnabled.value && ownerMatchesCcodOcod.value === false) {
                    flags.push({text: `${ i18n.global.t('titlePanel.cards.ownership.flags.ccodOcodMismatch') }`, class: 'colour-danger'})
                }
                return flags
            })

            // Companies House
            const hasCompaniesHouseData = computed((): boolean => Boolean(companiesHouseData.value))
            const getCompaniesHouseLink = (number: number): string => {
                return getCompaniesHouseLinkFromCompanyRegistrationNumber(number)
            }

            const isCompaniesHouseIntegrationEnabled = computed(() => store.state.config?.featureFlags?.companiesHouseIntegration)
            const showCompaniesHouseInformation = (owner: IOwner) => {
                const enabled = isCompaniesHouseIntegrationEnabled.value
                const hasData = Boolean(companiesHouseData.value)
                const hasDataForCompany = hasData ? Boolean(companiesHouseData.value[formatCompanyRegNumber(owner.companyRegNumber)]) : false
                return enabled && hasData && hasDataForCompany
            }

            const formatCompanyRegNumber = (companyRegNumber: string): string => {
                return pad(companyRegNumber, 8, '0')
            }

            const getCompaniesHouseProfile = (companyRegNumber: string): ICompaniesHouseData | null => {
                if (!companiesHouseData.value) {
                    return null
                }
                return companiesHouseData.value[formatCompanyRegNumber(companyRegNumber)]
            }

            const getCompaniesHouseROEData = async (companyName: string): Promise<ICompaniesHouseROEData> => {
                try {
                    const response = await CompaniesHouseApi.getROEData(companyName)
                    if (response.ok) {
                        return response.data
                    }
                } catch (error) {
                // companiesHouseApiError.value = "There was a problem retrieving the ROE data from Companies House."
                }
                return null
            }

            const isOverseasEntityMatched = (owner: IOwner): boolean => {
                return !isNullOrWhitespace(owner.overseasRegistrationNumber)
            }

            const isCompanyActive = (companyRegNumber: string): string => {
                return getCompaniesHouseProfile(companyRegNumber)?.isActive ? 'success' : 'warning'
            }

            const anyCompanyNotActive = computed(() => companiesHouseData.value
                ? Object.values(companiesHouseData.value)
                    .filter((x: ICompaniesHouseData) => x?.companyStatus !== 'Active')
                    .map((x: ICompaniesHouseData) => x.companyStatus)
                : [])

            const ownerMismatch = (owner: IOwner) => {
                if (!isNullOrWhitespace(owner?.companyRegNumber)) {
                    const companyHouseProfile = getCompaniesHouseProfile(owner.companyRegNumber)
                    if (!companyHouseProfile) {
                        return false
                    }
                    if (companyHouseProfile?.companyName.toUpperCase() !== owner.name.toUpperCase()) {
                        return true
                    }
                }
                return false
            }

            const anyCompanyCompanyOwnerMismatch = computed(() => !isNullOrEmpty(deduplicatedOwners.value
                .filter((owner: IOwner) => ownerMismatch(owner))))

            const fetchCompaniesHouseDataForTitles = async ({ titleNumber, matterId }: { titleNumber: string, matterId?: number }) => {
                const data = await TitleInformationApi.getCompaniesHouseDataByTitleNumbers([titleNumber], matterId)
                if (!data) {
                    return data
                }

                // Parse the result
                const result = data.map(r => {
                    // Add isActive property to the companies house data
                    const companiesHouseData = r.companiesHouseData?.map(companyProfile => {
                        companyProfile.isActive = companyProfile.companyStatus === 'Active'
                        return companyProfile
                    })
                    return {
                        companiesHouseData,
                        titleNumber: r.titleNumber,
                        lastUpdated: r.lastUpdatedCompaniesHouseDateTime,
                    }
                }).find(x => x.titleNumber === titleNumber)

                // Return the result
                return result
            }

            const refresh = async () => {
                // NOTE: If the title is purchased/uploaded don't use the matter id in the Companies House call
                const useMatterId = props.selectedTitle?.showLrOwnership
                // Request the Companies House data
                const result = useMatterId ? await fetchCompaniesHouseDataForTitles({
                    matterId: props.matterId,
                    titleNumber: props.titleNumber,
                }) : await fetchCompaniesHouseDataForTitles({
                    titleNumber: props.titleNumber,
                })

                // Null means there was an error when Doc Manager tried to
                // get the Companies House info, in this case we don't do anything
                if (result === null) {
                    loading.value = false
                    return
                }

                // Undefined means network error, so let's re-try
                if (result === undefined) {
                    if (retryInterval.value < 5 * 60 * 1000) { // stop trying after 5 minutes
                        retryTimeout.value = setTimeout(async () => {
                            await refresh()
                        }, retryInterval.value)
                        retryInterval.value = retryInterval.value * 2
                        retryAttempts.value++
                    } else {
                        errorState.value = true
                        console.error('Unable to load Companies House data')
                    }
                } else {
                    companiesHouseData.value = result?.companiesHouseData !== undefined
                        ? Object.fromEntries(result?.companiesHouseData?.map((x: ICompaniesHouseData) =>
                            [x.companyRegistrationNumber, x]))
                        : null
                    lastUpdatedDate.value = result?.lastUpdated
                    for (const co of deduplicatedOwners.value) {
                        if (!isNullOrWhitespace(co.name) && co.isOverseas) {
                            const roeData = await getCompaniesHouseROEData(co.name)
                            co.overseasRegistrationNumber = roeData?.companyNumber
                        }
                    }

                    // Info retrieved is available, reset error/retry state
                    loading.value = false
                    retryAttempts.value = 0
                    errorState.value = false
                }
            }

            const getTitleSource = () => {
                switch (props.selectedTitle.source) {
                    case DOCUMENT_SOURCE.HMLR:
                    case DOCUMENT_SOURCE.UPLOADED:
                        return registerDataAvailable.value ? 'Title Register' : 'Land Registry'
                    default:
                        return ''
                }
            }


            watch(() => recordLoaded.value, async (val) => {
                if (!val) {
                    return
                }
                ownerMatchesCcodOcod.value = await TitleInformationApi.checkOwnerMatchWithCcodOcodByTitleNumber(props.titleNumber)

                if (isCompaniesHouseIntegrationEnabled.value) {
                    retryAttempts.value = 0
                    retryInterval.value = 2000

                    // Load the Companies House data
                    // We use a try finally block to ensure the loading state is reset
                    try {
                        await refresh()
                    } finally {
                        loading.value = false
                    }
                } else {
                    loading.value = false
                }
            },{
                immediate: true,
            })

            return {
                ownerMismatch,
                deduplicatedOwners,
                formatCompanyRegNumber,
                getCompaniesHouseLink,
                getCompaniesHouseProfile,
                hasCompaniesHouseData,
                hasCompanyRegNumber,
                hasOverseasRegNumber,
                hasCorrespondenceAddress,
                hasCountryIncorporated,
                hasDateAdded,
                hasOwnerData,
                dataSource,
                hasOwnerName,
                hasProprietorship,
                isCompanyActive,
                loading,
                owCardInfoContent,
                owners,
                showCompaniesHouseInformation,
                isCompaniesHouseIntegrationEnabled,
                subtitleText,
                isOverseasEntityMatched,
                companiesHouseSource,
                hmlrSource,
                titleSource,
                onlyPrivateOwnersAndNotOverseas,
                showMoreArray,
            }
        },

        data: () => ({
            showMoreOwnershipInformation: false,
        }),
    }
</script>

<style lang="scss">
@import './ownership-card';
</style>
